

const DynamicList = props => {
    return (
        <div className={`dynamic-list ${props.className ? props.className : ''}`}>
            { props.children }
        </div>
    );
}
  
export default DynamicList;