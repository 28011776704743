import DsLayout from '../../layouts/DsLayout'
import {DocCard, Heading} from '../../components/design-system/DsComponents'
import bannerImage from "../../assets/test-images/card-3.jpg";
import thumbImage from '../../assets/test-images/card-test-small-32.jpg'

const ImagesView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Images</Heading>
            <p>Please follow these basic rules while creating and using images on a webpage:</p>
            <ul>
                <li>Do not insert text into the image (for SEO and accessibility issues)</li>
                <li>Do not insert UI element in the images (buttons, cards, etc.)</li>
                <li>Avoid unnecessary decorations</li>
                <li>Only use images if they serve a user need</li>
            </ul>

            <Heading size="h3">Aspect ratio</Heading>
            <p>There are two different aspect ratio in FAO.org. One is for hero banners, and one for thumbnails (used in
                the cards).
                The hero banner's aspect ratio (in desktop size) is 21:9, the thumbnail aspect ration is 3:2.</p>

            <DocCard>




                <div className="hero-banner ratio-visualizer-hero" style={{backgroundImage: `url(${bannerImage})`}}>
                    <div className="hero-caption">
                        <h6 className="title-category">News</h6>
                        <h5 className="title-link"><a href="#!">Lorem ipsum dolor sit amet, consectetur adipiscing elit</a></h5>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                    <h1 className="ratio-visualizer">21:9</h1>
                    <div className="ref-bar stretched horizontal">
                        <span>21</span>
                    </div>
                    <div className="ref-bar stretched vertical">
                        <span>9</span>
                    </div>
                </div>

                <div className="card" style={{width: 18 + 'rem'}}>
                    <div className="card-image">
                        <img src={thumbImage} className="img-fluid" alt="card alt"/>
                        <h1 className="ratio-visualizer">3:2</h1>
                        <div className="ref-bar stretched horizontal">
                            <span>3</span>
                        </div>
                        <div className="ref-bar stretched vertical">
                            <span>2</span>
                        </div>
                    </div>
                    <div className="card-body">
                        <h6 className="title-category">Category</h6>
                        <h5 className="card-title">
                            <a href="www.google.com" className="title-link">Card title</a>
                        </h5>
                        <p className="card-text">Some quick example text to build on the card title and make up the bulk
                            of the card's content.</p>
                        <a href="www.google.com" title="Placeholder link title" className="link-icon">
                            Internal Link Example
                            <i className="bi bi-chevron-right"></i>
                        </a>
                    </div>
                </div>

            </DocCard>

            <Heading size="h3">Image size</Heading>

            <p>It is crucial to maintain the images size as light as possible. The proportions mentioned before are
                related to the ratio between the width and the height of the image. The sizes in pixel could be
                different, often related to the initial size of the source image. But in order to mantain the images
                light (to increase the loading speed), the images should not exceed the width of <b>2000px</b> for the hero
                banners and <b>600px</b> for the thumb images. Those sizes allow to display the images in high quality also for
                high density screens, while keeping the size low.</p>

            <p>Software like Adobe Photoshop© are able to save images optimized for the web, by using the command "Save
                for web and devices". We suggest to keep the size within the limits mentioned before and the jpg quality
                up to 60%.</p>

            <p>In general, images should not exceed the size of <b>800KB for the Hero images and 500KB for the thumb images.</b></p>

            <p>There are also online tools to optimize the size of the image while maintaining the quality. One of them is <a
                href="https://imagecompressor.com/" target="_blank" rel="noreferrer">Optimizilla.</a></p>

            <Heading size="h3">Alternative text</Heading>

            <p>Alternative text, or alt text, is read out by screen readers or displayed if an image does not load or if
                images have been switched off.</p>

            <p>All images, except decorative images, must have alt text that:</p>

            <ul>
                <li>tells people what information the image provides</li>
                <li>describes the content and function of the image</li>
                <li>is specific, meaningful and concise</li>

            </ul>

            <p>Use normal punctuation, like commas and full stops, so the text is easy to read and understand.</p>

            <p>Do not:</p>

            <ul>
                <li>include the name of the photographer or person who created the image</li>
                <li>start with ‘Image of’, ‘Graphic of’ or ‘Photo of’</li>
                <li>repeat information from the page</li>
                <li>include extra information not in the image</li>
            </ul>


        </DsLayout>
    );
}


export default ImagesView;