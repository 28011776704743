import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
import { ListNews } from '../../components/fao/FaoComponents'
            

const FaoNewsListView = () => {
    return (
        <DsLayout>
            <Heading size="h1">News list</Heading>

            <RenderCode>
                <ListNews />
            </RenderCode>            
        </DsLayout>
    );
}

export default FaoNewsListView;
