

const SubSiteNavbar = props => {
    return (
        <nav className={'navbar-subsite navbar navbar-expand-lg navbar-light bg-white ' + props.className}>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto">
                    <li className="nav-item">
                        <a className="nav-link" href="#!"><i className="bi bi-house-fill"></i></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#!">Link</a>
                    </li>
                    <li className="nav-item active">
                        <a className="nav-link" href="#!">Active</a>
                    </li>
                    <li className="nav-item dropdown ">
                        <a className="nav-link dropdown-toggle" href="#!" id="navbarDropdown" role="button"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Dropdown
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><a className="dropdown-item" href="#!">Link 1</a></li>
                            <li><a className="dropdown-item" href="#!">Link 2</a></li>
                            <li className="dropdown-submenu"><a className="dropdown-item dropdown-toggle" href="#!">Dropdown</a>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="#!">Sub Link 1</a></li>
                                    <li><a className="dropdown-item" href="#!">Sub Link 2</a></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link disabled" href="#!" tabIndex="-1" aria-disabled="true">Disabled</a>
                    </li>
                </ul>
            </div>
        </nav>
    );
}
  
export default SubSiteNavbar;