import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
import { Link } from '../../components/fao/FaoComponents'


const LinksView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Links</Heading>

            <Heading size="h3">Internal Link</Heading>
            <RenderCode withBorders={true}>
                <a href="#!" title="Placeholder link title" className="link-icon">
                    Internal Link Example
                    <i className="bi bi-chevron-right"></i>
                </a>
            </RenderCode>

            <Heading size="h3">External Link</Heading>
            <RenderCode withBorders={true}>
                <Link links={['External Link Example']} external/> 
            </RenderCode>

            <Heading size="h3">External Link background icon</Heading>
            <RenderCode withBorders={true}>
                <Link links={['External Link Example']}  external bgIco/>
            </RenderCode>

            <Heading size="h3">Publication Link</Heading>
            <RenderCode withBorders={true}>
                <Link links={['Publication Link Example']} publication />
            </RenderCode>

            <Heading size="h3">Internal Links List</Heading>
            <RenderCode withBorders={true}>
                <Link links={['Internal Link Example', 'Internal Link Example']} internal />
            </RenderCode>

            <Heading size="h3">External Links List</Heading>
            <RenderCode withBorders={true}>
                <Link links={['External Link Example', 'External Link Example']} external />
            </RenderCode>

            <Heading size="h3">Publication Links List</Heading>
            <RenderCode withBorders={true}>
                <Link links={['Publication Link Example', 'Publication Link Example']} publication />
            </RenderCode>
            
        </DsLayout>
    );
}

export default LinksView;
