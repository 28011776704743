import DsLayout from '../../layouts/DsLayout'
import { Button } from '../../components/fao/FaoComponents'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'


const ButtonsView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Buttons</Heading>
            <p>Several predefined button styles, each serving its own semantic purpose, with a few extras thrown in for more control.</p>

            <Heading size="h3">Default button</Heading>
            <RenderCode withBorders={true}>
                <Button type="primary" mr>Primary</Button>
                <Button type="secondary" mr>Secondary</Button>
                <Button type="outline">Outline</Button>
            </RenderCode>
            
            <Heading size="h3">Button Icon</Heading>
            <RenderCode withBorders={true}>
                <Button type="primary" hasIcon icon="chevron-right" mr>Primary</Button>
                <Button type="secondary" hasIcon icon="chevron-right">Secondary</Button>
            </RenderCode>
            
            <Heading size="h3">Button Icon Large</Heading>
            <RenderCode withBorders={true}>
                <Button type="primary" hasIconLeft icon="collection-play" style={{width: '18rem'}} block large>Primary</Button>
                <Button type="secondary" hasIconLeft icon="images" style={{width: '18rem'}} block large>Secondary</Button>
            </RenderCode>

            <Heading size="h3">Button Link Icon</Heading>
            <RenderCode withBorders={true}>
                <a href="#!" className="btn btn-link btn-icon d-block btn-lg mb-3" >
                    <i className="bi bi-rss"></i>
                    Primary
                </a>
            </RenderCode>
            
            <Heading size="h3">Button Icon Round</Heading>
            <RenderCode withBorders={true}>
                <Button type="primary" hasIcon icon="chevron-right" round mr></Button>
                <Button type="secondary" hasIcon icon="chevron-right" round></Button>
            </RenderCode>
            
            <Heading size="h3">Button Icon Round Big</Heading>
            <RenderCode withBorders={true}>
                <Button type="primary" hasIcon icon="chevron-right" round roundBig mr />
                <Button type="secondary" hasIcon icon="chevron-right" round roundBig />
            </RenderCode>
            
            <Heading size="h3">Button Icon Round Side</Heading>
            <RenderCode withBorders={true}>
                <Button type="primary" hasIcon icon="envelope" roundSide mr />
                <Button type="primary" hasIcon icon="share" roundSide mr />
                <Button type="primary" hasIcon icon="question-circle" roundSide />
            </RenderCode>            
        </DsLayout>
    );
}

export default ButtonsView;