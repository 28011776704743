import { createContext, useState, useEffect } from "react";


const UserContext = createContext();

export function UserProvider({ children }) {
    const [user, setUser] = useState(false);

    useEffect(() => {
        const aCookies = document.cookie.split(';');
        const auth = aCookies.find(cookie => cookie.includes('auth'));
        const authValue = auth?.split('=')[1];

        if (authValue === 'true') {
            setUser(true);
        }
    }, [user]);

    const userLogin = () => {
        setUser(true);
    };

    return <UserContext.Provider value={{user, userLogin}}>{children}</UserContext.Provider>;
}

export default UserContext;