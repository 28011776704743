import { NavLink } from "react-router-dom";

const SideNavItem = props => {
    return renderItemNav(props.routes);
}

function renderItemNav(routes) {
    return <div className="accordion" id="navAccordion">
        {routes.map((route, idx) => {

            if (route.children) {
                return <div className="accordion-item" key={idx}>
                    <h2 className="accordion-header" id={"navHeading" + idx}>
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#navHeaderCollapse" + idx} aria-expanded="true" aria-controls={"navHeaderCollapse" + idx}>
                        {route.name}
                    </button>
                    </h2>
                    <div id={"navHeaderCollapse" + idx} className="accordion-collapse collapse" aria-labelledby={"navHeading" + idx} data-bs-parent="#navAccordion">
                        <div className="accordion-body">
                            {route.children.map((subRoute, i) => {
                                return <li className="app-sidebar-item" key={`route${idx}-subroute${i}`}><NavLink className="app-sidebar-link m-0 rounded" to={subRoute.path} activeClassName='active'>{subRoute.name}</NavLink></li>
                            })}
                        </div>
                    </div>
                </div>
            } else {
                return <li className="app-sidebar-item" key={idx}><NavLink className="app-sidebar-link m-0 rounded" to={route.path} activeClassName='active'>{route.name}</NavLink></li>
            }

        })}
    </div>

}
  
export default SideNavItem;
