import DsLayout from '../layouts/DsLayout'

const StylesPage = () => {
    return (
        <DsLayout>
            <h1 className="ds-title-fw mt-5 mb-4">Styles</h1>
            <p>Make your service look and feel like FAO.org.</p>
            <p>If you are using FAO Sitefinity, the coded examples in the Design System will not need any additional styling.</p>
            <p>If you need to apply styles manually, you should still follow existing FAO.org conventions. For example, do not assign new meanings to colours, do not change the style of buttons or adjust the thickness of borders on form inputs.</p>
            

        </DsLayout>
    );
}
  
export default StylesPage;
