import React from "react";
import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode, ShowCode} from '../../components/design-system/DsComponents'
import { HeroBanner, CardNews } from '../../components/fao/FaoComponents'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import SwiperCore from "swiper/core";
import cardImage from '../../assets/test-images/card-test-speeches.jpg'

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Navigation]);

const SwiperView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Swiper</Heading>
            <p>This component use Swiper.js, please include/import Swiper into the project</p>
            <p>Swiper documentation: <a href="https://swiperjs.com/get-started" target="_blank" rel="noreferrer">https://swiperjs.com/get-started</a></p>

            <Heading size="h3">Default</Heading>
            <RenderCode>
                <Swiper className="swiper-container" slidesPerView={1} navigation>
                    {[...Array(4)].map((e, i) => <SwiperSlide key={i}><HeroBanner /></SwiperSlide>)}
                </Swiper>
            </RenderCode>

            <Heading size="h3">Slides Per View Auto</Heading>
            <RenderCode>
                <Swiper slidesPerView={"auto"} navigation className="swiper-auto-cards-container" spaceBetween={30}>
                    {[...Array(6)].map((e, i) => <SwiperSlide key={i}><CardNews image={cardImage} title="Lorem ipsum dolor sit amet" date="26/06/2020" body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor." /></SwiperSlide>)}
                </Swiper>
            </RenderCode>

            <Heading size="h3">Slides Per View 2/3</Heading>
            <RenderCode>
                <Swiper id="swiperCardHalf" slidesPerView={1.2} navigation className="swiper-container swiper-cards" spaceBetween={30}
                    breakpoints={{720: {slidesPerView: 2.5, spaceBetween: 30}}}>
                {[...Array(6)].map((e, i) => <SwiperSlide key={i}><CardNews image={cardImage} title="Lorem ipsum dolor sit amet" date="26/06/2020" body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor." /></SwiperSlide>)}
                </Swiper>
            </RenderCode>
            <ShowCode language="js">{jsString}</ShowCode>

        </DsLayout>
    );
}

export default SwiperView;

const jsString = `
const swiperCardHalf = new Swiper('#swiperCardHalf', {
    slidesPerView: 1.2,
    spaceBetween: 30,
    breakpoints: {
      720: {
        slidesPerView: 2.5,
        spaceBetween: 30,
      },
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
})
`;
