import DsLayoutFullWidth from '../../layouts/DsLayoutFullWidth'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
import { Link, TagsList, DynamicList, DynamicListRow } from '../../components/fao/FaoComponents'
import bannerImage from '../../assets/test-images/card-test.jpg'
import publImage from '../../assets/test-images/publication-1.jpg'


const FaoBlogDetailView = () => {
    return (
        <DsLayoutFullWidth>
            
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Heading size="h1">Project detail</Heading>
                    </div>
                </div>
            </div>

            <RenderCode fullWidth={true}>    
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="detail-title">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
                            <img className="image-widget img-responsive img-hero mb-3" src={bannerImage} title="News Banner" alt="News Banner" />
                            <div className="row">
                                <div className="col-md-9">
                                    <p className="small text-color-gray-medium">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                                <div className="col-md-3 text-right">
                                    <p className="small text-color-gray-medium">©FAO/Lorem ipsum dolor</p>
                                </div>
                            </div>
                            
                            {/* TABLE START */}
                            <div className="project-list-item">
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th scope="row" className="bg-gray-light">Title</th>
                                            <td><a href="#!">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Amet deserunt officia fugiat.</a></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="bg-gray-light">Tags</th>
                                            <td><TagsList /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="bg-gray-light">Start Date</th>
                                            <td>02/06/2022</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="bg-gray-light">End Date</th>
                                            <td>04/06/2022</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="bg-gray-light">Status</th>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="bg-gray-light">Donor</th>
                                            <td>Lorem ipsum</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="bg-gray-light">Recipient/Target areas</th>
                                            <td>Lorem, Ipsum, Lorem, Ipsum</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="bg-gray-light">Budget</th>
                                            <td>99999</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="bg-gray-light">Project code</th>
                                            <td>LOREM/IPS/UM</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="bg-gray-light">Partners</th>
                                            <td>
                                                <ul>
                                                    <li>Lorem ipsum dolor sit</li>
                                                    <li>Lorem ipsum dolor sit amet consectetur</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="bg-gray-light">Beneficiaries</th>
                                            <td><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio, ut ab blanditiis deserunt illo commodi in quos enim.</p></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="bg-gray-light">Activities</th>
                                            <td>
                                                <ul>
                                                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad velit sit ducimus saepe vel a cupiditate id ratione doloremque, molestiae autem quos quia cumque hic eveniet eius veniam alias nesciunt!</li>
                                                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia aspernatur inventore facere maxime cumque? Est temporibus ipsa maiores esse, unde repellat aspernatur molestias dolor, amet iusto hic beatae qui nostrum.</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="bg-gray-light">Impact</th>
                                            <td>
                                                <ul>
                                                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad velit sit ducimus saepe vel a cupiditate id ratione doloremque, molestiae autem quos quia cumque hic eveniet eius veniam alias nesciunt!</li>
                                                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia aspernatur inventore facere maxime cumque? Est temporibus ipsa maiores esse, unde repellat aspernatur molestias dolor, amet iusto hic beatae qui nostrum.</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="bg-gray-light">Contact</th>
                                            <td>
                                                <ul>
                                                    <li><a href="#!">Contact 1</a></li>
                                                    <li><a href="#!">Contact 2</a></li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="bg-gray-light">SDGs</th>
                                            <td>
                                                <span className="sdg-small sdg-1 me-2 mb-2"></span>
                                                <span className="sdg-small sdg-2 me-2 mb-2"></span>
                                                <span className="sdg-small sdg-3 me-2 mb-2"></span>
                                                <span className="sdg-small sdg-4 me-2 mb-2"></span>
                                                <span className="sdg-small sdg-5 me-2 mb-2"></span>
                                                <span className="sdg-small sdg-6 me-2 mb-2"></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="bg-gray-light">More topics</th>
                                            <td>
                                                <Link external numbers links={[
                                                    'Lorem ipsum dolor sit amet consectetur', 
                                                    'Lorem ipsum dolor sit', 
                                                    'Lorem ipsum dolor sit amet consectetur'
                                                ]} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="bg-gray-light">Publicatons</th>
                                            <td>
                                                <DynamicList>
                                                    <DynamicListRow publication image={publImage} />
                                                </DynamicList>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>                        
                        </div>
                    </div>
                </div>
            </RenderCode>
        </DsLayoutFullWidth>
    );
}

export default FaoBlogDetailView;
