import DsLayoutFullWidth from '../../layouts/DsLayoutFullWidth'
import { Heading } from '../../components/design-system/DsComponents'
import { Link, TagsList } from '../../components/fao/FaoComponents'
import bannerImage from '../../assets/test-images/card-test.jpg'

const FaoArticleDetailView = () => {
    return (
        <DsLayoutFullWidth>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Heading size="h1">Article detail</Heading>
                    </div>
                </div>
            </div>
            

            <div className="container detail-article">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="detail-title mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</h1>
                        <p className="date">26/11/2020, Rome (Italy)</p>
                        <img className="image-widget img-responsive img-hero mb-3" src={bannerImage} title="News Banner" alt="News Banner" />
                        <div className="row mb-3">
                            <div className="col-md-9">
                                <p className="small text-color-gray-medium">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                            <div className="col-md-3 text-right">
                                <p className="small text-color-gray-medium">©FAO/Lorem Ipsum</p>
                            </div>
                        </div>
                        
                        <div className="row mb-3">
                            <div className="col-12">
                                <TagsList />
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-md-12">
                                <div className="news-detail__body">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                                    <h5 className="sub-title small">Lorem ipsum dolor sit amet</h5>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h5 className="sub-title small">Find out more</h5>
                                        <Link external links={[
                                            'Lorem ipsum dolor sit amet', 
                                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit', 
                                            'Lorem ipsum dolor sit amet', 
                                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit', 
                                            'Lorem ipsum dolor sit amet', 
                                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit', 
                                            'Lorem ipsum dolor sit amet', 
                                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit', 
                                            'Lorem ipsum dolor sit amet', 
                                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit', 
                                            ]} />
                                    </div>
                                    <div className="col-lg-6">
                                        <h5 className="sub-title small">Video</h5>
                                        <div className="card border-0">
                                            <div className="card-image ratio ratio-16x9">
                                                <iframe src="https://www.youtube.com/embed/T3fh95y8AJQ?controls=0" title="sample 1" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                            </div>
                                            <div className="card-body">
                                                <h6 className="title-category">Video</h6>
                                                <h5 className="card-title">
                                                    <a href="#!" clss="title-link">Video Title</a>
                                                </h5>
                                                <h6 className="date">23/03/2021</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </DsLayoutFullWidth>
    );
}

export default FaoArticleDetailView;
