import DsLayoutFullWidth from '../../layouts/DsLayoutFullWidth'
import { Heading } from '../../components/design-system/DsComponents'
import {
    Link,
    ContentBackground,
    Button,
    CardTwitter
} from '../../components/fao/FaoComponents'
import bannerImage from '../../assets/test-images/card-test.jpg'


import identityTest from '../../assets/test-images/identity-test.svg'

const FaoEventsDetailView = () => {
    return (
        <DsLayoutFullWidth>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Heading size="h1">Event detail</Heading>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="detail-title mt-4 mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <p className="event-detail__date"><i className="bi bi-geo-alt-fill"></i>Rome (Italy), 26/11/2020 - 25/11/2022</p>
                        <img className="image-widget img-responsive img-hero mb-3" src={bannerImage} title="News Banner" alt="News Banner" />

                        <div className="row mb-3">
                            <div className="col-md-9">
                                <p className="small text-color-gray-medium">Agong and her child in South Sudan, where over 7 million people are projected to fall into crisis or worse levels of acute food insecurity.</p>
                            </div>
                            <div className="col-md-3 text-right">
                                <p className="small text-color-gray-medium">©FAO/Stefanie Glinski</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-9">

                                <p>Acute hunger is set to soar in over 20 countries 
                                in the coming months without urgent and scaled-up assistance, warn the UN's Food and Agriculture 
                                Organization (FAO) and World Food Programme (WFP) in a new report issued today. Yemen, South 
                                Sudan and northern Nigeria top the list and face catastrophic levels of acute hunger, with families 
                                in pockets of South Sudan and Yemen already in the grip of or at risk of starvation and 
                                death according to the <a href="#!">Hunger Hotspots</a> report. </p>
                                <p>Although the majority of the affected countries are in Africa, acute hunger is
                                due to rise steeply in most world regions - from Afghanistan in Asia, Syria and Lebanon in the Middle 
                                East, to Haiti in Latin America and the Caribbean. 
                                Already, over 34 million people are grappling with emergency levels of acute hunger (IPC4) - meaning 
                                they are one step away from starvation - across the world. 
                                "The magnitude of suffering is alarming. It is incumbent upon all of us to act now and to act fast to 
                                save lives, safeguard livelihoods and prevent the worst situation," said FAO Director-General QU Dongyu.
                                </p>

                                <div className="event-details__related-links">
                                    <h5 className="sub-title">Related Links</h5>
                                    <Link external links={[
                                        'FAO One million cisterns in the Sahel programme', 
                                        'FAO WAPOR (Water Productivity Open Acces Portal)', 
                                        'FAO Hand-in-Hand Geospatial Platform'
                                        ]} />
                                </div>

                                <div className="event-details__partners">
                                    <h5 className="sub-title">Co-organizers</h5>
                                    <ul className="list-unstyled list-partner">
                                        <li><img src={identityTest} className="pop-out" alt="card alt" /></li>
                                        <li><img src={identityTest} className="pop-out" alt="card alt" /></li>
                                        <li><img src={identityTest} className="pop-out" alt="card alt" /></li>
                                    </ul>

                                </div>
                            </div>
                            <div className="col-md-3">
                                <Button type="primary" hasIcon icon="chevron-right" block>Register</Button>
                                <Button type="secondary" hasIcon icon="chevron-right" block>Webcast</Button>
                                {/* <Button type="secondary" hasIcon icon="calendar-plus" block>Add to calendar</Button> */}

                                <div className="details__documents">

                                    <h5 className="sub-title">Documents</h5>

                                    <Link publication links={[
                                        'Agenda', 
                                        'Concept Note', 
                                        'List of speakers'
                                    ]} />

                                </div>

                                <CardTwitter />

                                <ContentBackground className="bg-primary-light">
                                    <h5>Contact</h5>
                                    <p>
                                        <a href="#!">test@fao.org</a>
                                    </p>
                                </ContentBackground>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </DsLayoutFullWidth>
    );
}

export default FaoEventsDetailView;



