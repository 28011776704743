import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
import { ListAudio } from '../../components/fao/FaoComponents'

const FaoArticleListView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Audio list</Heading>

            <Heading size="h3">Audio list with player</Heading>
            <RenderCode>
                <ListAudio player />
            </RenderCode>
            
            <Heading size="h3">Audio list with image</Heading>
            <RenderCode>
                <ListAudio />
                <ListAudio />
                <ListAudio />
                <ListAudio />
                <ListAudio />
                <ListAudio />
                <ListAudio />
                <ListAudio />
            </RenderCode>
            
        </DsLayout>
    );
}

export default FaoArticleListView;
