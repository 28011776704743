import React from 'react';

const elements = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
};
  
const Heading = ({ props, size, children }) => {
    return React.createElement(
      elements[size] || elements.rg, 
      {className: `ds-title-fw mt-5 mb-4 toc-entry`, id: children.toString().replace(/,/g, '').replace(/-/g, '').replace(/ /g, '-')},
      props, 
      children
    );
}

Heading.defaultProps = {
    size: 'h3',
};
  
  
export default Heading;