import DsLayout from '../layouts/DsLayout'


const ContentPage = () => {
    return (
        <DsLayout>
            <h1 className="ds-title-fw mt-5 mb-4">Sitefinity CMS</h1>
            <p>The set of element you will find in FAO Sitefinity backend. Here you can have an overview on how the compontents will work when combined togheter.</p>
            <p>All of the elements in this section are supported by written guidance and contain coded examples where possible.</p>
        </DsLayout>
    );
}
  
export default ContentPage;
