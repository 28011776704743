import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
import { ListPartners } from '../../components/fao/FaoComponents'

const FaoStoryListView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Partners list</Heading>

            <RenderCode>
                <ListPartners />
            </RenderCode>
            
        </DsLayout>
    );
}

export default FaoStoryListView;
