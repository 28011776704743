import DsLayout from '../layouts/DsLayout'


const ExamplePage = () => {
    return (
        <DsLayout>
            <h1 className="ds-title-fw mt-5 mb-4">Examples</h1>
            <p>This section collects all the examples all the page templates and collections of UI elements of FAO.org.
                These examples can be useful also to check whether the release is compatible or not with
                previous version of the markup. </p>

            <p>Please select in the left column the requested example.</p>
        </DsLayout>
    );
}

export default ExamplePage;
