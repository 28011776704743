

const AccordionItem = props => {
    return (
        <div className="accordion-item">
            <div className="accordion-header" id={`heading${props.id}`}>
                <h2 className="mb-0">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#${props.id}`} aria-expanded="true" aria-controls={`collapse${props.id}`}>
                    {props.title}
                    </button>
                </h2>
            </div>
            <div id={`${props.id}`} className={`accordion-collapse collapse ${props.open ? 'show' : ''}`} aria-labelledby={`heading${props.id}`} data-bs-parent={`#accordionExample${props.parentId}`}>
                <div className="accordion-body">
                    {props.bodytext ? props.bodytext : props.children}
                </div>
            </div>
        </div>
    );
}
  
export default AccordionItem;