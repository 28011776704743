import cardImage from '../../assets/test-images/card-test-speeches.jpg'
import cardImageOverlay from '../../assets/test-images/flag-italy.png';
import cardImagePubblication from '../../assets/test-images/publication-1.jpg';


const Card = props => {
    return (
        <div className={`card ${props.className}`} style={props.style} >
            { props.hasImage && renderImage(props.overlay, props.isEvent, props.textOverlay, props.isPublication, props) }
            { props.hasVideo && renderVideo() }
            { props.hasAudio && renderAudio() }
            <div className="card-body">
                <h6 className="title-category">{props.categoryName ? props.categoryName : 'Category'}</h6>
                { props.hasTitle && renderTitle(props.titleHasLink) }
                { props.hasDate && <h6 className="date">26/11/2020</h6> }
                <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                { props.hasIntLink && (
                    <a href="www.google.com" title="Placeholder link title" className="link-icon">
                        Internal Link Example
                        <i className="bi bi-chevron-right"></i>
                    </a>
                )}
            </div>
        </div>
    );
}

const renderImage = (hasOverlay, isTypeEvent, textOverlay, isPublication, props) => {
    return(
        <div className="card-image ratio ratio-3x2">
            
            {
                !isPublication ? <img src={props.imageCustom ? props.imageCustom : cardImage} alt="card alt" /> : <div className="card-pub-image"> <img src={cardImagePubblication} alt="card alt" /></div>
            }


            {hasOverlay && (
                <div className="card-img-overlay">
                    {
                        !isTypeEvent ? <img src={cardImageOverlay} alt="card alt" /> : <i className="bi bi-geo-alt-fill d-flex me-1"></i>
                    }
                    <p className="text-white mb-0">{textOverlay}</p>
                </div>
            )}
        </div>
    );
}

const renderVideo = () => {<div className=""></div>
    return(
        <div v-if="hasVideo" className="card-image ratio ratio-3x2" style={{position: 'relative'}}>
            <iframe src="https://www.youtube.com/embed/WeoIsjYBQH0?controls=0" title="iframe1" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
    );
}

const renderAudio = () => {
    return(
        <div v-if="hasVideo" className="ratio ratio-3x2">
            <iframe title="audio iframe" width="100%" height="auto" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1176941233&color=%23116AAB&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
        </div>
    );

}

const renderTitle = hasLink => {
    return(
        <h5 v-if="hasTitle" className="card-title">
            {hasLink ? (
                <a href="www.google.com" className="title-link">Card title</a>
            ) : (
                <p>Card title</p>
            )}
        </h5>
    );
}

  
export default Card;