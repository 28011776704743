

const Footer = props => {
    return (
        <div className="--doc-footer mt-5 mb-4">
            <hr />
            <p className="text-right">©FAO {getYear()}</p>
        </div>
    );

    function getYear() {
        return new Date().getFullYear();
    }
}
  
export default Footer;