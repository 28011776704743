

const Breadcrumb = props => {
    return(
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="#!">Home</a></li>
                <li className="breadcrumb-item"><a href="#!">About</a></li>
                <li className="breadcrumb-item active" aria-current="page">Current Page</li>
            </ol>
        </nav>
    );
}

export default Breadcrumb;