import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
import { ListPublication } from '../../components/fao/FaoComponents'

const FaopublicationsListView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Publication list</Heading>
 
            <RenderCode>
                <ListPublication />
            </RenderCode>
        </DsLayout>
    );
}

export default FaopublicationsListView;
