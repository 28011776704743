import DsLayout from '../layouts/DsLayout'


const ContactPage = () => {
    return (
        <DsLayout>
            <h1 className="ds-title-fw mt-5 mb-4">Contact</h1>
            
        </DsLayout>
    );
}
  
export default ContactPage;
