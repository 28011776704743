import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
import { Breadcrumb } from '../../components/fao/FaoComponents'


const BreadcrumbsView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Breadcrumbs</Heading>
            
            <Heading size="h3">Default</Heading>
            <RenderCode>
                <Breadcrumb />
            </RenderCode>

            <p className='mt-5'>For more info please visit the <a href="https://getbootstrap.com/docs/5.2/components/breadcrumb/" target="_blank" rel="noreferrer">Bootstrap v5 Breadcrumb Component page</a></p>
        </DsLayout>
    );
}

export default BreadcrumbsView;
