import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode, RenderOnLogin } from '../../components/design-system/DsComponents';
import React, { useState, useEffect } from 'react';
const sdgAmount = 17;

const SdgView = () => {
    const langs = ['en', 'fr', 'es', 'zh', 'ar', 'ru'];
    const [selectedLang, setSelectedLang] = useState(langs[0]);

    useEffect(() => {
        document.documentElement.lang = selectedLang;
        return () => {
          document.documentElement.lang = 'en';
        };
    }, [selectedLang]);
    
    const handleLangChange = (event) => {
        setSelectedLang(event.target.value);
    };


      return (
        <DsLayout>
            <Heading size="h1">Sdg</Heading>
            
            <p>SDGs Images can be used in different sizes:
                <ol>
                    <li>Normal size: CSS class "<b>sdg sdg-[number]</b>"</li>
                    <li>Small size (no text): CSS class "<b>sdg-small sdg-[number]</b>"</li>    
                </ol>
                <br />
                Sdg images will automatically adapt to the selected HTML language for the text translation.</p>
            <RenderOnLogin>
                <div>
                    <label htmlFor="lang">Available languages:</label>
                    <div className='mb-2'>
                        {langs.map((lang) => (
                        <label className='mx-2' key={lang}>
                            <input className='mr-4' type="radio" name="lang" value={lang} checked={selectedLang === lang} onChange={handleLangChange} />
                            {lang.toLocaleUpperCase()}
                        </label>
                        ))}
                    </div>
                </div>
            </RenderOnLogin>
            <RenderCode withBorders={true}>
                {Array.from(Array(sdgAmount), (e, i) => {
                    return <span className={`sdg sdg-${i + 1}`} key={i}  />
                })}
            </RenderCode>

            <Heading size="h3">Sdg small</Heading>
            <RenderCode withBorders={true}>
                {Array.from(Array(sdgAmount), (e, i) => {
                    return <span className={`sdg-small me-2 mb-2 sdg-${i + 1}`} key={i}></span>
                })}
            </RenderCode>

        </DsLayout>
    );
}

export default SdgView;
