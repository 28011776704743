import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
import {ListPhotoGallery } from '../../components/fao/FaoComponents'


const FaoPhotoGalleryListView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Photo gallery List</Heading>



            <RenderCode>
                <ListPhotoGallery />
            </RenderCode>

        </DsLayout>
    );
}

export default FaoPhotoGalleryListView;
