import DsLayout from '../../layouts/DsLayout'
import {CardStatic, CardStaticNoBorder, CardStaticBackground, CardStaticVideo, CardStaticOverlay, CardHorizontal} from '../../components/fao/FaoComponents'
import {Heading, RenderCode} from '../../components/design-system/DsComponents'
import cardImage from '../../assets/test-images/card-test-speeches.jpg'


const CardsExampleView = () => {
    return (
        <DsLayout>
            <style>
                {`
                .card:not(.card-horizontal) { max-width: 18rem; }
                .card.card-overlay { max-width: 22rem; }
                `}
            </style>
            <Heading size="h1">Cards with live event examples</Heading>

            <Heading size="h3">Default</Heading>
            <RenderCode>
                <CardStatic 
                    image={cardImage} 
                    category="category"
                    title="Lorem ipsum dolor sit amet" 
                    date="26/06/2020" 
                    body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor." 
                    live
                />
            </RenderCode>
            
            <Heading size="h3">Default no border</Heading>
            <RenderCode>
                <CardStaticNoBorder 
                    image={cardImage} 
                    category="category"
                    title="Lorem ipsum dolor sit amet" 
                    date="26/06/2020" 
                    body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor." 
                    live
                />
            </RenderCode>
            
            <Heading size="h3">Default background gray</Heading>
            <RenderCode>
                <CardStaticBackground 
                    image={cardImage} 
                    category="category"
                    title="Lorem ipsum dolor sit amet" 
                    date="26/06/2020" 
                    body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor." 
                    live
                />
            </RenderCode>
            
            <Heading size="h3">Default embed video</Heading>
            <RenderCode>
                <CardStaticVideo 
                    iframe="https://www.youtube.com/embed/WeoIsjYBQH0?controls=0" 
                    category="category"
                    title="Lorem ipsum dolor sit amet" 
                    date="26/06/2020" 
                    body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor." 
                    live
                />
            </RenderCode>

            <Heading size="h3">Horizontal background caption</Heading>
            <RenderCode>
                <CardHorizontal live />
            </RenderCode>
            
            <Heading size="h3">Image overlay</Heading>
            <RenderCode>
                <CardStaticOverlay 
                    image={cardImage} 
                    category="category"
                    title="Lorem ipsum dolor sit amet" 
                    date="26/06/2020" 
                    live
                />
            </RenderCode>

        </DsLayout>
    );
}

export default CardsExampleView;