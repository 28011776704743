import PropTypes from "prop-types";

const Link = props => {
    let classes = 'bi';

    if (props.internal) classes += ' bi-chevron-right';
    if (props.external) classes += ' bi-globe';
    if (props.bgIco) classes += ' bi-bgico';
    if (props.publication) classes += ' bi-file-earmark-text';

    if (props.links.length === 1) {
        return (
            <a href="#!" title="Placeholder link title" className="link-icon">
                {!props.iconRight ? <i className={classes}></i> : ''}
                {/* <i className={classes}></i> */}
                <span>{props.links[0]} {props.numbers ? `(${Math.floor(Math.random() * 20)})` : ''}</span>
                {props.iconRight ? <i className={classes}></i> : ''}
            </a>
        );
    }

    return (
        <ul className="simple-list list-unstyled">
            {
                props.links.map((link, i) => {
                    return(
                        <li key={i}>
                            <a href="#!" title="Placeholder link title" className="link-icon">
                                <i className={classes}></i>
                                <span>{link} {props.numbers ? `(${Math.floor(Math.random() * 20)})` : ''}</span>
                            </a>
                        </li>
                    )
                })
            }
        </ul>
    );
}

Link.propTypes = {
  bgIco: PropTypes.bool,
  external: PropTypes.bool,
  internal: PropTypes.bool,
  links: PropTypes.arrayOf(PropTypes.string).isRequired,
  numbers: PropTypes.bool,
  publication: PropTypes.bool
}

export default Link;
