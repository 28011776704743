import DsLayout from "../../layouts/DsLayout";
import {
  Heading,
  RenderCode,
} from "../../components/design-system/DsComponents";
import {
  DynamicSearch,
  DynamicList,
  DynamicListRow,
  DynamicListResources,
} from "../../components/fao/FaoComponents";
import cardImage from "../../assets/test-images/card-test.jpg";
import publImage from "../../assets/test-images/publication-1.jpg";
import mediaImage from "../../assets/test-images/card-test.jpg";

const AccordionsView = () => {
  return (
    <DsLayout>
      <Heading size="h1">Search</Heading>
      <p>
        Please note that the fields inside the Advanced search in the example
        below are just placeholder to show how to build the component.
      </p>
      <p>
        Please use required advanced search field for the required dynamic list
        type.
      </p>
      <p>
        For more information about forms please check the Bootstrap 5
        documentation at:{" "}
        <a href="https://getbootstrap.com/docs/5.2/forms/overview/">
          https://getbootstrap.com/docs/5.2/forms/overview/
        </a>
      </p>

      <Heading size="h3">Search</Heading>

      <RenderCode withBorders={true}>
        <DynamicSearch />
      </RenderCode>

      <Heading size="h3">Search with tabs</Heading>

      <RenderCode withBorders={true}>
        <DynamicSearch />

        <div className="tabbed-content">
          <ul className="nav nav-tabs" id="tabs-1" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                id="tab_1-1"
                data-bs-toggle="tab"
                href="#tab-news"
                role="tab"
                aria-selected
              >
                News
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link "
                id="tab_2-1"
                data-bs-toggle="tab"
                href="#tab-publications"
                role="tab"
              >
                Publications
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link "
                id="tab_2-1"
                data-bs-toggle="tab"
                href="#tab-videos"
                role="tab"
              >
                Videos
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link "
                id="tab_2-1"
                data-bs-toggle="tab"
                href="#tab-audios"
                role="tab"
              >
                Audios
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link "
                id="tab_2-1"
                data-bs-toggle="tab"
                href="#tab-elearning"
                role="tab"
              >
                E-Learning
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link "
                id="tab_2-1"
                data-bs-toggle="tab"
                href="#tab-photogallery"
                role="tab"
              >
                Photogallery
              </a>
            </li>
          </ul>

          <div className="mt-4 d-flex justify-content-between align-items-center">
            <p className="ms-0 mb-3">Results by: Lorem ipsum dolor sit amet</p>
            <div className="dropdown dropdown-sort-by">
              {/* <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Sort by</button> */}

              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="#!">
                    ...
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#!">
                    ...
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#!">
                    ...
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="tab-content pt-3" id="tab-content-1">
            <div
              className="tab-pane fade show active"
              id="tab-news"
              role="tabpanel"
            >
              <DynamicList>
                <DynamicListRow news image={cardImage} />
                <DynamicListRow news image={cardImage} />
              </DynamicList>
            </div>

            <div
              className="tab-pane fade show"
              id="tab-publications"
              role="tabpanel"
            >
              <DynamicList>
                <DynamicListRow publication image={publImage} />
                <DynamicListRow publication image={publImage} />
              </DynamicList>
            </div>

            <div className="tab-pane fade show" id="tab-videos" role="tabpanel">
              <div className="dynamic-list ">
                <div className="row dynamic-list-row list-resources list-video bg-gray-dark rounded">
                  <div className="col-md-3 col-lg-2">
                    <div className="list-image ratio ratio-3x2">
                      <img
                        className="img-fluid"
                        src={mediaImage}
                        alt="resource"
                      />
                    </div>
                  </div>
                  <div className="col-md-9 col-lg-10">
                    <h5 className="title-link pt-3 pt-md-0">
                      <a href="#!">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      </a>
                    </h5>
                    <h6 className="date text-color-gray-medium">26/11/2020</h6>
                    <p>
                      <b>VIDEO </b>Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit, sed do eiusmod tempor incididunt ut
                      labore et dolore magna aliqua. Ut enim ad minim veniam,
                      quis nostrud exercitation ullamco laboris nisi ut aliquip
                      ex ea commodo consequat.{" "}
                    </p>
                    <a
                      href="#!"
                      className="btn btn-link btn-icon text-color-gray-medium text-uppercase ps-0"
                    >
                      Play Video<i className="bi bi-play-fill text-white"></i>
                    </a>
                  </div>
                </div>

                <div className="row dynamic-list-row list-resources list-video bg-gray-dark rounded">
                  <div className="col-md-3 col-lg-2">
                    <div className="list-image ratio ratio-3x2">
                      <img
                        className="img-fluid"
                        src={mediaImage}
                        alt="resource"
                      />
                    </div>
                  </div>
                  <div className="col-md-9 col-lg-10">
                    <h5 className="title-link pt-3 pt-md-0">
                      <a href="#!">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      </a>
                    </h5>
                    <h6 className="date text-color-gray-medium">26/11/2020</h6>
                    <p>
                      <b>VIDEO </b>Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit, sed do eiusmod tempor incididunt ut
                      labore et dolore magna aliqua. Ut enim ad minim veniam,
                      quis nostrud exercitation ullamco laboris nisi ut aliquip
                      ex ea commodo consequat.{" "}
                    </p>
                    <a
                      href="#!"
                      className="btn btn-link btn-icon text-color-gray-medium text-uppercase ps-0"
                    >
                      Play Video<i className="bi bi-play-fill text-white"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="tab-pane fade show" id="tab-audios" role="tabpanel">
              <DynamicList>
                <DynamicListResources
                  audio
                  audioPlayer
                  title="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                  image={cardImage}
                />
                <DynamicListResources
                  audio
                  audioPlayer
                  title="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                  image={cardImage}
                />
              </DynamicList>
            </div>

            <div
              className="tab-pane fade show"
              id="tab-elearning"
              role="tabpanel"
            >
              <DynamicList>
                <DynamicListResources
                  elearning
                  title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                />
                <DynamicListResources
                  elearning
                  title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                />
              </DynamicList>
            </div>

            <div
              className="tab-pane fade show"
              id="tab-photogallery"
              role="tabpanel"
            >
              <DynamicList>
                <DynamicListResources
                  photog
                  title="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                  image={cardImage}
                />
                <DynamicListResources
                  photog
                  title="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                  image={cardImage}
                />
              </DynamicList>
            </div>
          </div>
        </div>
      </RenderCode>
    </DsLayout>
  );
};

export default AccordionsView;
