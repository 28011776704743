import React from 'react'
import {
    Header,
    SubSiteNavbar,
    Card,
    ContentBlock,
    CardTwitter,
    DynamicList,
    DynamicListRow,
    Link,
    ContentBackground,
    Footer,
    ShareModal,
    EmailModal,
    Breadcrumb
} from '../../components/fao/FaoComponents'
// import bannerImage from '../../assets/test-images/one-health-banner.jpg'
import cardImage1 from '../../assets/test-images/card-test-2.jpeg'
// import cardImage2 from '../../assets/test-images/card-test-3.jpeg'
import cardImage3 from '../../assets/test-images/card-test-4.jpeg'
import DsLayoutFullWidth from '../../layouts/DsLayoutFullWidth'
import heroVideoPoster from '../../assets/test-images/poster.jpg'
import heroVideo from '../../assets/videos/emergencies-banner.mp4'
import publImage from '../../assets/test-images/publication-1.jpg'



const NewsDetailExampleView = () => {
    return (
        <DsLayoutFullWidth>
            <Header subsite />

            <div className="subheader">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="content-icons">

                                <Breadcrumb/>

                                <div className="content-icons-right">

                                    <a href="#!" type="button" className="d-inline-flex" data-bs-toggle="modal" data-bs-target="#emailModal">
                                        <i className="bi bi-envelope"></i>
                                        <span>Email</span>
                                    </a>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <h2 className="page-title">Title of the Thematic site/page</h2>
                            <SubSiteNavbar />
                        </div>
                    </div>


                </div>
            </div>

            <main>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="hero-banner">
                                <video className="hero-banner-video" loop autoPlay muted playsInline preload="auto"
                                    poster={heroVideoPoster}>
                                    <source src={heroVideo} type="video/mp4"/>
                                </video>
                                <div className="hero-caption">
                                    <h5 className="title-link"><a href="#!">Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus eum delectus ratione alias</a></h5>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus eum delectus ratione alias, at qui autem deleniti aut odio dolorum corrupti maxime dolorem, iure porro possimus deserunt praesentium eaque velit.</p>
                                </div>
                            </div>

                            <ContentBackground className="bg-primary-light">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                aboris nisi ut aliquip ex ea commodo consequat.</p>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                aboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                                dolor in reprehenderit in voluptate velit esse cillum dolore eu
                                fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                                sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            </ContentBackground>
                            
                            <h5 className="sub-title">Highlights</h5>

                            <div className="row">
                                <div className="col-md-4">
                                    <Card hasImage hasDate hasTitle titleHasLink imageCustom={cardImage1} categoryName="NEWS" />
                                </div>
                                <div className="col-md-4">
                                    <Card hasImage hasDate hasTitle titleHasLink imageCustom={cardImage3} categoryName="IN FOCUS" />
                                </div>
                                <div className="col-md-4">
                            
                                    <Card hasImage hasDate hasTitle titleHasLink isPublication className="card-publication" categoryName="PUBLICATION" />
                                </div>
                            </div>

                            <h5 className="sub-title">Multimedia</h5>

                            <div className="row">
                                <div className="col-lg-3">
                                    <Card hasVideo hasDate hasTitle titleHasLink hasIntLink className="bg-gray-dark"/>
                                </div>

                                <div className="col-lg-3">
                                    <Card hasAudio hasDate hasTitle titleHasLink hasIntLink className="bg-gray-dark card-audio"/>
                                </div>

                                <div className="col-lg-3">
                                    <Card hasImage hasDate hasTitle titleHasLink className="bg-gray-dark card-photo-gallery" hasIntLink/>
                                </div>

                                <div className="col-lg-3">
                                    <a href="!#" className="btn btn-primary btn-icon btn-lg d-block mb-3">
                                        <i className="bi bi-mic-fill"></i>
                                        Audio
                                    </a>

                                    <a href="!#" className="btn btn-primary btn-icon btn-lg d-block mb-3">
                                        <i className="bi bi-images"></i>
                                        Photo
                                    </a>

                                    <a href="!#" className="btn btn-primary btn-icon btn-lg d-block mb-3">
                                        <i className="bi bi-collection-play"></i>
                                        Video
                                    </a>
                                </div>
                            </div>

                            <h5 className="sub-title">Publications</h5>

                            <div className="row">
                                <div className="col-12">
                                    <DynamicList className="dynamic-list-publication">
                                        <DynamicListRow publication image={publImage} />
                                        <DynamicListRow publication image={publImage} />
                                    </DynamicList>

                                    <div className="sfContentBlock sf-Long-text">
                                        <p className="text-end">
                                            <a className="link-icon" href="!#">see all publications <em className="bi bi-chevron-right"></em></a>
                                        </p>
                                    </div>


                                </div>
                            </div>

                            

                            <div className="row mb-4">
                                <div className="col-9">
                                    <h4 className="sub-title">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h4>
                                    <ContentBlock>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                            Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                            aboris nisi ut aliquip ex ea commodo consequat.</p>

                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco aboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                                aboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                                                dolor in reprehenderit in voluptate velit esse cillum dolore eu
                                                fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                                                sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                            Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                            aboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                                            dolor in reprehenderit in voluptate velit esse cillum dolore eu
                                            fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                                            sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                            Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                            aboris nisi ut aliquip ex ea commodo consequat.</p>
                                    </ContentBlock>
                                    
                                </div>

                                <div className="col-3">
                                    <h4 className="sub-title">More on this topic</h4>
                                    
                                    <Link links={['External Link Example', 'External Link Example']} external />
                                    
                                    <CardTwitter/>

                                    <ContentBackground className="bg-primary-light">
                                        <h5>Contact</h5><p><a href="mailto:contact@fao.org">contact@fao.org</a></p>
                                    </ContentBackground>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </main>

            <Footer />
            <ShareModal />
            <EmailModal />
        </DsLayoutFullWidth>
    );
}
  
export default NewsDetailExampleView;