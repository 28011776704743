const FooterApp = () => {
  return (
    <footer className="footer footer-app">
      <div className="container pt-0">
        <div className="row">
          <div className="footer-links col-lg-8">
            <ul className="list-inline">
              <li className="list-inline-item">
                <a
                  href="http://fao.org/contact-us/en/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact us
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  href="http://fao.org/contact-us/terms/en/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms and Conditions
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  href="http://fao.org/contact-us/data-protection-and-privacy/en/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data protection and privacy
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  href="http://fao.org/contact-us/scam-alert/en/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Scam Alert
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  href="https://www.fao.org/aud/69204/en"
                  target="_blank"
                  rel="noreferrer"
                >
                  Report Misconduct
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-4 text-end">
            <a
              className="copyright"
              href="https://www.fao.org/contact-us/terms/en/"
              target="_blank"
              rel="noreferrer"
            >
              © FAO&nbsp;2024
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterApp;
