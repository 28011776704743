import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode, RenderOnLogin } from '../../components/design-system/DsComponents'


const IconsView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Icons</Heading>
            <p>Bootstrap Icons are SVGs, so you can include them into your HTML in a few ways depending on how your project is setup. Bootstrap Icons include a width and height of 1em by default to allow for easy resizing via font-size.</p>
            <p>Include the icon web fonts in your page via CSS, then reference the class names as needed in your HTML</p>
            <p>Use font-size and color to change the icon appearance.</p>
            <p>Please find the full list of icons <a href="https://icons.getbootstrap.com/">here</a></p>

            <RenderCode>
                <i className="bi-alarm"></i>
            </RenderCode>
            
            <RenderCode>
            <i className="bi-alarm" style={{fontSize: '2rem', color: '#116aab'}} />
            </RenderCode>

            <RenderOnLogin>
                <Heading size="h4">Icons that we usee in the styles with the BootStrap Icon Code</Heading>
                {Array.from(Array(20), (e, i) => {
                    return <i className={'m-4 bi icon-' + i} key={i}></i>
                })}
            </RenderOnLogin>
            
            
    
        </DsLayout>
    );
}

export default IconsView;
