import PropTypes from "prop-types";
import DsLayoutFullWidth from "../../layouts/DsLayoutFullWidth";
import { ShowCode } from "./DsComponents";
import  UserContext  from '../../contexts/UserContext'
import { useContext, useState, useEffect } from 'react'


const RenderCode = props => {
    const [auth, setAuth] = useState(false);
    const { user } = useContext(UserContext);

    useEffect(() => {
        setAuth(user);
    }, [user]);

    const showCode = <ShowCode
      codeType={!props.language ? "HTML" : props.language} 
      skipStripHTMLComment={props.skipStripHTMLComment}
      skipStripIFrameSrc={props.skipStripIFrameSrc}
      skipStripImgSrc={props.skipStripImgSrc}
      skipStripVideoSrc={props.skipStripVideoSrc}  
      {...(props.language && {language: props.language})}>
        {props.children}
    </ShowCode>;

    const defaultCode = <div className="render-code">
        <div className={props.withBorders && 'render-code-element'}>
            {props.children}
        </div>
        {auth && <div>{!props.hideCode && showCode}</div>}        
    </div>;

    const fullwidthCode = <DsLayoutFullWidth>
        {props.children}
        <div className="container">
            <div className="row">
                <div className="col-12">
                    {!props.hideCode && showCode}
                </div>
            </div>
        </div>
    </DsLayoutFullWidth>

    return props.fullWidth ? fullwidthCode : defaultCode;
}

RenderCode.propTypes = {
  children: PropTypes.any.isRequired,
  fullWidth: PropTypes.bool,
  withBorders: PropTypes.bool,
  language: PropTypes.string,
  hideCode: PropTypes.bool,
  skipStripHTMLComment: PropTypes.bool,
  skipStripImgSrc: PropTypes.bool,
  skipStripVideoSrc: PropTypes.bool,
  skipStripIFrameSrc: PropTypes.bool
}

export default RenderCode;
