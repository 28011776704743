import DsLayout from '../../layouts/DsLayout'
import { SideNav, FooterApp, TopNav } from '../../components/fao/FaoComponents'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'


const AppLayoutView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Application Layout</Heading>

            <p className="mb-4">Web applications, including dashboards implemented in PowerBI, Tableau or any other platform approved by <a href="mailto:IT-solutions@fao.org">IT-solutions@fao.org</a> should implement this simple template with the same header, navigation pattern and footer to guarantee basic branding usability and compliance with FAO terms and conditions and data privacy.</p>
            
            <RenderCode withBorders={true}>
                <TopNav search login languages title="DESIGN SYSTEM"/>

                <div id="appLayout" className="pe-0 pe-lg-4 app-layout">
 
                    
                <SideNav id="sideNavApp" title="Browse docs" target="#sideNavApp" toggle>
                    <li className="app-sidebar-item"><a className="app-sidebar-link m-0 rounded active" href="!#">Item 1</a></li>
                    <li className="app-sidebar-item"><a className="app-sidebar-link m-0 rounded" href="!#">Item 2</a></li>
                    <li className="app-sidebar-item"><a className="app-sidebar-link m-0 rounded" href="!#">Item 3</a></li>
                    <li className="app-sidebar-item"><a className="app-sidebar-link m-0 rounded" href="!#">Item 4</a></li>
                    <li className="app-sidebar-item"><a className="app-sidebar-link m-0 rounded" href="!#">Item 5</a></li>
                    <li className="app-sidebar-item"><a className="app-sidebar-link m-0 rounded" href="!#">Item 6</a></li>
                </SideNav>
            
                

                    <main className="app-main order-1">
                        <div className="container">
                            ...
                        </div>

                        
                    </main>

                </div>

                <FooterApp />

            </RenderCode>   
            <style>
                {`
                .app-main {
                    min-height: 400px;
                }
                @media (min-width: 992px) {
                    #sideNavApp {
                        height: 400px;
                    }
                }
                
                `}
            </style>       
        </DsLayout>
    );
}

export default AppLayoutView;