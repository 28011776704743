import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
import { ListVideo } from '../../components/fao/FaoComponents'


const FaoArticleListView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Video List</Heading>

            <Heading size="h3">Video list with player</Heading>
            <RenderCode>
                <ListVideo player />
            </RenderCode>
            
            <Heading size="h3">Video list with image</Heading>
            <RenderCode>
                <ListVideo />
            </RenderCode>
        </DsLayout>
    );
}

export default FaoArticleListView;
