import DsLayout from "../../layouts/DsLayout";
import {
  Heading,
  RenderCode,
} from "../../components/design-system/DsComponents";
import { Footer, FooterApp } from "../../components/fao/FaoComponents";

const FooterView = () => {
  return (
    <DsLayout>
      <Heading size="h1">Footer</Heading>
      <RenderCode withBorders={true}>
        <Footer />
      </RenderCode>

      <Heading size="h1">Footer Application</Heading>

      <RenderCode withBorders={true}>
        <FooterApp />
      </RenderCode>
    </DsLayout>
  );
};

export default FooterView;
