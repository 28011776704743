// import DsLayout from '../layouts/DsLayout'
import { ChangelogViewer } from '../components/design-system/DsComponents';


const Changelog = () => {
    return (
        <div className="container">
            <ChangelogViewer />
        </div>
    );
}
  
export default Changelog;
