import React from "react";


const SideNav = props => {
  return (
    <aside id={props.id} className={`app-sidebar bg-gray-light me-4 collapse collapse-horizontal show`}>
        <div className="offcanvas-lg offcanvas-start" tabIndex="-1" id="bdSidebar" aria-labelledby="bdSidebarOffcanvasLabel">

          <div className="offcanvas-header border-bottom">
            <h5 className="offcanvas-title" id="bdSidebarOffcanvasLabel">{ props.title }</h5>

            {props.toggle && <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" data-bs-target="#bdSidebar"></button>}
            
          </div>
          
          {props.toggle && renderToggle(props.id)}

          <div className="offcanvas-body">

            <nav className="bd-links w-100" id="bd-docs-nav" aria-label="Docs navigation">
              <ul className="bd-links-nav list-unstyled mb-0 py-4 pb-lg-2 px-lg-3">
                { props.children }
              </ul>
            </nav>

          </div>

        </div>
      </aside>
  );
}

const renderToggle = (id) => {
    return(
      <div className="d-none d-lg-flex justify-content-end align-items-center w-100 border-bottom p-1">
        <button className="btn btn-app-collapse" type="button" data-bs-toggle="collapse" data-bs-target={`#${id}`} aria-expanded="true" aria-controls={id}>
          <i id="asideToggleIcon" className="bi bi-x fs-4"></i>
          <i id="asideToggleIcon" className="bi bi-list fs-4"></i>
        </button>
      </div>
    );
}


  
export default SideNav;