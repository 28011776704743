import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
import { CardAudio } from '../../components/fao/FaoComponents'

const FaoAudioCardView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Audio card</Heading>
            <style>{`.card { max-width: 18rem; }`}</style>
            <RenderCode>
                <CardAudio 
                    iframe="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1176941233&color=%23116AAB&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
                    title="Lorem ipsum dolor sit amet" 
                    date="26/06/2020" 
                    body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor." 
                />
            </RenderCode>
        </DsLayout>
    );
}

export default FaoAudioCardView;
