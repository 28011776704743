import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
import { ListArticle } from '../../components/fao/FaoComponents'

const FaoArticleListView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Article list</Heading>
            
            <RenderCode>
                <ListArticle />
            </RenderCode>
        </DsLayout>
    );
}

export default FaoArticleListView;
