import DsLayout from '../../layouts/DsLayout'
import {Heading, RenderCode} from '../../components/design-system/DsComponents'
// import {ProjectsListItem} from '../../components/fao/FaoComponents'

const FaoMeetingsDocsListView = () => {
    return (
        <DsLayout>
            <Heading size="h3">Meetings documents list</Heading>
            <RenderCode>
                <div className="table-responsive meetings-docs-list">
                    <table className="table">
                        <thead className="bg-gray-light">
                        <tr>
                            <th>Number</th>
                            <th>Title</th>
                            <th>Category</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>C 2023/1</td>
                            <td><a href="#!">Lorem ipsum dolor sit amet consectetur adipisicing elit.</a></td>
                            <td>NL350</td>
                        </tr>
                        <tr>
                            <td>C 2023/1</td>
                            <td><a href="#!">Lorem ipsum dolor sit amet consectetur adipisicing elit.</a></td>
                            <td>NL350</td>
                        </tr>
                        <tr>
                            <td>C 2023/1</td>
                            <td><a href="#!">Lorem ipsum dolor sit amet consectetur adipisicing elit.</a></td>
                            <td>NL350</td>
                        </tr>

                        </tbody>
                    </table>
                </div>
            </RenderCode>
            
        </DsLayout>
    );
}

export default FaoMeetingsDocsListView;
