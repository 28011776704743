import { Link } from '../FaoComponents'
import { Category } from '../typo/Typo'


const CardStaticVideo = props => {
    return (
        <div className={`card${props.live ? ' event-live' : ''}`}>
            <div className="card-image ratio ratio-3x2">
                <iframe src={props.iframe} title={props.title}></iframe>
            </div>
            <div className="card-body">
                {props.live && <h6 className="card-event-live">Live</h6>}
                <Category>{props.category}</Category>
                <h5 className="card-title">
                    <a href="#!" className="title-link">{props.title}</a>
                </h5>
                <p className="card-text">{props.body}</p>
                <Link links={['Internal Link Example']} internal iconRight />
            </div>
        </div>
    );
}
  
export default CardStaticVideo;
