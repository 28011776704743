import DsLayout from '../../layouts/DsLayout'
import { ColorCard, Heading } from '../../components/design-system/DsComponents'


const ColorsView = () => {
    const bgColorStyle = {
        width:'20px', 
        height:'20px', 
        display:'inline-block',
        marginRight: '.5rem', 
        border: '1px solid #999999'
    }

    return (
        <DsLayout>
            <Heading size="h1">Colors</Heading>
            <p>Always use the FAO.org colour palette.</p>

            <Heading size="h3">Colour contrast</Heading>
            <p>You must make sure that the contrast ratio of text and interactive elements in your service meets level <a href="https://www.w3.org/TR/WCAG21/#contrast-minimum">AA of the Web Content Accessibility Guidelines (WCAG 2.1).</a></p>
            
            <Heading size="h3">Main colors</Heading>
            <ColorCard color="primary" title="Primary" hex="116AAB" rgb="17, 106, 171" whiteText />
            <ColorCard color="primary-light" title="Primary Light" hex="E5ECF4" rgb="229, 236, 244" />
            <ColorCard color="gray-dark" title="Gray Dark" hex="545454" rgb="84, 84, 84" whiteText />
            <ColorCard color="gray-medium" title="Gray Medium" hex="999999" rgb="153, 153, 153" whiteText />
            <ColorCard color="gray-light" title="Gray Light" hex="F2F2F2" rgb="242, 242, 242"/>
            <ColorCard color="white-fao" title="White FAO" hex="F7F8F9" rgb="127, 196, 253" />
            <ColorCard color="orange" title="Orange" hex="F58320" rgb="245, 131, 32" whiteText />
            <ColorCard color="caption" title="Caption" hex="1C4767" rgb="28, 71, 103" whiteText />
            <ColorCard color="emergency" title="Emergency" hex="980000" rgb="152, 0, 0" whiteText />
            <ColorCard color="un-blue" title="UN blue" hex="5792c9" rgb="87, 146, 202" whiteText />

            <Heading size="h3">Color background CSS classes</Heading>
            <p>It is possible to use the following classes to add background color to elements</p>
            <ul className="list-unstyled">
                <li><span className="bg-primary" style={bgColorStyle}></span>.bg-primary</li>
                <li><span className="bg-primary-light" style={bgColorStyle}></span>.bg-primary-light</li>
                <li><span className="bg-gray-dark" style={bgColorStyle}></span>.bg-gray-dark</li>
                <li><span className="bg-gray-medium" style={bgColorStyle}></span>.bg-gray-medium</li>
                <li><span className="bg-gray-light" style={bgColorStyle}></span>.bg-gray-light</li>
                <li><span className="bg-white-fao" style={bgColorStyle}></span>.bg-white-fao</li>
                <li><span className="bg-orange" style={bgColorStyle}></span>.bg-orange</li>
                <li><span className="bg-caption" style={bgColorStyle}></span>.bg-caption</li>
                <li><span className="bg-emergency" style={bgColorStyle}></span>.bg-emergency</li>
                <li><span className="bg-un-blue" style={bgColorStyle}></span>.bg-un-blue</li>
            </ul>

            <Heading size="h3">Text color CSS classes</Heading>
            <p>It is possible to use the following classes to add text color to elements</p>
            <ul className="list-unstyled">
                <li className="text-color-default mb-2 p-2 border">.text-color-default</li>
                <li className="text-color-primary mb-2 p-2 border">.text-color-primary</li>
                <li className="text-color-primary-light mb-2 p-2 border bg-gray-dark">.text-color-primary-light</li>
                <li className="text-color-gray-dark mb-2 p-2 border">.text-color-gray-dark</li>
                <li className="text-color-gray-medium mb-2 p-2 border">.text-color-gray-medium</li>
                <li className="text-color-gray-light mb-2 p-2 border bg-gray-dark">.text-color-gray-light</li>
                <li className="text-color-white-fao mb-2 p-2 border bg-gray-dark">.text-color-white-fao</li>
                <li className="text-color-orange mb-2 p-2 border">.text-color-orange</li>
                <li className="text-color-caption mb-2 p-2 border">.text-color-caption</li>
                <li className="text-color-emergency mb-2 p-2 border">.text-color-emergency</li>
                <li className="text-color-un-blue mb-2 p-2 border">.text-color-un-blue</li>
            </ul>
        </DsLayout>
    );   
}
  
export default ColorsView;