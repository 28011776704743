

const ColorCard = props => {
    let classes = `--color-card --color-card__${props.color}`; 

    if (props.whiteText) classes += ' text-white';

    return (
        <div className={classes}>
            <p className="--color-card__name">{props.title}</p>
            <p className="--color-card__hex">#{props.hex}</p>
            <p className="--color-card__rgb">rgb({props.rgb})</p>
        </div>
    );
}
  
export default ColorCard;