// import { Footer } from '../components/design-system/DsComponents'
// import "../scss/design-system/design-system.scss";
// import "../scss/fao/fao-theme.scss";

const DsLayoutFullWidth = (props) => {
  return (
    <div
      className={`--doc-content --doc-content-fullwidth ${
        props.story ? "detail-story" : ""
      }`}
    >
      {props.children}
    </div>
  );
};

export default DsLayoutFullWidth;
