import DsLayoutFullWidth from '../../layouts/DsLayoutFullWidth'
import {Heading, RenderCode} from '../../components/design-system/DsComponents'
import bannerImage from '../../assets/test-images/speeches-bg-1110x300.jpg'


const FaoSpeechesDetailView = () => {
    return (
        <DsLayoutFullWidth>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Heading size="h1">Speeches detail</Heading>
                    </div>
                </div>
            </div>

            <RenderCode fullWidth={true}>
                <div className="container detail-speeches">
                    <div className="row">
                        <div className="col-md-12">
                            <div
                                className="position-relative d-flex justify-content-center align-items-center background-cover overflow-hidden mb-4"
                                style={{backgroundImage: `url(${bannerImage})`}}>
                                <div className="text-white text-center px-4 py-5">
                                    <h6 className="title-category">STATEMENT</h6>
                                    <h1 className="detail-title">Lorem ipsum dolor sit amet consectetur.</h1>
                                    <h4>By Dr QU Dongyu, FAO Director-General </h4>
                                    <p className="date">26/11/2020, Rome (Italy)</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-sm-10 col-lg-9 mx-auto">
                            <div className="detail__body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel venenatis ipsum.
                                    Proin cursus vulputate ligula, in aliquet urna malesuada eu. Maecenas sapien quam,
                                    viverra quis urna eu, sodales mattis magna. Mauris ornare quam eu arcu ultricies, ac
                                    luctus neque pharetra. Integer a dui mattis, pretium lorem eu, aliquam nisi. Aliquam
                                    fermentum fringilla quam ut malesuada. Aenean ornare vitae dui eget auctor. Proin
                                    elit nibh, maximus at scelerisque vel, convallis et magna. Aliquam non purus vitae
                                    augue ullamcorper vestibulum eu vitae libero. In eget enim sit amet mi vulputate
                                    rutrum. Aliquam erat volutpat.</p>

                                <p>Nullam ut nisi sodales, consequat sapien at, auctor mauris. Aenean id sollicitudin
                                    libero, at aliquam dui. Pellentesque habitant morbi tristique senectus et netus et
                                    malesuada fames ac turpis egestas. Curabitur posuere laoreet risus sit amet sodales.
                                    Aliquam efficitur, velit id bibendum lobortis, massa lorem euismod tellus, vitae
                                    viverra elit lacus eget arcu. Integer nec diam posuere, tempus nulla sit amet,
                                    tristique neque. Aenean et fermentum tortor.</p>

                                <p>Quisque at turpis aliquet, semper orci lobortis, accumsan ligula. Aenean eu ante
                                    faucibus, vestibulum elit et, vulputate turpis. Etiam euismod ultrices fermentum.
                                    Curabitur tincidunt neque at nunc mattis, a convallis nulla porttitor. Pellentesque
                                    sollicitudin quis enim a ornare. Vestibulum ante ipsum primis in faucibus orci
                                    luctus et ultrices posuere cubilia curae; Sed magna felis, hendrerit ac libero et,
                                    bibendum feugiat nisi. Nunc pretium libero vitae felis vestibulum volutpat.</p>

                                <p>In pulvinar lectus in augue scelerisque posuere. Duis vitae arcu nisl. Nam convallis
                                    lectus ac arcu interdum tempor. Sed luctus ligula vitae justo elementum, sit amet
                                    cursus dui pellentesque. Morbi aliquet, ligula quis varius gravida, velit mauris
                                    congue magna, et suscipit orci urna eu quam. Maecenas fringilla sollicitudin orci,
                                    non sollicitudin nunc volutpat ut. Nulla blandit, lectus vel viverra volutpat, ex
                                    nisl sagittis metus, eu ullamcorper tellus risus vel quam. Aenean dapibus aliquet
                                    erat, non efficitur orci laoreet id.</p>

                                <p>Donec magna lacus, viverra at ultrices non, tempor auctor augue. Duis at quam sit
                                    amet orci hendrerit sollicitudin. Vestibulum tincidunt euismod lorem, at tristique
                                    enim porta at. Vivamus iaculis condimentum sem, fringilla pulvinar justo cursus
                                    eget. Suspendisse aliquet ultricies risus, at aliquet mi gravida vitae. Phasellus
                                    tempor suscipit libero at fringilla. Etiam pulvinar hendrerit efficitur. Etiam
                                    luctus venenatis odio. Nunc blandit, odio eget semper venenatis, enim quam maximus
                                    sem, vitae aliquam lectus sapien vitae sapien. Duis ac ante vel ligula malesuada
                                    fermentum. Aenean et nunc maximus, ullamcorper purus quis, commodo nibh.</p>

                            </div>
                        </div>
                    </div>

                </div>
            </RenderCode>
        </DsLayoutFullWidth>
    );
}

export default FaoSpeechesDetailView;
