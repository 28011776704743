import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { SideNavDs, TopNavDoc } from "./components/design-system/DsComponents";
import { SideNav } from "./components/fao/FaoComponents";
import {
  routes,
  route_styles,
  route_components,
  route_content,
  route_accessibility,
  route_examples,
} from "./routes";
import Route404 from "./views/Route404";
import { UserProvider } from "./contexts/UserContext";

const supportsContainerQueries = "container" in document.documentElement.style;
if (!supportsContainerQueries) {
  console.log("no queries");
  import("container-query-polyfill");
}

const App = () => {
  return (
    <UserProvider>
      <TopNavDoc
        search
        title="DESIGN SYSTEM"
        style={{ zIndex: "1040" }}
        allRoutes={{
          styles: route_styles,
          components: route_components,
          content: route_content.flatMap((el) => [...el.children]),
          accessibility: route_accessibility,
          examples: route_examples,
        }}
      />

      <div id="appLayout" className="pe-0 app-layout">
        <SideNav id="sidebarDs" title="Browse docs" target="#sidebarDs" toggle>
          {SideNavigation()}
        </SideNav>

        <main className="app-main">
          <Switch>
            {routes.map((route, idx) => {
              return <Route key={idx} {...route} exact />;
            })}
            <Route path="*">
              <Route404 />
            </Route>
          </Switch>
        </main>
      </div>
    </UserProvider>
  );
};

function SideNavigation() {
  const location = useLocation();

  if (location.pathname.includes("/styles")) {
    document.body.className = "";
    document.body.classList.add("p-styles");
    return <SideNavDs routes={sortArr(route_styles)} />;
  } else if (location.pathname.includes("/components")) {
    document.body.className = "";
    document.body.classList.add("p-components");
    return <SideNavDs routes={sortArr(route_components)} />;
  } else if (location.pathname.includes("/sitefinity")) {
    document.body.className = "";
    document.body.classList.add("p-sitefinity");
    route_content.forEach((route) => sortArr(route.children));
    return <SideNavDs routes={sortArr(route_content)} />;
  } else if (location.pathname.includes("/accessibility")) {
    document.body.className = "";
    document.body.classList.add("p-accessibility");
    return <SideNavDs routes={sortArr(route_accessibility)} />;
  } else if (location.pathname.includes("/example")) {
    document.body.className = "";
    document.body.classList.add("p-example");
    return <SideNavDs routes={sortArr(route_examples)} />;
  } else if (location.pathname.includes("/changelog")) {
    document.body.className = "";
    document.body.classList.add("p-changelog");
    document.body.classList.add("app-no-sidebar");
  } else if (location.pathname.includes("/international-year-v2")) {
    document.body.className = "";
    document.body.classList.add("iy-v2");
    document.body.classList.add("app-no-sidebar");
  } else {
    document.body.className = "";
    document.body.classList.add("app-no-sidebar");
    return;
  }
}

export default App;

function sortArr(arr) {
  return arr.sort((a, b) => a.name.localeCompare(b.name));
}
