import { Toc, Footer } from "../components/design-system/DsComponents";

// import "../scss/design-system/design-system.scss";
// import "../scss/fao/fao-theme.scss";

//"container-fluid --doc-content"
const DsLayout = (props) => {
  return (
    <div
      className={`--doc-content ${
        !props.container ? "container-fluid" : "container"
      }`}
    >
      <div className="row">
        <div className="col-12 col-lg-9">{props.children}</div>

        <Toc />

        <div className="col-12 col-lg-9">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default DsLayout;
