import DsLayout from '../layouts/DsLayout'


const ComponentsPage = () => {
    return (
        <DsLayout>
            <h1 className="ds-title-fw mt-5 mb-4">Components</h1>
            <p>Components are reusable parts of the user interface that have been made to support a variety of applications.</p>
            <p>You can use individual components in many different patterns and contexts. For example, you can use the text input component to ask for an email address, a project number or someone’s name.</p>
            <p>Each component in the FAO.org Design System has coded examples. If you’re using FAO Sitefinity, coded examples will render the same as in the Design System.</p>
            
            
        </DsLayout>
    );
}
  
export default ComponentsPage;
