import DsLayout from '../../layouts/DsLayout'
import { Heading } from '../../components/design-system/DsComponents'


const FormsView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Forms</Heading>
            <p>Please use the offical Bootstrap 5 documentation for examples and usage guidelines for form control styles, layout options, and custom components for creating a wide variety of forms: <a href="https://getbootstrap.com/docs/5.2/forms/overview/">https://getbootstrap.com/docs/5.2/forms/overview/</a></p>
        </DsLayout>
    );
}

export default FormsView;