import faoLogo from '../../assets/fao-logo-blue.svg'
import cardTest from '../../assets/test-images/card-test.jpg'


const Header = props => {
    return (
        <header className={`fao-header ${props.subsite ? 'subsite-header' : 'corporate-header' }`}>
            <div className="container">
                <div className="row">
                    <div className="header-wrapper col-12">
                        <div className="fao-header-left">
                            <a href="/home/en/">
                                <img src={faoLogo} alt="Food and Agriculture Organization of the United Nations"
                                    title="Food and Agriculture Organization of the United Nations"
                                    className="header-fao-logo" />
                            </a>
                        </div>
                        <div className="fao-header-right">
                            <div className="dropdown discover-dropdown">
                                <button className="btn btn-secondary dropdown-toggle" type="button"
                                    id="dropdownDiscoverMenu" data-bs-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    Discover
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownDiscoverMenu">
                                    <a className="dropdown-item" href="#!">About FAO</a>
                                    <a className="dropdown-item" href="#!">In action</a>
                                    <a className="dropdown-item" href="#!">Media</a>
                                    <a className="dropdown-item" href="#!">Main topics</a>
                                    <a className="dropdown-item" href="#!">Resources</a>
                                    <a className="dropdown-item" href="#!">Member countries</a>
                                    <a className="dropdown-item" href="#!">Get involved</a>
                                </div>
                            </div>

                            <div className="dropdown language-switch" aria-label="Change Language" role="menu">
                                <button className="btn btn-secondary dropdown-toggle" type="button"
                                    id="dropdownLanguageMenu" data-bs-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    English
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownLanguageMenu">
                                    <a className="dropdown-item" href="#!" role="menuitem" aria-label="Arabic">العربية</a>
                                    <a className="dropdown-item" href="#!" role="menuitem" aria-label="Chinese">中文</a>
                                    <a className="dropdown-item" href="#!" role="menuitem" aria-label="French">Français</a>
                                    <a className="dropdown-item" href="#!" role="menuitem" aria-label="Russian">Русский</a>
                                    <a className="dropdown-item" href="#!" role="menuitem" aria-label="Spanish">Español</a>
                                </div>
                            </div>
                            <div className="header-icons">
                                <a href="#!" type="button" data-bs-toggle="modal" data-bs-target="#shareModal" aria-label="Share">
                                    <i className="bi bi-share-fill"></i>
                                </a>
                                <a data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" aria-label="Search"> 
                                    <i className="bi bi-search"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="collapse" id="collapseExample">
                            <div className="input-group my-3">
                                <input type="text" className="form-control" placeholder="Search" aria-label="Search" />
                                <div className="input-group-append">
                                    <button type="button" className="btn btn-primary btn-icon">
                                        <i className="bi bi-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {!props.subsite ? renderNav() : null}
        </header>
    );
}


const renderNav = () => {
    return(
        <nav className="navbar navbar-expand-lg navbar-light bg-light navbar-corporate">
            <div className="container">
                <button className="navbar-toggler my-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav justify-content-between">
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#!" id="navbarDropdownAbout" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                About FAO
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownAbout">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <ul className="simple-list list-unstyled navbar-list">
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        About us
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        Director-General
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        Leadership
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        Organizational chart
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        Worldwide offices
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        Governing Bodies
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        Oversight Advisory Committee
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        FAO and the UN
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        Meetings
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        Visit us
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-9 d-none d-lg-block">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="card card-overlay mb-4">
                                                        <div className="card-image">
                                                            <img src={cardTest} className="img-fluid" alt="..." />
                                                        </div>
                                                        <div
                                                            className="card-img-overlay d-flex flex-column justify-content-center align-items-center">
                                                            <h5 className="card-title mb-0">
                                                                <a href="#!" title="Placeholder link title"
                                                                    className="stretched-link">
                                                                    Leadership
                                                                </a>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="card card-overlay mb-4">
                                                        <div className="card-image">
                                                            <img src={cardTest} className="img-fluid" alt="..." />
                                                        </div>
                                                        <div className="card-img-overlay d-flex flex-column justify-content-center">
                                                            <h5 className="card-title mb-3">
                                                                <a href="#!" title="Placeholder link title"
                                                                    className="stretched-link">
                                                                    Director-General
                                                                </a>
                                                            </h5>
                                                            <p className="mb-0">2020 in review: A year of global
                                                                engagement to help build a better
                                                                world</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="card card-overlay">
                                                        <div className="card-image">
                                                            <img src={cardTest} className="img-fluid" alt="..." />
                                                        </div>
                                                        <div
                                                            className="card-img-overlay d-flex flex-column justify-content-center align-items-center">
                                                            <h5 className="card-title mb-0">
                                                                <a href="#!" title="Placeholder link title"
                                                                    className="stretched-link">
                                                                    Worldwide offices
                                                                </a>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="card card-bg-light h-100">
                                                        <div
                                                            className="card-body d-flex flex-column justify-content-center">
                                                            <h5 className="card-title">Programme Committee</h5>
                                                            <ul>
                                                                <li>
                                                                    <p className="card-text mb-1">130th Session</p>
                                                                    <h6 className="date mb-0">22-26 March 2021</h6>
                                                                </li>
                                                            </ul>
                                                            <h5 className="card-title mt-4">Finance Committee</h5>
                                                            <ul>
                                                                <li>
                                                                    <p className="card-text mb-1">185th Session</p>
                                                                    <h6 className="date mb-0">22-26-March 2021</h6>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#!" id="navbarDropdownInAction" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                In action
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownInAction">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <ul className="simple-list list-unstyled navbar-list">
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        FAO at work
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        Projects
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        Standard and Policies
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        Sustainable Development Goals
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-9 d-none d-lg-block">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="card card-bg-gray h-100">
                                                        <div className="card-image">
                                                            <img src={cardTest} className="img-fluid" alt="card alt" />
                                                        </div>
                                                        <div className="card-body">
                                                            <h5 className="card-title">
                                                                <a href="#!"
                                                                    className="title-link stretched-link">Boosting
                                                                    transparency of forest data</a>
                                                            </h5>
                                                            <h6 className="date">20/11/2019</h6>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-md-4">
                                                    <div className="card card-bg-gray h-100">
                                                        <div className="card-image">
                                                            <img src={cardTest} className="img-fluid" alt="card alt" />
                                                        </div>
                                                        <div className="card-body">
                                                            <h5 className="card-title">
                                                                <a href="#!"
                                                                    className="title-link stretched-link">Greater
                                                                    investments in the agricultural
                                                                    sector...</a>
                                                            </h5>
                                                            <p className="card-text">FAO has launched its second
                                                                comprehensive assessment of 22
                                                                indicators...</p>
                                                            <h6 className="date">15/09/2020</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="card card-overlay mb-4">
                                                        <div className="card-image">
                                                            <img src={cardTest} className="img-fluid" alt="..." />
                                                        </div>
                                                        <div
                                                            className="card-img-overlay d-flex flex-column justify-content-center align-items-center">
                                                            <h5 className="card-title mb-0">
                                                                <a href="#!" title="Placeholder link title"
                                                                    className="stretched-link">
                                                                    Public-private partnerships boost capacity and
                                                                    know-how in agriculture
                                                                </a>
                                                            </h5>
                                                        </div>
                                                    </div>

                                                    <div className="card card-bg-gray mb-4">
                                                        <div className="card-body d-flex align-items-center">
                                                            <button type="button"
                                                                className="btn btn-primary btn-round-big btn-icon me-3">
                                                                <i className="bi bi-book"></i>
                                                            </button>

                                                            <h6 className="card-title mb-0">Policy support and
                                                                governance</h6>

                                                        </div>
                                                    </div>

                                                    <div className="card card-bg-gray mb-4">
                                                        <div className="card-body d-flex align-items-center">
                                                            <button type="button"
                                                                className="btn btn-primary btn-round-big btn-icon me-3">
                                                                <i className="bi bi-collection"></i>
                                                            </button>

                                                            <h6 className="card-title mb-0">Codex Alimentarius</h6>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#!" id="navbarDropdownMedia" role="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Media
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownMedia">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <ul className="simple-list list-unstyled navbar-list">
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        News
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        Stories
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        Video
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        Photo
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        Audio
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        Webcast
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        Media contacts
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-9 d-none d-lg-block">
                                            <div className="row">
                                                <div className="col-md-6">

                                                    <div className="row h-100">
                                                        <div className="col-6 mb-4">
                                                            <div className="card card-overlay h-100">
                                                                <div className="card-image">
                                                                    <img src={cardTest} className="img-fluid"
                                                                        alt="..." />
                                                                </div>
                                                                <div
                                                                    className="card-img-overlay d-flex flex-column justify-content-center align-items-center">
                                                                    <i
                                                                        className="bi bi-camera-video icon-corporate-header-media"></i>
                                                                    <h5 className="card-title mb-0">
                                                                        <a href="#!" title="Placeholder link title"
                                                                            className="stretched-link">
                                                                            Video
                                                                        </a>
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 mb-4">
                                                            <div className="card card-overlay h-100">
                                                                <div className="card-image">
                                                                    <img src={cardTest} className="img-fluid"
                                                                        alt="..." />
                                                                </div>
                                                                <div
                                                                    className="card-img-overlay d-flex flex-column justify-content-center align-items-center">
                                                                    <i
                                                                        className="bi bi-camera icon-corporate-header-media"></i>
                                                                    <h5 className="card-title mb-0">
                                                                        <a href="#!" title="Placeholder link title"
                                                                            className="stretched-link">
                                                                            Photo
                                                                        </a>
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="card card-overlay h-100">
                                                                <div className="card-image">
                                                                    <img src={cardTest} className="img-fluid"
                                                                        alt="..." />
                                                                </div>
                                                                <div
                                                                    className="card-img-overlay d-flex flex-column justify-content-center align-items-center">
                                                                    <i
                                                                        className="bi bi-volume-up icon-corporate-header-media"></i>
                                                                    <h5 className="card-title mb-0">
                                                                        <a href="#!" title="Placeholder link title"
                                                                            className="stretched-link">
                                                                            Audio
                                                                        </a>
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="card card-overlay h-100">
                                                                <div className="card-image">
                                                                    <img src={cardTest} className="img-fluid"
                                                                        alt="..." />
                                                                </div>
                                                                <div
                                                                    className="card-img-overlay d-flex flex-column justify-content-center align-items-center">
                                                                    <i
                                                                        className="bi bi-broadcast icon-corporate-header-media"></i>
                                                                    <h5 className="card-title mb-0">
                                                                        <a href="#!" title="Placeholder link title"
                                                                            className="stretched-link">
                                                                            Webcast
                                                                        </a>
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>


                                                </div>
                                                <div className="col-md-6">

                                                    <div className="card card-overlay mb-4">
                                                        <div className="card-image">
                                                            <img src={cardTest} className="img-fluid" alt="..." />
                                                        </div>
                                                        <div
                                                            className="card-img-overlay d-flex flex-column justify-content-center ">
                                                            <h5 className="card-title mb-0">
                                                                <a href="#!" title="Placeholder link title"
                                                                    className="stretched-link">
                                                                    News
                                                                </a>
                                                            </h5>
                                                            <p className="card-text">Acute malnutrition threatens half
                                                                of children under five in Yemen
                                                                in 2021: UN</p>
                                                        </div>
                                                    </div>

                                                    <div className="card card-bg-light">
                                                        <div className="card-body">
                                                            <h5 className="card-title">Media contacts</h5>
                                                            <div>
                                                                <a href="#!" title="Placeholder link title"
                                                                    className="link-icon">
                                                                    Multimedia contacts
                                                                    <i className="bi bi-chevron-right"></i>
                                                                </a>
                                                                <a href="#!" title="Placeholder link title"
                                                                    className="link-icon">
                                                                    Contacts in Europe
                                                                    <i className="bi bi-chevron-right"></i>
                                                                </a>
                                                                <a href="#!" title="Placeholder link title"
                                                                    className="link-icon">
                                                                    Tools for journalists
                                                                    <i className="bi bi-chevron-right"></i>
                                                                </a>

                                                            </div>


                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#!" id="navbarDropdownMainTopics"
                                role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Main topics
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownMainTopics">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="card card-overlay mb-4">
                                                        <div className="card-image">
                                                            <img src={cardTest} className="img-fluid" alt="..." />
                                                        </div>
                                                        <div
                                                            className="card-img-overlay d-flex flex-column justify-content-center align-items-center">
                                                            <h5 className="card-title mb-0">
                                                                <a href="#!" title="Placeholder link title"
                                                                    className="stretched-link">
                                                                    Hand in Hand
                                                                </a>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="card card-overlay mb-4">
                                                        <div className="card-image">
                                                            <img src={cardTest} className="img-fluid" alt="..." />
                                                        </div>
                                                        <div
                                                            className="card-img-overlay d-flex flex-column justify-content-center align-items-center">
                                                            <h5 className="card-title mb-0">
                                                                <a href="#!" title="Placeholder link title"
                                                                    className="stretched-link">
                                                                    Food System
                                                                </a>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="card card-overlay ">
                                                        <div className="card-image">
                                                            <img src={cardTest} className="img-fluid" alt="..." />
                                                        </div>
                                                        <div
                                                            className="card-img-overlay d-flex flex-column justify-content-center align-items-center">
                                                            <h5 className="card-title mb-0">
                                                                <a href="#!" title="Placeholder link title"
                                                                    className="stretched-link">
                                                                    Innovation at FAO
                                                                </a>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="card card-overlay">
                                                        <div className="card-image">
                                                            <img src={cardTest} className="img-fluid" alt="..." />
                                                        </div>
                                                        <div
                                                            className="card-img-overlay d-flex flex-column justify-content-center align-items-center">
                                                            <h5 className="card-title mb-0">
                                                                <a href="#!" title="Placeholder link title"
                                                                    className="stretched-link">
                                                                    Novel Coronavirus (COVID-19)
                                                                </a>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        <div className="col-lg-4  d-none d-lg-block">
                                            <div className="card card-overlay h-100">
                                                <div className="card-image">
                                                    <img src={cardTest} className="img-fluid" alt="..." />
                                                </div>
                                                <div
                                                    className="card-img-overlay d-flex flex-column justify-content-center align-items-center">
                                                    <h5 className="card-title mb-0">
                                                        <a href="#!" title="Placeholder link title"
                                                            className="stretched-link">
                                                            All
                                                        </a>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#!" id="navbarDropdownResources" role="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Resources
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownResources">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <ul className="simple-list list-unstyled navbar-list">
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        Data
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        Interactive Stories
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        Infographics
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        Publications
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-9 d-none d-lg-block">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="card card-overlay h-100">
                                                        <div className="card-image">
                                                            <img src={cardTest} className="img-fluid" alt="..." />
                                                        </div>
                                                        <div
                                                            className="card-img-overlay d-flex flex-column justify-content-center align-items-center">
                                                            <h5 className="card-title">
                                                                <a href="#!" title="Placeholder link title"
                                                                    className="stretched-link">
                                                                    The State of Food Security and Nutrition in the
                                                                    World
                                                                </a>
                                                            </h5>
                                                            <p className="card-text">
                                                                Transforming Food Systems for Affordable Healthy Diets
                                                            </p>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-md-4">
                                                    <div className="card card-bg-gray h-100">
                                                        <div className="card-image">
                                                            <img src={cardTest} className="img-fluid" alt="card alt" />
                                                        </div>
                                                        <div className="card-body">
                                                            <h5 className="card-title">
                                                                <a href="#!" className="title-link stretched-link">FAO
                                                                    launches its 2020 report “Tracking
                                                                    progress on food and agriculture-related SDG
                                                                    indicators”</a>
                                                            </h5>
                                                            <p className="card-text">This second edition offers detailed
                                                                analysis and trends on 22
                                                                indicators under 6 SDGs (2, 5, 6, 12, 14 and 15) under
                                                                its custodianship.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">

                                                    <div className="card card-overlay h-100">
                                                        <div className="card-image">
                                                            <img src={cardTest} className="img-fluid" alt="..." />
                                                        </div>
                                                        <div
                                                            className="card-img-overlay d-flex flex-column justify-content-center align-items-center">
                                                            <h5 className="card-title mb-0">
                                                                <a href="#!" title="Placeholder link title"
                                                                    className="stretched-link">
                                                                    Food Price Index
                                                                </a>
                                                            </h5>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#!" id="navbarDropdownMemberCountries"
                                role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Member Countries
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownMemberCountries">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <ul className="simple-list list-unstyled navbar-list">
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        Country Profiles
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        FAO Member Gateway
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        Low-Income Food-Deficit countries
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-9 d-none d-lg-block">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <div className="card card-horizontal card-bg-light mb-4">
                                                        <div className="row g-0">
                                                            <div className="col-md-3">
                                                                <div className="card-image">
                                                                    <img src={cardTest} className="img-fluid"
                                                                        alt="card alt" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="card-body">
                                                                    <h5 className="card-title">
                                                                        <a href="#!"
                                                                            className="title-link stretched-link">Water
                                                                            and agriculture country
                                                                            profile</a>
                                                                    </h5>
                                                                    <p className="card-text">FAO's Global Information
                                                                        System on Water and Agriculture</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="card card-horizontal card-bg-light">
                                                        <div className="row g-0">
                                                            <div className="col-md-3">
                                                                <div className="card-image">
                                                                    <img src={cardTest} className="img-fluid"
                                                                        alt="card alt" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="card-body">
                                                                    <h5 className="card-title">
                                                                        <a href="#!"
                                                                            className="title-link stretched-link">Preserving
                                                                            agricultural heritage
                                                                            around the world</a>
                                                                    </h5>
                                                                    <p className="card-text">Globally Important
                                                                        Agricultural Heritage Systems (GIAHS) are
                                                                        sites that have survived ...</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="card card-overlay h-100">
                                                        <div className="card-image">
                                                            <img src={cardTest} className="img-fluid" alt="..." />
                                                        </div>
                                                        <div
                                                            className="card-img-overlay d-flex flex-column justify-content-center align-items-center">
                                                            <h5 className="card-title mb-0">
                                                                <a href="#!" title="Placeholder link title"
                                                                    className="stretched-link">
                                                                    FAO Members Gateway
                                                                </a>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#!" id="navbarDropdownGetInvolved"
                                role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Get involved
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownGetInvolved">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <ul className="simple-list list-unstyled navbar-list">
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        Partnership
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        Work with us
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        Ask FAO
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        FAO on social media
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        Invest
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-9 d-none d-lg-block">
                                            <div className="row">
                                                <div className="col-md-8">

                                                    <h5 className="title-link"><a href="#!">Employment at FAO</a></h5>

                                                    <table className="table table-striped">
                                                        <tbody>
                                                            <tr>
                                                                <td>National Project Coordinator</td>
                                                                <td>Deadline
                                                                    <h6 className="date">26-02-2021</h6>
                                                                </td>
                                                                <td>
                                                                    <button type="button"
                                                                        className="btn btn-primary">Apply</button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Agro-ecological zoning consultant</td>
                                                                <td>Deadline
                                                                    <h6 className="date">08-03-2021</h6>
                                                                </td>
                                                                <td>
                                                                    <button type="button"
                                                                        className="btn btn-primary">Apply</button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>National Operations Specialist</td>
                                                                <td>Deadline
                                                                    <h6 className="date">05-03-2021</h6>
                                                                </td>
                                                                <td>
                                                                    <button type="button"
                                                                        className="btn btn-primary">Apply</button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <button type="button" className="btn btn-primary">All</button>

                                                </div>
                                                <div className="col-md-4">
                                                    <div className="card card-overlay h-100">
                                                        <div className="card-image">
                                                            <img src={cardTest} className="img-fluid" alt="..." />
                                                        </div>
                                                        <div
                                                            className="card-img-overlay d-flex flex-column justify-content-center align-items-center">
                                                            <h5 className="card-title mb-0">
                                                                <a href="#!" title="Placeholder link title"
                                                                    className="stretched-link">
                                                                    PSR Report
                                                                </a>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

  
export default Header;