import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
// import cardImage from '../../assets/test-images/card-test.jpg'
import identityTest from '../../assets/test-images/identity-test.svg'
// import identityTestSolo from '../../assets/test-images/identity-test-solo.svg'

const FaoStoryListView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Partners card</Heading>

            

            <RenderCode>
                <div className="card-partners ratio ratio-3x2">
                    <img src={identityTest} alt="card alt" />
                </div>
            </RenderCode>
            
        </DsLayout>
    );
}

export default FaoStoryListView;
