// import React from 'react'
// import { Header, SubSiteNavbar, Card, CardOverlay, CardTwitter, Link, ContentBackground, Footer, ShareModal, EmailModal } from '../../components/fao/FaoComponents'
import {

    Footer,
    Breadcrumb,
     ListAudio
} from '../../components/fao/FaoComponents'

import DsLayoutFullWidth from '../../layouts/DsLayoutFullWidth'


const AudioVisualView = () => {
    return (
        <DsLayoutFullWidth>

            <div className="theme-dark position-relative">
                <div className="audio-visual-cover">
                    <div className="audio-visual-image" style={{backgroundImage: 'url(https://www.fao.org/images/corporatelibraries/corporate-pages-images/02Brief.png)'}}/>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="content-icons">

                                <Breadcrumb/>




                            </div>
                        </div>

                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-xl-7 position-relative">

                            <h2 className="page-title border-0">FAO Brief</h2>
                            <h6 className="title-category">PODCAST SERIES 2</h6>
                            <p>Find out the latest news and happenings at FAO. A regular round-up of recent headlines,
                                new initiatives and upcoming events</p>
                            <div className="tags-list title-category">
                                <span className="class-list__title">TAGS:</span>
                                <span className="badge text-bg-secondary">Lorem ipsum</span>
                                <span className="badge text-bg-secondary">Lorem</span>
                                <span className="badge text-bg-secondary">Lorem ipsum</span>
                                <a className="badge text-bg-secondary" href="!#">Lorem</a>
                            </div>
                            <br/>
                            <p>If you are experiencing issues listening to the podcast on your PC please refer to this
                                FAQ at Soundcloud.com for support.</p>
                            <br/>
                            <div className="media-provider-list">
                                <span className="media-provider-title title-category">AVAILABLE ON:</span>
                                <a href="!#" className="media-provider-ico">
                                    <img src="https://www.fao.org/images/corporatelibraries/social-icons/soundcloud.svg" alt="Sound cloud"/>
                                </a>
                                <a href="!#" className="media-provider-ico">
                                    <img src="https://www.fao.org/images/corporatelibraries/social-icons/applebutton.svg" alt="Sound cloud"/>
                                </a>
                                <a href="!#" className="media-provider-ico">
                                    <img src="https://www.fao.org/images/corporatelibraries/social-icons/stitcherbutton.svg" alt="Sound cloud"/>
                                </a>
                                <a href="!#" className="media-provider-ico">
                                    <img src="https://www.fao.org/images/corporatelibraries/social-icons/spotifybutton.svg" alt="Sound cloud"/>
                                </a>

                            </div>
                            <br/>
                            <h6 className="title-category">(AVERAGE LISTENING TIME 3 MINS)</h6>
                            <hr/>
                            <ListAudio/>
                            <ListAudio/>
                            <ListAudio/>
                            <ListAudio/>
                            <nav aria-label="Page navigation example">
                                <ul className="pagination justify-content-center">
                                    <li className="page-item">
                                        <a className="page-link" href="#!" aria-label="Previous">
                                            <span aria-hidden="true">&laquo;</span>
                                        </a>
                                    </li>
                                    <li className="page-item"><a className="page-link" href="#!">1</a></li>
                                    <li className="page-item active"><a className="page-link" href="#!">2</a></li>
                                    <li className="page-item"><a className="page-link" href="#!">3</a></li>
                                    <li className="page-item">
                                        <a className="page-link" href="#!" aria-label="Next">
                                            <span aria-hidden="true">&raquo;</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                            <br/><br/><br/>
                        </div>
                    </div>

                </div>

            </div>
            <Footer/>
        </DsLayoutFullWidth>
    );
}

export default AudioVisualView;