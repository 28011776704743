import DsLayoutFullWidth from '../../layouts/DsLayoutFullWidth'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
import { Link, TagsList } from '../../components/fao/FaoComponents'
import bannerImage from '../../assets/test-images/card-test.jpg'

const FaoBlogDetailView = () => {
    return (
        <DsLayoutFullWidth>
            
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Heading size="h1">Blog detail</Heading>
                    </div>
                </div>
            </div>

            <RenderCode fullWidth={true}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h6 className="title-category">Blog Title</h6>
                            <h1 className="detail-title mt-2 mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1>
                            <img className="image-widget img-responsive img-hero mb-3" src={bannerImage} title="News Banner" alt="News Banner" />
                            <div className="row">
                                <div className="col-md-9">
                                    <p className="small text-color-gray-medium">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                                <div className="col-md-3 text-right">
                                    <p className="small text-color-gray-medium">©FAO/Lorem ipsum dolor</p>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <TagsList />
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="news-detail mb-3">
                                        <span className="news-detail__author"><a className="blog-author" href="#!">Lorem Ipsum</a> - </span>
                                        <span className="news-detail__date">23/03/2021</span>,
                                        <span className="news-detail__location">Rome</span>
                                    </div>
                                    <div className="news-detail__body">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                
                                    <h5 className="sub-title mt-0">Categories</h5>
                                    <Link internal numbers links={[
                                        'Lorem ipsum dolor sit amet', 
                                        'Lorem ipsum', 
                                        'Lorem ipsum dolor sit'
                                        ]} />
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </RenderCode>
            
        </DsLayoutFullWidth>
    );
}

export default FaoBlogDetailView;
