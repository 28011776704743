import DsLayout from '../../layouts/DsLayout'
import {Heading, RenderCode} from '../../components/design-system/DsComponents'
import {HeroBanner} from '../../components/fao/FaoComponents'
import heroVideoPoster from '../../assets/test-images/poster.jpg'
import heroVideo from '../../assets/videos/clip-1.mp4'


const HeroView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Hero</Heading>

            <Heading size="h3">Hero Banner Image</Heading>
            <RenderCode withBorders={true}>
                <HeroBanner/>
            </RenderCode>
            
            <Heading size="h3">Hero Banner Video</Heading>
            <RenderCode withBorders={true}>
                <div className="hero-banner">
                    <video className="hero-banner-video" loop autoPlay muted playsInline preload="auto"
                        poster={heroVideoPoster}>
                        <source src={heroVideo} type="video/mp4"/>
                    </video>
                    <div className="hero-caption">
                        <h6 className="title-category">News</h6>
                        <h5 className="title-link"><a href="#!">Lorem ipsum dolor sit amet, consectetur adipiscing elit</a></h5>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit repellat, nostrum quo.</p>
                    </div>
                </div>
            </RenderCode>
        </DsLayout>
    );
}

export default HeroView;