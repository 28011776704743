import { Link } from '../FaoComponents'
import { Category, Date } from '../typo/Typo'
import { Image } from '../media/Media'


const CardPhotoGallery = props => {
    return (
        <div className="card card-photo-gallery bg-gray-dark">
            <Image ratio="3x2" src={props.image} alt={props.title} className="card-image" />
            <div className="card-body">
                <Category>Photo Gallery</Category>
                <h5 className="card-title">
                    <a href="#!" className="title-link">{props.title}</a>
                </h5>
                <Date>{props.date}</Date>
                <p className="card-text">{props.body}</p>
                <Link links={['Internal Link Example']} internal iconRight />
            </div>
        </div>
    );
}
  
export default CardPhotoGallery;
