import React from 'react'
import {
    Header,
    CardTwitter,
    Link,
    ContentBackground,
    Footer,
    ShareModal,
    EmailModal,
    Breadcrumb
} from '../../components/fao/FaoComponents'
import bannerImage from '../../assets/test-images/one-health-banner.jpg'
// import cardImage1 from '../../assets/test-images/card-test-2.jpeg'
// import cardImage2 from '../../assets/test-images/card-test-3.jpeg'
// import cardImage3 from '../../assets/test-images/card-test-4.jpeg'
import DsLayoutFullWidth from '../../layouts/DsLayoutFullWidth'
// import heroVideoPoster from '../../assets/test-images/poster.jpg'
// import heroVideo from '../../assets/videos/emergencies-banner.mp4'
// import publImage from '../../assets/test-images/publication-1.jpg'

const NewsDetailExampleView = () => {
    return (
        <DsLayoutFullWidth>
            <Header subsite />

            <div className="subheader">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="content-icons">

                                <Breadcrumb/>

                                <div className="content-icons-right">

                                    <a href="#!" type="button" className="d-inline-flex" data-bs-toggle="modal" data-bs-target="#emailModal">
                                        <i className="bi bi-envelope"></i>
                                        <span>Email</span>
                                    </a>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <h2 className="page-title">Title of the Event site/page</h2>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vitae ipsam in harum. Odit ullam libero harum non ducimus nostrum! Recusandae odio similique, aut nam distinctio veniam vero aliquam nobis libero.</p>
                            <p className="event-detail__date">
                                <i className="bi bi-geo-alt-fill"></i>Virtual Event, 07/09/2021
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <main>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="hero-banner" style={{backgroundImage: `url('${bannerImage}')`}}>
                                {/* <div className="hero-caption">
                                    <h5 className="title-link">
                                        <a href="!#">Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus eum delectus ratione alias</a>
                                    </h5>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus eum delectus ratione alias, at qui autem deleniti aut odio dolorum corrupti maxime dolorem, iure porro possimus deserunt praesentium eaque velit.</p>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-9">
                            
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            aboris nisi ut aliquip ex ea commodo consequat.</p>

                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            aboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                            dolor in reprehenderit in voluptate velit esse cillum dolore eu
                            fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                            sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            aboris nisi ut aliquip ex ea commodo consequat.</p>
                            
                            
                            <h5 className="sub-title">Objectives of the launch event</h5>

                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            aboris nisi ut aliquip ex ea commodo consequat.</p>

                            <ul>
                                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                aboris nisi ut aliquip ex ea commodo consequat.</li>
                                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                aboris nisi ut aliquip ex ea commodo consequat.</li>
                            </ul>

                            <h5 className="sub-title">Related links</h5>

                            <Link links={['Link Example', 'Link Example', 'Link Example', 'Link Example']} internal />
                            
                        </div>
                        <div className="col-md-3">
                            <a href="https://fao.zoom.us/meeting/register/tJYpduyvqzssHdQU_i_49aKtVN6dj5SR8DIu" className="btn btn-primary btn-icon d-block mb-3">Register<i className="bi bi-chevron-right"></i></a>
                            <a href="http://www.fao.org/webcast/home/en/item/5621/icode/" className="btn btn-secondary btn-icon d-block">Webcast<i className="bi bi-chevron-right"></i></a>

                            <h4 className="sub-title">Documents</h4>
                                    
                            <Link links={['Link Example', 'Link Example', 'Link Example', 'Link Example']} publication />

                            <CardTwitter/>

                            <ContentBackground className="bg-primary-light">
                                <h5>Contact</h5><p><a href="mailto:contact@fao.org">contact@fao.org</a></p>
                            </ContentBackground>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
            <ShareModal />
            <EmailModal />
        </DsLayoutFullWidth>
    );
}
  
export default NewsDetailExampleView;