import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./scss/design-system/design-system.scss";
import "./scss/fao/fao-theme.scss";
// import './scss/international-year/intyear.scss';
// import './scss/wfd/wfd.scss';
import "./scss/international-year-v2/international-year-v2.scss";
import "./scss/fao-home/faohome.scss";

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  // <React.StrictMode>
  <Router>
    <App tab="home" />
  </Router>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
