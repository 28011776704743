
import { RenderCode } from '../../components/design-system/DsComponents'
import { TagsList, ListAudio } from '../../components/fao/FaoComponents'

const FaoAudioDetailView = () => {
    return (
        <RenderCode fullWidth={true}>
            <div className="bg-gray-dark text-white pt-5">
                <div className="container detail-audio">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="detail-title mb-4">Lorem ipsum dolor sit amet consectetur adipisicing elit</h1>

                            <div className="iframe-soundcloud mb-4">
                            <iframe title="Iframe title" width="100%" height="100%" scrolling="no" frameBorder="no" allow="autoplay"
                    src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1176941233&color=%23116aab&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
                                <div className="frameshoundcloud-anchor">
                                    <a href="https://soundcloud.com/unfao" title="UNFAO" target="_blank" rel="noreferrer" style={{color: '#cccccc', textDecoration: 'none'}}>UNFAO</a> · <a href="https://soundcloud.com/unfao/fao-podcast-kids-time-to-cook" title="Kids, it&#x27;s time to cook!" target="_blank" rel="noreferrer" style={{color: '#cccccc', textDecoration: 'none'}}>Kids, it&#x27;s time to cook!</a>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="detail__body mb-4">
                                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Exercitationem dolorem quae esse aspernatur obcaecati explicabo odit fuga! Ad optio distinctio ratione natus quis dignissimos fuga itaque vel. Adipisci, voluptates debitis.</p>    
                                        <p className="date text-color-gray-light">26/11/2020, Rome (Italy) - Host/Producer: Lorem ipsum</p>
                                        <TagsList title />
                                    </div>
                                    <h5 className="sub-title small text-white">Related Audios</h5>
                                    <div className="row detail-video__rvideos pb-5">
                                        <div className="col-lg-6 border-right border-white">
                                            <ListAudio />
                                        </div>
                                        <div className="col-lg-6">
                                            <ListAudio />
                                        </div>
                                        <div className="col-lg-6 border-right border-white">
                                            <ListAudio />
                                        </div>
                                        <div className="col-lg-6">
                                            <ListAudio />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <style>
        {
          `
.frameshoundcloud-anchor {
    background-color: red;
    font-size: 10px;
    color: #cccccc;
    line-break: anywhere;
    word-break: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
`
        }
        </style>
        </RenderCode>
    
    );
}

export default FaoAudioDetailView;
