import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
import { ListEvent } from '../../components/fao/FaoComponents'

const FaoEventsListView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Events list</Heading>
            <RenderCode>
                <ListEvent fromTo />
            </RenderCode>
            
            <Heading size="h3">Events only Date from</Heading>
            <RenderCode>
                <ListEvent />
            </RenderCode>
        </DsLayout>
    );
}

export default FaoEventsListView;
