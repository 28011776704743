import DsLayoutFullWidth from '../../layouts/DsLayoutFullWidth'
import {Heading} from '../../components/design-system/DsComponents'
import '../../scss/stories/stories.scss';
// import bannerImage from "../../assets/test-images/card-test.jpg";
// import publImage from "../../assets/test-images/publication-1.jpg";
// import cardImage from "../../assets/test-images/card-test.jpg";
import testImage from "../../assets/test-images/hero-1.jpg";


const FaoVideoDetailView = () => {
    return (
        <DsLayoutFullWidth>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Heading size="h1">Story detail</Heading>
                    </div>
                </div>
            </div>

            <div className="">

                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="story-cover">
                                <img src={testImage} alt="story cover"/>
                                <div className="story-cover-content">
                                    <div className="story-tag">
                                        <i className="bi bi-star"></i> Fao feature story
                                    </div>
                                    <h1 className="detail-title">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1>
                                    <hr/>
                                    <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                        tempor</h2>
                                    <div className="story-sdgs">
                                        <span className="sdg-small me-2 mb-2 sdg-11"
                                              title="SDG 11 - SUSTAINABLE AND COMMUNITIES"></span>
                                        <span className="sdg-small me-2 mb-2 sdg-13" title="SDG 13 - CLIMATE ACTION"></span>
                                        <span className="sdg-small me-2 mb-2 sdg-15" title="SDG 15 - LIFE ON LAND"></span>
                                    </div>
                                    <div className="story-share-cover">
                                        <a href="#!"><img
                                            src="https://www.fao.org/images/corporatelibraries/social-icons/facebook-ico-story.svg"
                                            alt="Share on Facebook"/></a>
                                        <a href="#!"><img
                                            src="https://www.fao.org/images/corporatelibraries/social-icons/x-ico-story.svg"
                                            alt="Share on X"/></a>
                                        <a href="#!"><img
                                            src="https://www.fao.org/images/corporatelibraries/social-icons/linkedin-ico-story.svg"
                                            alt="Share on Linkedin"/></a>
                                    </div>
                                </div>

                            </div>

                            <div className="story-column">
                                <p className="small text-color-gray-medium text-center my-4">Lorem ipsum dolor sit amet,
                                    consectetur adipiscing elit</p>


                                <p><h6 className="date">26/11/2020</h6></p>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                    irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                                    deserunt mollit anim id est laborum.</p>
                                <blockquote>
                                    “Cras sagittis orci consequat, lobortis felis id, vehicula justo. Nulla suscipit magna sed aliquet tincidunt.” states Cicero.
                                    <b>Cicero</b>
                                </blockquote>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                    irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                                    deserunt mollit anim id est laborum.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                    irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                                    deserunt mollit anim id est laborum.</p>

                                <h5 className="sub-title">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h5>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                    irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                                    deserunt mollit anim id est laborum.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                    irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                                    deserunt mollit anim id est laborum.</p>

                            </div>
                            <div className="story-column-2photos">
                                <div className="story-2photos-images">
                                    <div className="ratio ratio-3x2">
                                        <img src={testImage} alt="story cover"/>
                                    </div>
                                    <div className="ratio ratio-3x2">
                                        <img src={testImage} alt="story cover"/>
                                    </div>
                                </div>
                                <p className="small text-color-gray-medium text-center my-4">Lorem ipsum dolor sit amet,
                                    consectetur adipisicing
                                    elit. A aliquid aperiam, aspernatur autem
                                    consequatur.</p>
                            </div>
                            <div className="story-column">

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                    irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                                    deserunt mollit anim id est laborum.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                    irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                                    deserunt mollit anim id est laborum.</p>

                            </div>
                            <div className="story-column-1photo">
                                <div className="story-1photos-image">
                                    <div className="ratio ratio-3x2">
                                        <img src={testImage} alt="story cover"/>
                                    </div>
                                </div>
                                <p className="small text-color-gray-medium text-center my-4">Lorem ipsum dolor sit amet,
                                    consectetur adipisicing
                                    elit. A aliquid aperiam, aspernatur autem
                                    consequatur.</p>
                            </div>
                            <div className="story-column">

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                    irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                                    deserunt mollit anim id est laborum.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                    irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                                    deserunt mollit anim id est laborum.</p>

                                <h5 className="sub-title">Related links</h5>
                                <ul>
                                    <li>
                                        <a href="#!" title="Placeholder link title" className="link-icon">
                                            <i className="bi bi-globe"></i>
                                            <span>External Link Example </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#!" title="Placeholder link title" className="link-icon">
                                            <i className="bi bi-globe"></i>
                                            <span>External Link Example </span>
                                        </a>
                                    </li>
                                </ul>
                                <br/><br/>
                                <div className="story-share">
                                    <a href="#!"><img src="https://www.fao.org/images/corporatelibraries/social-icons/facebook-ico-story-text.svg" alt="Share on Facebook"/></a>
                                    <a href="#!"><img src="https://www.fao.org/images/corporatelibraries/social-icons/x-ico-story-text.svg" alt="Share on X"/></a>
                                    <a href="#!"><img src="https://www.fao.org/images/corporatelibraries/social-icons/linkedin-ico-story-text.svg" alt="Share on Linkedin"/></a>
                                </div>
                                <br/><br/><br/><br/>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            {/*<div className="story-corporate">*/}
            {/*    <div className="story-cover">*/}
            {/*        <img src={testImage} alt="story cover"/>*/}
            {/*        <div className="story-cover-content">*/}
            {/*            <h1>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1>*/}
            {/*            <hr/>*/}
            {/*            <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</h2>*/}
            {/*            <div className="story-sdgs">*/}
            {/*                <span className="sdg-small sdg-1 me-2 mb-2">1</span>*/}
            {/*                <span className="sdg-small sdg-2 me-2 mb-2">2</span>*/}
            {/*                <span className="sdg-small sdg-3 me-2 mb-2">3</span>*/}
            {/*            </div>*/}
            {/*        </div>*/}

            {/*    </div>*/}

            {/*    <div className="story-column">*/}
            {/*        <p className="small text-color-gray-medium text-center my-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>*/}


            {/*        <p><h6 className="date">26/11/2020</h6></p>*/}

            {/*        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>*/}
            {/*        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>*/}

            {/*        <h5 className="sub-title">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h5>*/}

            {/*        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>*/}
            {/*        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>*/}

            {/*    </div>*/}
            {/*    <div className="story-column-2photos">*/}
            {/*        <div className="story-2photos-images">*/}
            {/*            <div className="ratio ratio-3x2">*/}
            {/*                <img src={testImage} alt="story cover"/>*/}
            {/*            </div>*/}
            {/*            <div className="ratio ratio-3x2">*/}
            {/*                <img src={testImage} alt="story cover"/>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <p className="small text-color-gray-medium text-center my-4">Lorem ipsum dolor sit amet,*/}
            {/*            consectetur adipisicing*/}
            {/*            elit. A aliquid aperiam, aspernatur autem*/}
            {/*            consequatur.</p>*/}
            {/*    </div>*/}
            {/*    <div className="story-column">*/}

            {/*        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>*/}
            {/*        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>*/}

            {/*    </div>*/}
            {/*    <div className="story-column-1photo">*/}
            {/*        <div className="story-1photos-image">*/}
            {/*            <div className="ratio ratio-3x2">*/}
            {/*                <img src={testImage} alt="story cover"/>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <p className="small text-color-gray-medium text-center my-4">Lorem ipsum dolor sit amet,*/}
            {/*            consectetur adipisicing*/}
            {/*            elit. A aliquid aperiam, aspernatur autem*/}
            {/*            consequatur.</p>*/}
            {/*    </div>*/}
            {/*    <div className="story-column">*/}

            {/*        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>*/}
            {/*        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>*/}

            {/*        <h5 className="sub-title">Related links</h5>*/}
            {/*        <ul className="simple-list list-unstyled">*/}
            {/*            <li>*/}
            {/*                <a href="#!" title="Placeholder link title" className="link-icon">*/}
            {/*                    <i className="bi bi-globe"></i>*/}
            {/*                    <span>External Link Example </span>*/}
            {/*                </a>*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                <a href="#!" title="Placeholder link title" className="link-icon">*/}
            {/*                    <i className="bi bi-globe"></i>*/}
            {/*                    <span>External Link Example </span>*/}
            {/*                </a>*/}
            {/*            </li>*/}
            {/*        </ul>*/}
            {/*        <br/><br/><br/><br/><br/><br/>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </DsLayoutFullWidth>
    );
}


export default FaoVideoDetailView;
