import DsLayoutFullWidth from '../../layouts/DsLayoutFullWidth'
import { RenderCode } from '../../components/design-system/DsComponents'

const FaoPublicationDetailView = () => {
    return (
        <DsLayoutFullWidth>

            <RenderCode>

            {/* hero */}
            <div className="row bg-gray-light">
                <div className="col-md-12 py-5">
                    <div className="container detail-publication">
                        <div className="row">
                            <div className="col-md-9">
                                <div className="row">
                                    <div className="col-md-4 _card-image">
                                        <div className="thumb-image"></div>
                                        <div className="dynafef_det thumb-image">
                                            <img src="http://www.fao.org/3/cc0639en/cc0639en_200.jpg" className="rounded mb-3 mb-md-0"  alt="Lorem ipsum" />
                                        </div>
                                    </div>
                                    <div className="col-md-8 _card-header-info d-flex flex-column">
                                        <h1 className="detail-title py-0">Lorem ipsum dolor sit amet consectetur adipisicing elit</h1>
                                        <h6 className="sub-title pt-0 mt-2 text-color-primary"><strong>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores ullam, alias nam mollitia delectus architecto eius cum id laborum, facere amet officiis hic quisquam quidem a voluptas tempore</strong></h6>
                                        <div className="mt-auto tags-list title-category mb-3 mb-md-0">
                                            <div className="small mb-1">
                                                <strong>Also available in: </strong>
                                            </div>
                                            <a target="_blank" rel="noreferrer" className="badge bg-primary" href="#!">Arabic</a>
                                            <a target="_blank" rel="noreferrer" className="badge bg-primary" href="#!">Russian</a>
                                            <a target="_blank" rel="noreferrer" className="badge bg-primary" href="#!">French</a>
                                            <a target="_blank" rel="noreferrer" className="badge bg-primary" href="#!">Spanish</a>
                                            <a target="_blank" rel="noreferrer" className="badge bg-primary" href="#!">Chinese</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 _card-buttons-downloads">
                                <a href="#!" className="btn btn-primary d-block text-start mb-3" target="_blank" rel="noreferrer">
                                    <i className="bi bi-filetype-html pe-2"></i>
                                    Read Online
                                </a>
                                <a href="#!" className="btn btn-primary d-block text-start mb-3" target="_blank" rel="noreferrer">
                                    <i className="bi bi-filetype-pdf pe-2"></i>
                                    Download PDF
                                </a>
                                <a href="#!" className="btn btn-primary d-block text-start mb-3" target="_blank" rel="noreferrer">
                                    <i className="bi bi-book pe-2"></i> 
                                    Download ePub
                                </a>
                                <a href="#!" className="btn btn-primary d-block text-start mb-3" target="_blank" rel="noreferrer">
                                    <i className="bi bi-book pe-2"></i> 
                                    Download .mobi
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> {/* hero */}

            
            <div className="container">
                <div className="row mt-4">
                    {/* left column */}
                    <div className="col-md-3 _card-body-info-left">
                        <div className="border-bottom mb-2 pb-2">
                            <div className="small text-color-primary fw-bold">Year of publication</div>
                            <div className="small">2022</div>
                        </div>
                        <div className="border-bottom mb-2 pb-2">
                            <div className="small text-color-primary fw-bold">Place of publication</div>
                            <div className="small">Rome, Italy</div>
                        </div>
                        <div className="border-bottom mb-2 pb-2">
                            <div className="small text-color-primary fw-bold">Pages</div>
                            <div className="small force-ltr">#260 p.</div>
                        </div>
                        <div className="border-bottom mb-2 pb-2">
                            <div className="small text-color-primary fw-bold">ISBN</div>
                            <div className="small">000-00-0-000000-0</div>
                        </div>
                        <div className="border-bottom mb-2 pb-2">
                            <div className="small text-color-primary fw-bold">Author</div>
                            <div className="small">FAO; IFAD; UNICEF; WFP; WHO; </div>
                        </div>
                        <div className="border-bottom mb-2 pb-2">
                            <div className="small text-color-primary fw-bold">Serial Title</div>
                            <div className="small">
                                <a href="#!">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit
                                </a>
                            </div>
                        </div>
                        <div className="border-bottom mb-2 pb-2">
                            <div className="small text-color-primary fw-bold">Series number</div>
                            <div className="small">2022</div>
                        </div>
                        <div className="border-bottom mb-2 pb-2">
                            <div className="small text-color-primary fw-bold">Publisher</div>
                            <div className="small">FAO; IFAD; UNICEF; WFP; WHO; </div>
                        </div>
                        <div className="dynafef_det">
                            <div className="altmetric-embed my-3 d-inline-block">
                                <a target="_self" href="https://www.altmetric.com/details.php?domain=www.fao.org&amp;citation_id=130743412">
                                    <img className="border-0 m-0" alt="Article has an altmetric score of 3270" src="https://badges.altmetric.com/?size=240&amp;score=3270&amp;types=mbtttwfd" width="120" height="120" />
                                </a>
                            </div>
                        </div>
                        <div className="bg-gray-light p-3 small mb-3 text-break">
                            <span>
                                <strong className="text-color-primary">Cite this content as:</strong>
                                <br />
                                <div className="ExternalClass09B41812ED5A466DBAAA46FB6388F714">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero, exercitationem libero explicabo quod qui quas omnis cumque ullam eius ad vitae esse! Repellat, eligendi ab! Consequatur dicta illo quos repudiandae.<br /></p>
                                </div>
                                <a href="#~" target="_blank" rel="noreferrer">https://doi.org/</a>
                            </span>
                        </div>
                        <div>
                            <a href="#!" className="btn btn-primary d-block text-start mb-3" target="_blank" rel="noreferrer">
                                <i className="bi bi-cart2 pe-2"></i> 
                                Buy a print copy
                            </a>
                        </div>
                    </div>{/* left column */}


                    {/* right column */}
                    <div className="col-md-9 _card-body-info-center">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius dignissimos odio rem maxime culpa beatae, quae odit quibusdam esse assumenda, asperiores ex animi, eum cum autem sunt quia maiores vitae. Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius dignissimos odio rem maxime culpa beatae, quae odit quibusdam esse assumenda, asperiores ex animi, eum cum autem sunt quia maiores vitae. Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius dignissimos odio rem maxime culpa beatae, quae odit quibusdam esse assumenda, asperiores ex animi, eum cum autem sunt quia maiores vitae. Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius dignissimos odio rem maxime culpa beatae, quae odit quibusdam esse assumenda, asperiores ex animi, eum cum autem sunt quia maiores vitae.</p>
                        <div className="others-info bg-gray-light p-3 small mb-3">
                            <div id="cardText-content">
                                <p>The following complementary information is available:</p>
                                <ul>
                                    <li>Read online the <a href="#!" target="_blank" rel="noreferrer" className="TableHeading0"><i>full digital report</i></a></li>
                                    <li>See the <a href="#!" target="_blank" rel="noreferrer" className="TableHeading0"><i> interactive story</i></a></li>
                                    <li>Read the <a href="#!" target="_blank" rel="noreferrer" className="TableHeading0"><i>In Brief</i></a></li>
                                </ul>
                                <p><b><a href="#!" target="_blank" rel="noreferrer" className="external-link-new-window" title="Opens external link in new window">Help us improve your reading experience</a></b></p>
                                <p>Last updated date 01/01/2022 (minor update)</p>
                            </div>
                        </div>


                        <div className="mt-5 tags-list title-category">
                            <div className="fdr_label mb-1">Keywords: </div>
                            <a href="#!" className="badge bg-secondary">lorem</a>
                            <a href="#!" className="badge bg-secondary">ipsum</a>
                            <a href="#!" className="badge bg-secondary">lorem</a>
                            <a href="#!" className="badge bg-secondary">ipsum</a>
                            <a href="#!" className="badge bg-secondary">lorem</a>
                            <a href="#!" className="badge bg-secondary">ipsum</a>
                            <a href="#!" className="badge bg-secondary">lorem</a>
                            <a href="#!" className="badge bg-secondary">ipsum</a>
                            <a href="#!" className="badge bg-secondary">lorem</a>
                            <a href="#!" className="badge bg-secondary">ipsum</a>
                        </div>
                    </div>{/* right column */}

                </div>
            </div>

            </RenderCode>
            
        </DsLayoutFullWidth>
    );
}

export default FaoPublicationDetailView;
