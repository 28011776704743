// import { useState } from 'react';
// import { Link } from "react-router-dom";
import SideNavItem from './SideNavItem';

// import {route_styles, route_components, route_content, route_examples} from '../../routes';
// import {route_styles} from '../../routes';


const SideNavDs = props => {
    return (
        <SideNavItem name="Styles" itemId="sidenavSubmenuStyles" routes={props.routes} /> 
    );
}

export default SideNavDs;