

const TagsList = props => {
    return (
        <div className="tags-list title-category">
            {/*{props.title ? <span className="class-list__title">Tags: </span> : ''} */}
            <span className="badge text-bg-secondary">Lorem ipsum</span>
            <span className="badge text-bg-secondary">Lorem</span>
            <span className="badge text-bg-secondary">Lorem ipsum</span>
            <span className="badge text-bg-secondary">Lorem ipsum dolor sit amet, consectetur adipiscing elit</span>
            <a className="badge text-bg-secondary" href="!#" >Lorem</a>
        </div>
    );
}
  
export default TagsList;