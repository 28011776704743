import cardImage from '../../assets/test-images/card-test.jpg'


const CardOverlay = props => {
    return (
        <div className={`card card-overlay ${props.live && 'type-event on'} ${props.story && 'type-story'}`}  style={props.style}>
            <div className="card-image ratio ratio-3x2">
                <img src={cardImage} alt="..." />
            </div>
            <div className="card-img-overlay d-flex flex-column justify-content-center">
                {props.live && <h6 className="card-event-live">Live</h6>}
                <h6 className="title-category">{props.caption}</h6>
                <h5 className="card-title mb-3">Card title</h5>
                {!props.nodata && <h6 className="date">26/11/2020 Rome time</h6>}
                
            </div>
        </div>
    );
}
  
export default CardOverlay;