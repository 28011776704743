import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
import { CardPublication } from '../../components/fao/FaoComponents'
import cardImage from '../../assets/test-images/publication-1.jpg'

const FaoPublicationsCardView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Publications card</Heading>
            <style>{`.card { max-width: 18rem; }`}</style>
            <RenderCode>
                <CardPublication 
                    image={cardImage} 
                    title="Lorem ipsum dolor sit amet" 
                    date="26/06/2020" 
                    body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor." 
                />
            </RenderCode>
        </DsLayout>
    );
}


export default FaoPublicationsCardView;
