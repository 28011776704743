import DsLayout from '../../layouts/DsLayout'
import { Heading } from '../../components/design-system/DsComponents'


const ResourcesView = () => {
    return (
        <DsLayout>
        <Heading size="h1">Key Resources</Heading>
        <ul>
    <li>
        World Wide Web Consortium's&nbsp;
        <a   rel="noreferrer" target="_blank" href="https://www.w3.org/WAI/standards-guidelines/wcag/">
            WCAG Guidelines
        </a>
    </li>
    <li>
        <a   rel="noreferrer" target="_blank" href="https://webaim.org/standards/wcag/checklist">
            WebAIM's WCAG 2 Checklist
        </a>
        &nbsp;A distillation of WCAG guidelines to help technical implementation
    </li>
</ul>
        </DsLayout>
    );
}
  
export default ResourcesView;
