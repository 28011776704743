import DsLayout from '../layouts/DsLayout'
import packageJson from '../../package.json';
import { Heading, ShowCode } from '../components/design-system/DsComponents';

const DownloadPage = () => {
    return (
        <DsLayout container>
            <Heading size="h1">Download</Heading>
            <p>Download FAO Design System Theme to get the compiled CSS, source code, or include it with your favorite package managers like npm, yarn, NuGet and more.</p>
            
            <div>
                <img className='me-4' src="https://img.shields.io/npm/dt/fao-design-system" alt="NPM Downloads" />
                <img src="https://badge.fury.io/js/fao-design-system.svg" alt="npm version" />    
            </div>

            <Heading size="h3">Resources for designers</Heading>
            <p>Our <b>Figma</b> and <b>Adobe XD</b> libraries contain the latest foundations, components, and patterns for designing and building FAO web products.</p>

            <a className="btn btn-secondary btn-icon me-lg-3 d-inline-flex" href={`/resources/fao-design-system.fig`} download>
                <div style={{width: '1rem', marginRight: '.5rem'}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 57" className="figma-77e5ie" aria-label="Homepage"><path fill="#1abcfe" d="M19 28.5a9.5 9.5 0 1 1 19 0 9.5 9.5 0 0 1-19 0z"></path><path fill="#0acf83" d="M0 47.5A9.5 9.5 0 0 1 9.5 38H19v9.5a9.5 9.5 0 1 1-19 0z"></path><path fill="#ff7262" d="M19 0v19h9.5a9.5 9.5 0 1 0 0-19H19z"></path><path fill="#f24e1e" d="M0 9.5A9.5 9.5 0 0 0 9.5 19H19V0H9.5A9.5 9.5 0 0 0 0 9.5z"></path><path fill="#a259ff" d="M0 28.5A9.5 9.5 0 0 0 9.5 38H19V19H9.5A9.5 9.5 0 0 0 0 28.5z"></path></svg></div>
                Download .fig
            </a>
            <a className="btn btn-secondary btn-icon me-lg-3 d-inline-flex align-items-center" href={`/resources/fao-design-system.xd`} download>
                <div style={{marginRight: '.5rem'}}>
                    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="31" height="31" rx="5" fill="#450036"/>
                    <path d="M15.7393 21H13.3193L10.9951 17.2197L8.6709 21H6.40137L9.7168 15.8457L6.61328 11.0059H8.95117L11.1045 14.6016L13.2168 11.0059H15.5L12.3623 15.9619L15.7393 21ZM19.2529 21.1367C18.4007 21.1367 17.7057 20.804 17.168 20.1387C16.6348 19.4688 16.3682 18.4867 16.3682 17.1924C16.3682 15.8844 16.6393 14.8955 17.1816 14.2256C17.724 13.5511 18.4326 13.2139 19.3076 13.2139C19.6722 13.2139 19.9935 13.264 20.2715 13.3643C20.5495 13.4645 20.7887 13.599 20.9893 13.7676C21.1943 13.9362 21.3675 14.1253 21.5088 14.335H21.5771C21.5498 14.1891 21.5156 13.9749 21.4746 13.6924C21.4382 13.4053 21.4199 13.1113 21.4199 12.8105V10.3633H23.5117V21H21.9121L21.5088 20.0088H21.4199C21.2878 20.2184 21.1214 20.4098 20.9209 20.583C20.7249 20.7516 20.4902 20.8861 20.2168 20.9863C19.9434 21.0866 19.6221 21.1367 19.2529 21.1367ZM19.9844 19.4756C20.554 19.4756 20.9551 19.307 21.1875 18.9697C21.4245 18.6279 21.5498 18.1152 21.5635 17.4316V17.2061C21.5635 16.4632 21.4495 15.8958 21.2217 15.5039C20.9938 15.1074 20.57 14.9092 19.9502 14.9092C19.4899 14.9092 19.1299 15.1074 18.8701 15.5039C18.6104 15.9004 18.4805 16.4723 18.4805 17.2197C18.4805 17.9671 18.6104 18.5299 18.8701 18.9082C19.1344 19.2865 19.5059 19.4756 19.9844 19.4756Z" fill="#FE5EF4"/>
                    </svg>
                </div>
                Download .xd
            </a>

    

            <Heading size="h3">Compiled CSS</Heading>
            <div className="card text-color-default bg-primary-light">
                <div className="card-body">
                    {/* <Heading size="h3" className="mt-0">Prerequisites</Heading> */}
                    <h3 className="toc-entry m-0 mb-3 p-0">Prerequisites</h3>
                    <p className="m-0">The FAO Design System Theme CSS styles are build on top of the Bootstrap 5 library. In order to use our styles is required to import the Bootstrap 5 CSS before our bundles.</p>
                </div>
            </div>

            <p>Download ready-to-use compiled code for FAO Design System Theme to easily drop into your project:</p>
            <a className="btn btn-primary me-lg-3" href={`/releases/current/packed/fao-design-system-${packageJson.version}.zip`} download>Download ZIP</a>
            <a className="btn btn-primary" href={`/releases/current/packed/fao-design-system-${packageJson.version}.tgz`}>Download TGZ</a>

            <Heading size="h3">Package managers</Heading>
            <p>Pull in FAO Design System Theme CSS files into nearly any project with some of the most popular package managers.</p>

            <Heading size="h3">npm</Heading>
            <p>Install FAO Design System Theme CSS in your Node.js powered apps with <a href="https://www.npmjs.com/package/fao-design-system" target="_blank" rel="noreferrer">the npm package</a>:</p>
            <ShowCode hideHeader>
                npm i fao-design-system
            </ShowCode>
            
            <Heading size="h3">yarn</Heading>
            <p>Install FAO Design System Theme CSS in your Node.js powered apps with <a href="https://yarnpkg.com/package/fao-design-system" target="_blank" rel="noreferrer">the yarn package</a>:</p>
            <ShowCode hideHeader>
                yarn fao-design-system
            </ShowCode>

            {/* <Heading size="h3">NuGet</Heading>
            <p>If you develop in .NET Framework, you can also install and manage FAO Design System Theme CSS using NuGet. Newer projects should use libman or another method as NuGet is designed for compiled code, not frontend assets.</p>
            <ShowCode hideHeader>
                Install-Package fao-design-system
            </ShowCode> */}
        </DsLayout>
    );
}
  
export default DownloadPage;
