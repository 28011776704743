import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import UseDebounce from './UseDebounce';

const SearchComponents = props => {
  const allRoutes = props.allRoutes;
  const [search, setSearch] = useState('');
  const [currentSearchedRoutes, SetCurrentSearchedRoutes] = useState([]);

  UseDebounce(() => {
      SetCurrentSearchedRoutes(search ? processRoutes(allRoutes, search) : []);
    }, [allRoutes, search], 100
  );

  const handleSearch = (e) => setSearch(e.target.value);

    return (
        <div className="ds-search mb-5">
          <form className="ds-search-input">
            <i className="bi bi-search m-0 me-xl-2"></i>
            <input className='form-control form-control-lg wtr' id="search" type="text" spellCheck="false" autoComplete="off" placeholder="Search component name or path:" value={search || ''} onChange={handleSearch} />
            {Object.keys(currentSearchedRoutes).length !== 0 && 
              <i className="bi bi-x-circle ds-search-close-resutls" onClick={() => setSearch('')}></i>
            }
          </form>
          {Object.keys(currentSearchedRoutes).length !== 0 && 
            <>
              <div className="ds-search-results">
                {Object.keys(currentSearchedRoutes).map((key, i) =>
                  <div className="mb-3" key={i}>
                    <p className='text-color-primary fw-semibold text-uppercase ds-search-result-cat'>{key}</p>
                    <div className='bd-links-nav list-group'>
                      {currentSearchedRoutes[key].map((route, idx) => {
                        return <NavLink className="app-sidebar-link m-0 rounded list-group-item list-group-item-action" to={route.path} key={idx}
                        activeClassName='active' onClick={() => closeModal(props.modalId)} >
                          {route.name}</NavLink>
                      })}
                    </div>
                  </div>
                )}
              </div>
            </>
          }

        </div>
    )
}

export default SearchComponents;

function processRoutes(arr, search) {
  let results = arr.filter((d) => d.name?.toLowerCase().includes(search.toLowerCase())).sort((a, b) => a.category.localeCompare(b.category));

  if (results.length === 0 && search.length > 1) {
    const LevenshteinArr = arr.map(d => {
      const words = d.name.split(' ').filter(el => el !== "-");
      const scores = words.map(word => (LD(word.toLowerCase(), search.toLowerCase())));
      return {LDistance: Math.min(...scores), element: d}
    }).sort((a, b) => a.LDistance - b.LDistance);

    if (LevenshteinArr.length > 0) {
      const recordsNumberToShow = LevenshteinArr.length > 3 ? 4 : LevenshteinArr.length;
      results = LevenshteinArr.slice(0, recordsNumberToShow).filter(el => el.LDistance < 3).map(entry => entry.element);
    }
  }

  const objByCategories = {};
  // organize by categories
  results.forEach(entry => {
      let nameArray = objByCategories[entry.category];
      if (!nameArray) {
          nameArray = objByCategories[entry.category] = [];
      }
      nameArray.push(entry);
  });
  // sort by name each entry of each category and change name for duplicate entries
  Object.keys(objByCategories).forEach(key => {
      objByCategories[key] = objByCategories[key].sort((a, b) => a.name.localeCompare(b.name));
      if (new Set(objByCategories[key].map(e => e.name)).size !== objByCategories[key].length) {
        objByCategories[key].forEach(entry => {
          const dups = objByCategories[key].filter(e => e.name === entry.name);
          const dsContainer = document.getElementsByClassName('ds-search-results')[0];
          if (dups.length > 1 && dsContainer) {
            let anchors = [...dsContainer.getElementsByTagName('a')];

            if (dups.length !== anchors.length) {
              anchors = anchors.filter(a => a.innerText === dups[0].name);
            }

            anchors.forEach((a, i) => a.innerText = `${a.innerText} - ${dups[i].path.substring(dups[i].path.lastIndexOf('/') + 1)}`)

            // dups.forEach(dup => {
            //   dup.name = `${dup.name} - ${dup.path.substring(dup.path.lastIndexOf('/') + 1)}`
            // });
          }
        })
      }
  });

  return objByCategories;
}

function closeModal(modalId) {
  const el = document.getElementById(modalId);
  el?.querySelector('.btn-close')?.click();
}

function LD(a, b) {
  if (a === b) {
      return 0;
    }

    if (a.length > b.length) {
      var tmp = a;
      a = b;
      b = tmp;
    }

    var la = a.length;
    var lb = b.length;

    while (la > 0 && (a.charCodeAt(la - 1) === b.charCodeAt(lb - 1))) {
      la--;
      lb--;
    }

    var offset = 0;

    while (offset < la && (a.charCodeAt(offset) === b.charCodeAt(offset))) {
      offset++;
    }

    la -= offset;
    lb -= offset;

    if (la === 0 || lb < 3) {
      return lb;
    }

    var x = 0;
    var y;
    var d0;
    var d1;
    var d2;
    var d3;
    var dd;
    var dy;
    var ay;
    var bx0;
    var bx1;
    var bx2;
    var bx3;

    var vector = [];

    for (y = 0; y < la; y++) {
      vector.push(y + 1);
      vector.push(a.charCodeAt(offset + y));
    }

    var len = vector.length - 1;

    for (; x < lb - 3;) {
      bx0 = b.charCodeAt(offset + (d0 = x));
      bx1 = b.charCodeAt(offset + (d1 = x + 1));
      bx2 = b.charCodeAt(offset + (d2 = x + 2));
      bx3 = b.charCodeAt(offset + (d3 = x + 3));
      dd = (x += 4);
      for (y = 0; y < len; y += 2) {
        dy = vector[y];
        ay = vector[y + 1];
        d0 = _min(dy, d0, d1, bx0, ay);
        d1 = _min(d0, d1, d2, bx1, ay);
        d2 = _min(d1, d2, d3, bx2, ay);
        dd = _min(d2, d3, dd, bx3, ay);
        vector[y] = dd;
        d3 = d2;
        d2 = d1;
        d1 = d0;
        d0 = dy;
      }
    }

    for (; x < lb;) {
      bx0 = b.charCodeAt(offset + (d0 = x));
      dd = ++x;
      for (y = 0; y < len; y += 2) {
        dy = vector[y];
        vector[y] = dd = _min(dy, d0, dd, bx0, vector[y + 1]);
        d0 = dy;
      }
    }

    return dd;
};

function _min(d0, d1, d2, bx, ay)
{
return d0 < d1 || d2 < d1
    ? d0 > d2
        ? d2 + 1
        : d0 + 1
    : bx === ay
        ? d1
        : d1 + 1;
}
