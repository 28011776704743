const EmailModal = props => {
    return (
        <div className={`modal modal-email fade ${props.className}`} id="emailModal" tabIndex="-1" aria-labelledby="emailModalLabel" aria-hidden="true" style={props.style}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <form action="">
                        <div className="modal-header">
                            <h5 className="modal-title" id="emailModalLabel">Send email</h5>
                            <button type="button" className="btn btn-secondary btn-icon btn-round close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <input type="email" className="form-control" id="emailModalInput" placeholder="Enter email" />                  
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal">Send</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
  
export default EmailModal;