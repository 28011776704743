import react from "react";
import { RenderCode } from '../design-system/DsComponents'

const ProjectsListItem = props => {
    return (
        <RenderCode hideCode={!props.showCode}>
            <div className="project-list-item">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            <th scope="row" className="bg-gray-light">Title</th>
                            <td><a href="#!">{props.title}</a></td>
                        </tr>

                        { props.fullList && renderDateAndRecipient(props) }

                        <tr>
                            <th scope="row" className="bg-gray-light">Budget</th>
                            <td>{props.budget}</td>
                        </tr>
                        <tr>
                            <th scope="row" className="bg-gray-light">Donor</th>
                            <td>{props.donor}</td>
                        </tr>

                        { props.fullList && renderDProjectCode(props) }

                    </tbody>
                </table>
            </div>
        </RenderCode>
    );
}  

const renderDateAndRecipient = obj => {
    return(
        <react.Fragment>
            <tr>
                <th scope="row" className="bg-gray-light">Start Date</th>
                <td>{obj.startDate}</td>
            </tr>
            <tr>
                <th scope="row" className="bg-gray-light">End Date</th>
                <td>{obj.endDate}</td>
            </tr>
            <tr>
                <th scope="row" className="bg-gray-light">Recipient/Target areas</th>
                <td>{obj.targetAreas}</td>
            </tr>
        </react.Fragment>
    );
}

const renderDProjectCode = obj => {
    return(
        <tr>
            <th scope="row" className="bg-gray-light">Project code</th>
            <td>{obj.projectCode}</td>
        </tr>
    );
}

export default ProjectsListItem;
