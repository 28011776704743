import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
import { CardVideo } from '../../components/fao/FaoComponents'

const FaoVideoCardView = () => {
    return (
        <DsLayout>
            <style>{`.card { max-width: 18rem; }`}</style>
            <Heading size="h1">Video card</Heading>
            <RenderCode>
                <CardVideo 
                    iframe="https://www.youtube.com/embed/WeoIsjYBQH0?controls=0" 
                    title="Lorem ipsum dolor sit amet" 
                    date="26/06/2020" 
                    body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor." 
                />
            </RenderCode>
        </DsLayout>
    );
}

export default FaoVideoCardView;
