

const Button = props => {
    let classes = `btn btn-${props.type}`;

    if (props.hasIcon) classes += ' btn-icon';
    if (props.hasIconLeft) classes += ' btn-icon';
    if (props.round) classes += ' btn-round';
    if (props.roundBig) classes += ' btn-round-big';
    if (props.roundSide) classes += ' btn-round-side';
    if (props.block) classes += ' d-block mb-3';
    if (props.mr) classes += ' me-4';
    if (props.large) classes += ' btn-lg';


    return (
        <a href="www.fao.org" className={classes} style={props.style}>
            { props.hasIconLeft ? <i className={`bi bi-${props.icon}`}></i> : null }
            { props.children }
            { props.hasIcon ? <i className={`bi bi-${props.icon}`}></i> : null }
        </a>
    );
}
  
export default Button;