import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
import { ListStory } from '../../components/fao/FaoComponents'

const FaoStoryListView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Story list</Heading>

            <RenderCode>
                <ListStory />
            </RenderCode>
        </DsLayout>
    );
}

export default FaoStoryListView;
