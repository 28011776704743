import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
import { SubSiteNavbar } from '../../components/fao/FaoComponents'

const HeroView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Navbar</Heading>

            <Heading size="h3">Subsite Navbar</Heading>
            <RenderCode>
                <SubSiteNavbar />
            </RenderCode>

            {/* <Heading size="h3">Corporate</Heading>
            <p>The corporate navigation needs to be included inside the Header Component as last element.</p>
            <p>Please find below the full exmaple and markup for the Corporate header including the corporate navigation:</p>
            <p>Please note that in the example below the navigation is not rendering properly. To see a good example please use the following page: <router-link to="/example/home">Home page example</router-link></p>
            <RenderCode>
                <Header />
            </RenderCode> */}

            {/* <Heading size="h3">International Year</Heading>
            <RenderCode>
                <div className="iy-navbar-subsite">
                    <div className="container">
                        <SubSiteNavbar className="iy-navbar-subsite"/>
                    </div>
                </div>
            </RenderCode> */}

        </DsLayout>
    );
}

export default HeroView;