import React from 'react';
import DsLayoutFullWidth from '../layouts/DsLayoutFullWidth'
import  UserContext from '../contexts/UserContext'
import { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'


const Login = () => {
    const { user, userLogin } = useContext(UserContext);
    const history = useHistory();
    const styles = {
        maxWidth: '460px', 
        marginLeft: 'auto', 
        marginRight: 'auto', 
        paddingLeft: '1rem', 
        paddingRight: '1rem' 
    }

    useEffect(() => {
        const aCookies = document.cookie.split(';');
        const auth = aCookies.find(cookie => cookie.includes('auth'));
        const authValue = auth?.split('=')[1];

        if (authValue === 'true') {
            userLogin();
            history.push(`/`);
        };
    }, [user, history, userLogin]);

    const handleSubmit = (e)=> {
        e.preventDefault();
        const today = new Date();
        const nextweek = new Date(today.getFullYear(), today.getMonth(), today.getDate()+7);
        
        if (e.target.username.value === 'fao' && e.target.password.value === 'fao1234') {
            userLogin();
            document.cookie = `auth=true; expires=${nextweek.toUTCString()}}`;
            history.push(`/`);
        } else {
            alert('Wrong user or password');
        }
    }

    return (
        <DsLayoutFullWidth>
            <div style={styles}>
                <h1 className="ds-title-fw mt-5 mb-4">Login</h1>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="username" className="form-label">User</label>
                        <input type="text" className="form-control" id="username" name="username" />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Password</label>
                        <input type="password" className="form-control" id="password" />
                    </div>
                    <button type="submit" className="btn btn-primary mt-4 d-block">Submit</button>
                </form>
            </div>
        </DsLayoutFullWidth>
    );
}
  
export default Login;
