import {
    Header,
    Breadcrumb,
    ListArticle,
    ListAudio,
    ListBlog,
    ListElearning,
    ListEvent,
    ListNews,
    ListPartners,
    ProjectsListItem,
    ListPublication,
    ListPhotoGallery,
    ListSpeeches,
    ListStory,
    ListVideo,
    Footer,
} from '../../components/fao/FaoComponents'
import {RenderOnLogin} from '../../components/design-system/DsComponents'


const DemoPageView = () => {
    return (

        <RenderOnLogin>
            <Header/>
            <br/><br/><br/>
            {/*<div className="container">*/}
            {/*    <div className="dynamic-list">*/}
            {/*        <div className="row dynamic-list-row">*/}
            {/*            <div className="col-md-3 col-md-2">*/}
            {/*                <div className="card-calendar">*/}
            {/*                        <div className="row-calendar">*/}

            {/*                            <p className="date">19/2</p>*/}
            {/*                            <p className="year">2024</p>*/}
            {/*                        </div>*/}
            {/*                                <div className="from-to-divider"></div>*/}
            {/*                                <div className="row-calendar">*/}

            {/*                                    <p className="date">23/2</p>*/}
            {/*                                    <p className="year">2024</p>*/}
            {/*                                </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-md-9 col-md-10">*/}
            {/*                <h5 className="title-link pt-3 pt-md-0">*/}
            {/*                    <a href="https://www.fao.org/gfcm/fishforum2024" className="title-link">GFCM Forum on Fisheries Science in the Mediterranean and the Black Sea</a>*/}
            {/*                </h5>*/}
            {/*                <h6 className="date"><i className="bi bi-geo-alt-fill"></i>Antalya (Türkiye), 19/02/2024 - 23/02/2024</h6>*/}
            {/*                <p>*/}
            {/*                    The Fish Forum unites scientists, fishers, stakeholders, researchers, engineers, academics, practitioners, managers and decision-makers from international organizations, administrations, institutions and universities, non-governmental organizations and the private sector, in view of continued and accelerated progress towards ensuring sustainable fisheries in the Mediterranean and the Black Sea....*/}
            {/*                </p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}


            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div style={{backgroundColor: 'red', height: '100px'}}></div>

                    </div>
                </div>
                <br/><br/>
                <div className="row">
                    <div className="col-md-9">
                        <div style={{backgroundColor: 'red', height: '100px'}}></div>
                    </div>
                    <div className="col-md-3">
                        <div style={{backgroundColor: 'green', height: '100px'}}></div>
                    </div>
                </div>
                <br/><br/>
                <div className="row">
                    <div className="col-md-6">
                        <div style={{backgroundColor: 'red', height: '100px'}}></div>
                    </div>
                    <div className="col-md-6">
                        <div style={{backgroundColor: 'green', height: '100px'}}></div>
                    </div>
                </div>
                <br/><br/>
                <div className="row">
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-6">
                                <div style={{backgroundColor: 'blue', height: '100px'}}></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <br/><br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <ListArticle/>
                        <ListSpeeches/>
                        <ListElearning/>


                        <ListEvent fromTo/>
                        <ListNews/>
                        <ListBlog/>
                        <ListPartners/>

                        <ListStory/>

                        <ListAudio/>
                        <ListAudio player/>
                        <ListVideo/>
                        <ListVideo player/>
                        <ListPhotoGallery/>

                        <ListPublication/>


                        <ProjectsListItem

                            title="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Amet deserunt officia fugiat."
                            startDate="02/06/2022"
                            endDate="04/06/2022"
                            targetAreas="Lorem, Ipsum, Lorem, Ipsum"
                            budget="99999"
                            donor="Lorem ipsum"
                            projectCode="LOREM/IPS/UM"
                        />

                    </div>
                </div>
                <br/><br/>
                <div className="row">
                    <div className="col-md-9">
                        <ListArticle/>
                        <ListSpeeches/>
                        <ListElearning/>


                        <ListEvent fromTo/>
                        <ListNews/>
                        <ListBlog/>
                        <ListPartners/>

                        <ListStory/>

                        <ListAudio/>
                        <ListAudio player/>
                        <ListVideo/>
                        <ListVideo player/>
                        <ListPhotoGallery/>

                        <ListPublication/>


                        <ProjectsListItem

                            title="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Amet deserunt officia fugiat."
                            startDate="02/06/2022"
                            endDate="04/06/2022"
                            targetAreas="Lorem, Ipsum, Lorem, Ipsum"
                            budget="99999"
                            donor="Lorem ipsum"
                            projectCode="LOREM/IPS/UM"
                        />
                    </div>
                    <div className="col-md-3">
                        <ListArticle/>
                        <ListSpeeches/>
                        <ListElearning/>


                        <ListEvent fromTo/>
                        <ListNews/>
                        <ListBlog/>
                        <ListPartners/>

                        <ListStory/>

                        <ListAudio/>
                        <ListAudio player/>
                        <ListVideo/>
                        <ListVideo player/>
                        <ListPhotoGallery/>

                        <ListPublication/>


                        <ProjectsListItem

                            title="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Amet deserunt officia fugiat."
                            startDate="02/06/2022"
                            endDate="04/06/2022"
                            targetAreas="Lorem, Ipsum, Lorem, Ipsum"
                            budget="99999"
                            donor="Lorem ipsum"
                            projectCode="LOREM/IPS/UM"
                        />
                    </div>
                </div>
                <br/><br/>
                <div className="row">
                    <div className="col-md-6">
                        <ListArticle/>
                        <ListSpeeches/>
                        <ListElearning/>


                        <ListEvent fromTo/>
                        <ListNews/>
                        <ListBlog/>
                        <ListPartners/>

                        <ListStory/>

                        <ListAudio/>
                        <ListAudio player/>
                        <ListVideo/>
                        <ListVideo player/>
                        <ListPhotoGallery/>

                        <ListPublication/>


                        <ProjectsListItem

                            title="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Amet deserunt officia fugiat."
                            startDate="02/06/2022"
                            endDate="04/06/2022"
                            targetAreas="Lorem, Ipsum, Lorem, Ipsum"
                            budget="99999"
                            donor="Lorem ipsum"
                            projectCode="LOREM/IPS/UM"
                        />
                    </div>
                </div>
                <br/><br/>
                <div className="row">
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-6">
                                <ListArticle/>
                                <ListSpeeches/>
                                <ListElearning/>


                                <ListEvent fromTo/>
                                <ListNews/>
                                <ListBlog/>
                                <ListPartners/>

                                <ListStory/>

                                <ListAudio/>
                                <ListAudio player/>
                                <ListVideo/>
                                <ListVideo player/>
                                <ListPhotoGallery/>

                                <ListPublication/>


                                <ProjectsListItem

                                    title="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Amet deserunt officia fugiat."
                                    startDate="02/06/2022"
                                    endDate="04/06/2022"
                                    targetAreas="Lorem, Ipsum, Lorem, Ipsum"
                                    budget="99999"
                                    donor="Lorem ipsum"
                                    projectCode="LOREM/IPS/UM"
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <br/><br/><br/>

                        <ul className="simple-list list-unstyled">
                            <li>
                                <a href="#!" title="Placeholder link title" className="link-icon">
                                    <i className="bi bi-globe"></i>
                                    <span>External Link Example </span>
                                </a>
                            </li>
                            <li>
                                <a href="#!" title="Placeholder link title" className="link-icon">
                                    <i className="bi bi-globe"></i>
                                    <span>External Link Example </span>
                                </a>
                            </li>
                        </ul>

                        <br/><br/><br/>


                        <ListArticle/>
                        <ListSpeeches/>
                        <ListElearning/>


                        <ListEvent fromTo/>
                        <ListNews/>
                        <ListBlog/>
                        <ListPartners/>

                        <ListStory/>

                        <ListAudio/>
                        <ListAudio player/>
                        <ListVideo/>
                        <ListVideo player/>
                        <ListPhotoGallery/>

                        <ListPublication/>


                        <ProjectsListItem

                            title="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Amet deserunt officia fugiat."
                            startDate="02/06/2022"
                            endDate="04/06/2022"
                            targetAreas="Lorem, Ipsum, Lorem, Ipsum"
                            budget="99999"
                            donor="Lorem ipsum"
                            projectCode="LOREM/IPS/UM"
                        />
                        <br/><br/><br/>

                    </div>
                </div>
            </div>

            <div className="theme-dark position-relative">
                <div className="audio-visual-cover">
                    <div className="audio-visual-image" style={{backgroundImage: 'url(https://www.fao.org/images/corporatelibraries/corporate-pages-images/02Brief.png)'}}/>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="content-icons">

                                <Breadcrumb/>




                            </div>
                        </div>

                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-xl-7 position-relative">

                            <h2 className="page-title border-0">FAO Brief</h2>
                            <h6 className="title-category">PODCAST SERIES 2</h6>
                            <p>Find out the latest news and happenings at FAO. A regular round-up of recent headlines,
                                new initiatives and upcoming events</p>
                            <div className="tags-list title-category">
                                <span className="class-list__title">TAGS:</span>
                                <span className="badge text-bg-secondary">Lorem ipsum</span>
                                <span className="badge text-bg-secondary">Lorem</span>
                                <span className="badge text-bg-secondary">Lorem ipsum</span>
                                <a className="badge text-bg-secondary" href="!#">Lorem</a>
                            </div>
                            <br/>
                            <p>If you are experiencing issues listening to the podcast on your PC please refer to this
                                FAQ at Soundcloud.com for support.</p>
                            <br/>
                            <div className="media-provider-list">
                                <span className="media-provider-title title-category">AVAILABLE ON:</span>
                                <a href="!#" className="media-provider-ico">
                                    <img src="https://www.fao.org/images/corporatelibraries/social-icons/soundcloud.svg" alt="Sound cloud"/>
                                </a>
                                <a href="!#" className="media-provider-ico">
                                    <img src="https://www.fao.org/images/corporatelibraries/social-icons/applebutton.svg" alt="Sound cloud"/>
                                </a>
                                <a href="!#" className="media-provider-ico">
                                    <img src="https://www.fao.org/images/corporatelibraries/social-icons/stitcherbutton.svg" alt="Sound cloud"/>
                                </a>
                                <a href="!#" className="media-provider-ico">
                                    <img src="https://www.fao.org/images/corporatelibraries/social-icons/spotifybutton.svg" alt="Sound cloud"/>
                                </a>

                            </div>
                            <br/>
                            <h6 className="title-category">(AVERAGE LISTENING TIME 3 MINS)</h6>
                            <hr/>
                            <ListAudio/>
                            <ListAudio/>
                            <ListAudio/>
                            <ListAudio/>
                            <nav aria-label="Page navigation example">
                                <ul className="pagination justify-content-center">
                                    <li className="page-item">
                                        <a className="page-link" href="#!" aria-label="Previous">
                                            <span aria-hidden="true">&laquo;</span>
                                        </a>
                                    </li>
                                    <li className="page-item"><a className="page-link" href="#!">1</a></li>
                                    <li className="page-item active"><a className="page-link" href="#!">2</a></li>
                                    <li className="page-item"><a className="page-link" href="#!">3</a></li>
                                    <li className="page-item">
                                        <a className="page-link" href="#!" aria-label="Next">
                                            <span aria-hidden="true">&raquo;</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                            <br/><br/><br/>
                        </div>
                    </div>

                </div>

            </div>
            <Footer/>

        </RenderOnLogin>
    );
}

export default DemoPageView;