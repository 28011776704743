import DsLayout from '../../layouts/DsLayout'
import {Heading, RenderCode} from '../../components/design-system/DsComponents'

const EmbedsView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Embeds</Heading>

            <Heading size="h3">Aspect Ratios</Heading>
            <p>In the FAO Design System we have extended the standard Bootstrap class "ratio" by adding a
                new ratio 3 by 2 (our standards for images, videos etc..).</p>

            <Heading size="h3">3 by 2</Heading>
            <RenderCode>
                <div className="ratio ratio-3x2">
                    <iframe src="https://www.youtube.com/embed/WeoIsjYBQH0?controls=0" title="iframe1" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
            </RenderCode>
        </DsLayout>
    );
}


export default EmbedsView;