import DsLayout from '../../layouts/DsLayout';
import { Heading, RenderCode } from '../../components/design-system/DsComponents';
import { Header, TopNav } from '../../components/fao/FaoComponents';


const HeaderView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Header</Heading>

            <Heading size="h3">Subsite Header</Heading>
            <RenderCode withBorders={true}>
                <Header subsite />
            </RenderCode>

            <Heading size="h3">Header share</Heading>
            <p>This component can be included under the header</p>
            <RenderCode withBorders={true}>
                <div className="header-share">
                    <div className="container">
                    <ul className="list-unstyled mb-0 d-flex justify-content-end">
                        <li>
                        <a href="#!" title="Placeholder link title" className="link-icon">
                            <i className="bi bi-printer"></i>
                            <span>Print</span>
                        </a>
                        </li>
                        <li>
                        <a href="#!" title="Placeholder link title" className="link-icon">
                            <i className="bi bi-envelope"></i>
                            <span>Send</span>
                        </a>
                        </li>
                    </ul>
                    </div>
                </div>
            </RenderCode>

            <Heading size="h3">Header Application</Heading>
            <RenderCode withBorders={true}>
                <TopNav search login languages title="DESIGN SYSTEM"/>
            </RenderCode>

            <Heading size="h3">Header Application - Without Login</Heading>
            <RenderCode withBorders={true}>
                <TopNav search languages title="DESIGN SYSTEM"/>
            </RenderCode>

            <Heading size="h3">Header Application - Without Login and Search</Heading>
                <RenderCode withBorders={true}>
                <TopNav languages title="DESIGN SYSTEM"/>
            </RenderCode>
            
        </DsLayout>
    );
}

export default HeaderView;