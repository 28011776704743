import React, { useState, useEffect } from "react";
import { Heading } from './DsComponents';
import Markdown from 'markdown-to-jsx';
import CHANGELOG from './CHANGELOG.md';

const ChangelogViewer = () => {
    const [content, setContent] = useState({md: []});
    const [allAccordionsOpen, setAllAccordionsOpen] = useState(false);


    const handleClick = () => {
        setAllAccordionsOpen(current => !current);
      };
    

    useEffect(()=> {
        fetch(CHANGELOG)
            .then((res) => res.text())
            .then((md) => {
                const mdArrRaw = md.split('\n');
                const mdArrSplitted = [];
                mdArrRaw.forEach(el => {
                    if (el.trim().startsWith('## ')) {
                        mdArrSplitted.push([el]);
                    } else {
                        mdArrSplitted[mdArrSplitted.length - 1].push(el);
                    }
                })
                setContent({md: mdArrSplitted});
            })
    }, [])

    return (
    <div className="ds-changelog mb-5">
        <h2 className="page-title my-4">Changelog</h2>

        <div className="ds-changelog__last-release pt-4 pb-5">
                <Markdown children={content?.md[0]?.join('\n') ?? ''} />            
        </div>


        <div>
            <input onClick={handleClick} type="checkbox" id="openAll" name="openAll" />
            <label className="ms-2 mb-2 user-select-none" htmlFor="openAll">Open all accordions</label>
        </div>


        <div className="accordion" id="changelogAccordion">
            {content.md.slice(1).map((el, i) => {
                return <div key={i} className="accordion-item">
                            <div className="accordion-header" id={"headingacordion" + i}>
                                <h2 className="mb-0">
                                    <button className={"accordion-button " + (allAccordionsOpen ? "open": "collapsed")} type="button" data-bs-toggle="collapse"
                                     data-bs-target={"#changelogAccordion" + i} aria-expanded={allAccordionsOpen ? "true" : "false"} aria-controls={"collapsechangelogAccordion" + i}>
                                        <Markdown options={{overrides: {h2: { component: Heading, props: {size: 'h3'}}}}} children={el[0]} />
                                    </button>
                                </h2>
                            </div>

                            <div id={"changelogAccordion" + i} className={"accordion-collapse " + (allAccordionsOpen ? "show" : "collapse")} aria-labelledby={"headingacordion" + i} 
                             {...(!allAccordionsOpen && {'data-bs-parent':'#changelogAccordion'})}>
                                <div className="accordion-body">
                                    <Markdown children={el.slice(1).join('\n')} />
                                </div>
                            </div>            

                        </div>;
            })}
        </div>
    </div>);
}

export default ChangelogViewer;
