import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
import { CardEvent, CardEventOverlay  } from '../../components/fao/FaoComponents'
import cardImage from '../../assets/test-images/card-test.jpg'

const FaoEventsCardView = () => {
    return (
        <DsLayout>
            <style>
                {
                `
                .card { max-width: 18rem; }
                .card.card-overlay { max-width: 22rem; }
                `
                }
            </style>
            <Heading size="h1">Events card</Heading>

            <Heading size="h3">Image overlay with live indicator</Heading>
            <p>Similar to a standard Card Overlay. But it can have the class "on", that turns the live tag on.</p>
            
            <RenderCode>
                <CardEventOverlay 
                    image={cardImage} 
                    title="Lorem ipsum dolor sit amet" 
                    date="26/06/2020 Rome Time" 
                />
            </RenderCode>
            <Heading size="h3">Standard events card with location icon</Heading>

            <RenderCode>
                <CardEvent 
                    image={cardImage} 
                    location="Event location"
                    title="Lorem ipsum dolor sit amet" 
                    date="26/06/2020 Rome Time" 
                    body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor." 
                />
            </RenderCode>
        </DsLayout>
    );
}

export default FaoEventsCardView;
