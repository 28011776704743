const CardTwitter = props => {
    return (
        <div className="card card-twitter">
            <div className="card-header">
                <i className="bi bi-twitter"></i>
                Join the conversation
            </div>
            <div className="card-body">
               <ul className="list-unstyled">
                   <li><a href="https://twitter.com/home">#Hashtag-number-1</a></li>
                   <li><a href="https://twitter.com/home">#Hashtag-number-2</a></li>
                   <li>#Hashtag-number-3</li>
               </ul>
            </div>
        </div>
    );
}
  
export default CardTwitter;