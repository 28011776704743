import DsLayoutFullWidth from '../../layouts/DsLayoutFullWidth'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
import cardImage from '../../assets/test-images/card-test.jpg'

const FaoElearningDetailView = () => {
    return (
        <DsLayoutFullWidth>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Heading size="h1">Elearning detail</Heading>
                    </div>
                </div>
            </div>

            <RenderCode fullWidth={true}>
                <div className="container detail-elearning">
                    <div className="row">
                        <div className="col-lg-4">
                            <img className="img-fluid" src={cardImage} alt="img" />
                        </div>

                        <div className="col-lg-8">
                            <h1 className="detail-title mt-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</h1>
                            <p className="date">06/2021 <span className="badge bg-secondary bg-orange">New</span></p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            <p className="list-elearning-links">
                                <a href="#!" className="btn btn-link btn-icon">Enroll<i className="bi bi-pencil-square"></i></a>
                            </p>
                                    
                        </div>
                    </div>
                </div>
            </RenderCode>            
            
        </DsLayoutFullWidth>
    );
}

export default FaoElearningDetailView;
