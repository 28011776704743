import { Date } from '../typo/Typo'
import {TagsList} from "../FaoComponents";


const ListEvent = props => {
    return (
        <div className="d-list d-list-event">
            <div className="d-list-visual">
                <div className="card-calendar">
                    <div className="row-calendar">
                        <p className="date"><span class="date__day">22</span>/<span class="date__month">5</span></p>
                        <p className="year">2021</p>
                    </div>
                    { props.fromTo ? fromTo() : '' }
                </div>
            </div>
            <div className="d-list-content">
                <h5 className="title-link">
                    <a href="#!">{ props.title || 'Lorem ipsum dolor sit amet' }</a>
                </h5>
                <Date><i className="bi bi-geo-alt-fill"></i>ROME (ITALY), 26/11/2020 - 25/11/2022</Date>
                <p>{ props.text || 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque autem corrupti consequuntur tempore. Numquam, facere? Natus nisi aliquid a cumque, incidunt deleniti atque quibusdam adipisci recusandae repellendus labore. Neque, placeat.' }</p>
                <p className="category-list"><span className="category-list-title">Categories:</span> <a href="#!">Lorem ipsum</a>, <a href="#!">Cumque autem</a>, <a href="#!">Neque</a></p>
                <TagsList />
            </div>
        </div>
    );
}

const fromTo = () => {
    return(
        <>
            <div className="from-to-divider"></div>
            <div className="row-calendar">
                <p className="date"><span class="date__day">26</span>/<span class="date__month">5</span></p>
                <p className="year">2022</p>
            </div>
        </>
    );
}
  
export default ListEvent;
