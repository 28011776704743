import DsLayoutFullWidth from '../../layouts/DsLayoutFullWidth'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
import { TagsList, ListVideo } from '../../components/fao/FaoComponents'
// import mediaImage from '../../assets/test-images/card-test.jpg'

const FaoVideoDetailView = () => {
    return (
        <DsLayoutFullWidth>
            
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Heading size="h1">Video detail</Heading>
                    </div>
                </div>
            </div>

            <RenderCode fullWidth={true}>
                <div className="bg-gray-dark text-white pt-4 border-top border-white">
                    <div className="container detail-video">
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="detail-title mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h1>
                                
                                <div className="ratio ratio-16x9 mb-4">
                                    <iframe src="https://www.youtube.com/embed/WeoIsjYBQH0" title="FAO Policy Series: Sustainable Food and Agriculture" allowFullScreen></iframe>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="detail__body mb-4">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>    
                                            <p className="date text-white">26/11/2020, Rome (Italy)</p>
                                            <TagsList title />
                                        </div>
                                        <h5 className="sub-title small text-white">Related Videos</h5>
                                        <div className="row detail-video__rvideos pb-5">
                                            <div className="col-lg-6 border-right border-white">
                                                <ListVideo />
                                            </div>
                                            <div className="col-lg-6">
                                                <ListVideo />
                                            </div>
                                            <div className="col-lg-6 border-right border-white">
                                                <ListVideo />
                                            </div>
                                            <div className="col-lg-6">
                                                <ListVideo />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </RenderCode>          
        </DsLayoutFullWidth>
    );
}

export default FaoVideoDetailView;