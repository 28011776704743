

export const AccordionWrap = props => {
    return (
        <div className={`accordion ${props.className ? " " + props.className : ""}`} id={`accordionExample${props.id}`}>
            { props.children }
        </div>
    );
}
  
export default AccordionWrap;