import React from 'react';
import bannerImage from '../../assets/test-images/card-3.jpg'

const HeroBanner = props => {
    return (
        <React.Fragment>
            <div className={`hero-banner ${props.photoData ? 'mb-3' : ''}`} style={{backgroundImage: `url(${bannerImage})`}}>
                <div className="hero-caption">
                    <h6 className="title-category">{!props.blog ? 'News' : 'Blog'}</h6>
                    <h5 className="title-link"><a href="#!">Lorem ipsum dolor sit amet, consectetur adipiscing elit</a></h5>
                    {renderDate(props)}
                </div>
                
            </div>
            {renderPhotoData(props)}
        </React.Fragment>
    );
}

const renderPhotoData = pr => {
    if (pr.photoData) {
        return(
            <div className="row">
                <div className="col-md-9">
                    <p className="small text-color-gray-medium">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
                <div className="col-md-3 text-right">
                    <p className="small text-color-gray-medium">©FAO/Lorem Ipsum</p>
                </div>
            </div>
        );
    }
}

const renderDate = pr => {
    if (pr.blog) {
        return(
            <h6 className="date">By <a className="blog-author" href="#!">Lorem Ipsum</a> - 26/11/2020</h6>
        );
    } else {
        return(
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        );
    }
}
  
export default HeroBanner;