import DsLayoutFullWidth from '../../layouts/DsLayoutFullWidth'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
import bannerImage from '../../assets/test-images/card-test.jpg'
import publImage from '../../assets/test-images/publication-1.jpg'
import cardImage from '../../assets/test-images/card-test.jpg'


const FaoNewsDetailView = () => {
    return (
        <DsLayoutFullWidth>
        
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Heading size="h1">News detail</Heading>
                    </div>
                </div>
            </div>

            <RenderCode fullWidth={true}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="detail-title mt-4 mb-3">Lorem ipsum dolor sit amet</h1>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. At commodi quibusdam, dignissimos magnam ipsa repellat veritatis dolorum error voluptatibus, fuga accusantium ex voluptatum. Ex quas reprehenderit a obcaecati, nostrum nisi</p>
                            <img className="image-widget img-responsive img-hero mb-3" src={bannerImage} title="News Banner" alt="News Banner" />
                            <div className="row mb-3">
                                <div className="col-md-9">
                                    <p className="small text-color-gray-medium">Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                                </div>
                                <div className="col-md-3 text-right">
                                    <p className="small text-color-gray-medium">©FAO/Lorem ipsum</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="news-detail mb-3">
                                        <span className="news-detail__date">23/03/2021</span>,
                                        <span className="news-detail__location">Rome</span>
                                    </div>
                                    <div className="news-detail__body"><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A optio illo nihil sunt quidem dolorum eum nesciunt minus delectus provident? Soluta, tempore reprehenderit error quos veniam molestias quae neque natus</p></div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h5 className="sub-title small">More this topic</h5>
                                            <ul className="simple-list list-unstyled">
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        <i className="bi bi-globe"></i>
                                                        <span>Lorem ipsum dolor sit amet consectetur</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        <i className="bi bi-globe"></i>
                                                        <span>Lorem ipsum dolor sit</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" title="Placeholder link title" className="link-icon">
                                                        <i className="bi bi-globe"></i>
                                                        <span>Lorem ipsum dolor sit amet consectetur</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-6">
                                            <h5 className="sub-title small">Report</h5>
                                            <div className="card card-horizontal bg-primary-light">
                                                <div className="row g-0 align-items-center">
                                                    <div className="col-md-4">
                                                        <div className="card-image p-4">
                                                            <img src={publImage} className="img-fluid" alt="card alt" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <div className="card-body">
                                                            <h5 className="card-title">Card title</h5>
                                                            <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                                            <a href="#!" title="Placeholder link title" className="link-icon">
                                                                Internal Link Example
                                                                <i className="bi bi-chevron-right"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="card card-overlay">
                                                <div className="card-image">
                                                    <img src={cardImage} className="img-fluid" alt="..." />
                                                </div>
                                                <div className="card-img-overlay d-flex flex-column justify-content-center">
                                                    <h6 className="title-category">News</h6>
                                                    <h5 className="card-title mb-3">Lorem ipsum dolor sit amet consectetur</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="card card-overlay">
                                                <div className="card-image">
                                                    <img src={cardImage} className="img-fluid" alt="..." />
                                                </div>
                                                <div className="card-img-overlay d-flex flex-column justify-content-center">
                                                    <h6 className="title-category">THEMATIC PAGE</h6>
                                                    <h5 className="card-title mb-3">Lorem ipsum dolor sit</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card text-color-default bg-primary-light">
                                        <div className="card-body">
                                            <h5>Contact</h5>
                                            <p>
                                                <b>FAO</b> <br />
                                                Lorem ipsum<br />
                                                Lorem ipsum dolor sit amet consectetur<br />
                                                <a href="#!">+39 XX XXX XX XXX</a><br />
                                                <a href="#!">lorem.ipsum@fao.org</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </RenderCode>
            
            
        </DsLayoutFullWidth>
    );
}

export default FaoNewsDetailView;
