import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
import { TagsList } from '../../components/fao/FaoComponents'

const TagsListView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Tags List</Heading>
            <RenderCode withBorders={true}>
                <TagsList />
            </RenderCode>
        </DsLayout>
    );
}

export default TagsListView;
