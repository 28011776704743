import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
import { ListBlog } from '../../components/fao/FaoComponents'

const FaoBlogListView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Blog list</Heading>
            <RenderCode>
                <ListBlog />
            </RenderCode>

            

        </DsLayout>
    );
}

export default FaoBlogListView;
