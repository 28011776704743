import React from 'react'
import {
    Header,
    SubSiteNavbar,
    Card,
    CardOverlay,
    CardTwitter,
    Link,
    ContentBackground,
    Footer,
    ShareModal,
    EmailModal,
    Breadcrumb
} from '../../components/fao/FaoComponents'
import bannerImage from '../../assets/test-images/one-health-banner.jpg'
import cardImage1 from '../../assets/test-images/card-test-2.jpeg'
// import cardImage2 from '../../assets/test-images/card-test-3.jpeg'
// import cardImage3 from '../../assets/test-images/card-test-4.jpeg'
import DsLayoutFullWidth from '../../layouts/DsLayoutFullWidth'
// import heroVideoPoster from '../../assets/test-images/poster.jpg'
// import heroVideo from '../../assets/videos/emergencies-banner.mp4'
// import publImage from '../../assets/test-images/publication-1.jpg'

const NewsDetailExampleView = () => {
    return (
        <DsLayoutFullWidth>
            <Header subsite /> 

            <div className="subheader">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="content-icons">

                                <Breadcrumb/>

                                <div className="content-icons-right">

                                    <a href="#!" type="button" className="d-inline-flex" data-bs-toggle="modal" data-bs-target="#emailModal">
                                        <i className="bi bi-envelope"></i>
                                        <span>Email</span>
                                    </a>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            
                            <h2 className="page-title">Title of the Platform site/page</h2>
                            <SubSiteNavbar />

                        </div>
                    </div>
                </div>
            </div>

            <main>
                <div className="container">
                    <div className="row"> 
                        <div className="col-md-12"> 
                            <div className="hero-banner" style={{backgroundImage: `url('${bannerImage}')`}}> 
                                <div className="hero-caption"> 
                                    <h5 className="title-link"> 
                                        <a href="!#">Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus eum delectus ratione alias</a> 
                                    </h5> 
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus eum delectus ratione alias, at qui autem deleniti aut odio dolorum corrupti maxime dolorem, iure porro possimus deserunt praesentium eaque velit.</p> 
                                </div> 
                            </div> 
                        </div> 
                    </div> 


                    <div className="row">
                        <div className="col-md-9">
                        <h5 className="sub-title">Subtitle / Section</h5>
                            <ContentBackground className="bg-primary-light">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                aboris nisi ut aliquip ex ea commodo consequat.</p>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                aboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                                dolor in reprehenderit in voluptate velit esse cillum dolore eu
                                fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                                sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                aboris nisi ut aliquip ex ea commodo consequat.</p>
                            </ContentBackground>
                            
                            <h5 className="sub-title">Latest</h5>


                            <div className="row">
                                <div className="col-lg-6">
                                    <Card hasImage hasDate hasTitle titleHasLink imageCustom={cardImage1} categoryName="Interactive story" />
                                </div>
                                <div className="col-lg-6">
                                    <Card hasImage hasDate hasTitle titleHasLink imageCustom={cardImage1} categoryName="Initiative" />
                                </div>
                                <div className="col-lg-6">
                                    <Card hasImage hasDate hasTitle titleHasLink imageCustom={cardImage1} categoryName="NEWS" />
                                </div>
                                <div className="col-lg-6">
                                    <Card hasImage hasDate hasTitle titleHasLink imageCustom={cardImage1} categoryName="Initiative" />
                                </div>
                            </div>

                            <h5 className="sub-title">In Focus</h5>

                            <div className="row">
                                <div className="col-lg-6">
                                    <CardOverlay caption="news"/>
                                </div>
                                <div className="col-lg-6">
                                    <CardOverlay caption="news"/>
                                </div>
                            </div>
                            
                        </div>
                        <div className="col-md-3">
                            <div className="sfContentBlock sf-Long-text">
                                <a href="#!" target="_blank" rel="noreferrer" className="-align-center d-block">
                                    <img src="https://www.fao.org/images/devschoolfoodlibraries/default-album/bmel-logo.svg" width="100%" alt="" style={{display: 'block', maxWidth: '160px', margin: '0 auto'}} />
                                </a>
                            </div>
                            <div className="sfContentBlock sf-Long-text">
                                <a href="!#" target="_blank" rel="noreferrer" className="-align-center d-block">
                                    <img src="https://www.fao.org/images/devschoolfoodlibraries/default-album/wfp-logo-standard-blue-en.png" width="100%" alt="" style={{display: 'block', maxWidth: '160px', margin: '0 auto'}} />
                                </a>
                            </div>
                            <div className="sfContentBlock sf-Long-text">
                                <a href="!#" target="_blank" rel="noreferrer" className="-align-center d-block">
                                    <img src="https://www.fao.org/images/devschoolfoodlibraries/default-album/smc-logo---blue_transparent.png" width="100%" alt="" style={{display: 'block', maxWidth: '160px', margin: '0 auto'}} />
                                </a>
                            </div>

                            <h4 className="sub-title">Related links</h4>
                                    
                            <Link links={['External Link Example', 'External Link Example', 'External Link Example', 'External Link Example']} external />

                            <CardTwitter/>

                            <ContentBackground className="bg-primary-light">
                                <h5>Contact</h5><p><a href="mailto:contact@fao.org">contact@fao.org</a></p>
                            </ContentBackground>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
            <ShareModal />
            <EmailModal />
        </DsLayoutFullWidth>
    );
}
  
export default NewsDetailExampleView;