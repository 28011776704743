import DsLayout from '../../layouts/DsLayout'
import {CardStatic, CardStaticNoBorder, CardStaticBackground, CardStaticVideo, CardStaticOverlay, CardHorizontal, CardTwitter} from '../../components/fao/FaoComponents'
import {Heading, RenderCode} from '../../components/design-system/DsComponents'
import cardImage from '../../assets/test-images/card-test-speeches.jpg'


const CardsView = () => {
    return (
        <DsLayout>
            <style>
                {`
                .card:not(.card-horizontal) { max-width: 18rem; }
                .card.card-overlay { max-width: 22rem; }
                `}
            </style>
            <Heading size="h1">Cards</Heading>
            <p>Below is an example of a basic card with mixed content and a fixed width. Cards have no fixed width to
                start, so they’ll naturally fill the full width of its parent element.</p>
            <p>For this type of card is suggested to use an image size of 1920x1440px or 1920x1080px</p>

            <Heading size="h3">Default</Heading>
            <RenderCode>
                <CardStatic 
                    image={cardImage} 
                    category="category"
                    title="Lorem ipsum dolor sit amet" 
                    date="26/06/2020" 
                    body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor." 
                />
            </RenderCode>
            
            <Heading size="h3">Default no border</Heading>
            <RenderCode>
                <CardStaticNoBorder 
                    image={cardImage} 
                    category="category"
                    title="Lorem ipsum dolor sit amet" 
                    date="26/06/2020" 
                    body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor." 
                />
            </RenderCode>
            
            <Heading size="h3">Default background gray</Heading>
            <RenderCode>
                <CardStaticBackground 
                    image={cardImage} 
                    category="category"
                    title="Lorem ipsum dolor sit amet" 
                    date="26/06/2020" 
                    body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor." 
                />
            </RenderCode>

            <Heading size="h3">Default background dark gray</Heading>
            <RenderCode>
                <CardStaticBackground 
                    image={cardImage} 
                    category="category"
                    title="Lorem ipsum dolor sit amet" 
                    date="26/06/2020" 
                    body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor." 
                    darkGray
                />
            </RenderCode>
            
            <Heading size="h3">Default embed video</Heading>
            <RenderCode>
                <CardStaticVideo 
                    iframe="https://www.youtube.com/embed/WeoIsjYBQH0?controls=0" 
                    category="category"
                    title="Lorem ipsum dolor sit amet" 
                    date="26/06/2020" 
                    body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor." 
                />
            </RenderCode>

            <Heading size="h3">Horizontal background caption</Heading>
            <RenderCode>
                <CardHorizontal />
            </RenderCode>
            
            <Heading size="h3">Image overlay</Heading>
            <p>These elements are meant to be used in the <i>content</i> of a web page, not as a Hero banner. For full
                width components check the Hero banner section</p>
            <RenderCode>
                <CardStaticOverlay 
                    image={cardImage} 
                    category="category"
                    title="Lorem ipsum dolor sit amet" 
                    date="26/06/2020" 
                />
            </RenderCode>

            <Heading size="h3">Card Twitter</Heading>
            <RenderCode>
                <CardTwitter/>
            </RenderCode>

        </DsLayout>
    );
}

export default CardsView;