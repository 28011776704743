import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
import { ContentBackground } from '../../components/fao/FaoComponents'

const ContentBackgroundView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Content Background Widget</Heading>
            <p>It is possible to apply all background color classes present in the color page documentation</p>
            <p>In the example below we are using the <b>.bg-primary-light</b> class</p>

            <RenderCode>
                <ContentBackground className="bg-primary-light">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco
                    aboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                    dolor in reprehenderit in voluptate velit esse cillum dolore eu
                    fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                    sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                </ContentBackground>
            </RenderCode>
        </DsLayout>
    );
}

export default ContentBackgroundView;