import { Category, Date } from '../typo/Typo'
import { Image } from '../media/Media'


const CardEventOverlay = props => {
    return (
        <div className="card card-overlay type-event event-live">
            <Image ratio="3x2" src={props.image} alt={props.title} className="card-image" />
            <div className="card-img-overlay d-flex flex-column justify-content-center">
                <h6 className="card-event-live">Live</h6>
                <Category>Event</Category>
                <h5 className="card-title">
                    <a href="#!" className="title-link stretched-link">{props.title}</a>
                </h5>
                <Date>{props.date}</Date>
            </div>
        </div>
    );
}
  
export default CardEventOverlay;