import DsLayout from '../../layouts/DsLayout';
import { Heading, RenderCode } from '../../components/design-system/DsComponents';

const TabbedContentView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Tabbed Content</Heading>

            <RenderCode>
                <div className="tabbed-content">
                    <ul className="nav nav-tabs" id="tabs-1" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" id="tab_1-1" data-bs-toggle="tab" href="#tab-panel_1-1" role="tab" aria-selected>Tab title 1</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link " id="tab_2-1" data-bs-toggle="tab" href="#tab-panel_2-1" role="tab">Tab title 2</a>
                        </li>
                    </ul>
                    <div className="tab-content pt-3" id="tab-content-1">
                        <div className="tab-pane fade show active" id="tab-panel_1-1" role="tabpanel">...</div>
                        <div className="tab-pane fade" id="tab-panel_2-1" role="tabpanel">...</div>
                    </div>
                </div>
            </RenderCode>
            
        </DsLayout>
    );
}

export default TabbedContentView;
