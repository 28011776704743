import DsLayout from '../../layouts/DsLayout'
import {Heading} from '../../components/design-system/DsComponents'
import { ShowCode } from '../../components/design-system/DsComponents'
import {ContentBackground} from '../../components/fao/FaoComponents'


const FaoLogoView = () => {
    return (
        <DsLayout>
            <Heading size="h1">FAO logo</Heading>


            <p className="text-danger"><b>The FAO logo is the corporate identifier and official seal of the
                Organization, and it is protected under international treaties and
                conventions on intellectual property. Its use is highly restricted and
                is prohibited without prior permission.</b></p>
            <br/>


            <ContentBackground className="bg-primary-light p-3">
                <h3>Use of FAO logo</h3>


                <p>Requests for use of the FAO logo
                    for any reason whatsoever, whether or not in association with other logos,
                    must be addressed to <a href="mailto:logo@fao.org">logo@fao.org</a>. If permission for use of the
                    FAO logo
                    is granted, instructions for its placement and display, including related
                    disclaimers, must be strictly adhered to. The use and reproduction of
                    content containing the FAO logo must not be in association with
                    advertising or with the names of proprietary products and must not imply
                    FAO's endorsement of products or services in any way. FAO reserves the
                    right to request the immediate removal of its logo from unauthorized
                    websites or products.
                </p>
                {/* <a className="btn btn-primary" href="mailto:logo@fao.org">Request logo</a> */}
            </ContentBackground>


            <p>The FAO logo is a key element in communicating a common message and presenting FAO as one unified
                organization.
            </p>

            <br/><br/>

            <div className="fao-logo-container">
                <img
                    src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-blue-3lines-en.svg"
                    alt="Fao logo EN" className="ms-2" style={{height: '85px'}}/>
            </div>
            <br/><br/>

            <p>The FAO logo shows a grain of wheat icon and the words ‘Fiat Panis’ (Latin for ‘Let there be bread’)
                enclosed in a circle, which represent FAO’s mission – to eradicate hunger and promote food security
                worldwide –the letters ‘FAO’ form an acronym, derived from the UN Agency’s name in English, the ‘Food
                and Agriculture Organization of the United Nations.</p>

            {/* <p>You can consult the policies here: </p>
            <p><a href="https://intranet.fao.org/fileadmin/user_upload/occ/FAO-logo-policy-guidelines.pdf"
                  title="Placeholder link title" className="link-icon" target="_blank" rel="noreferrer">
                <i className="bi bi-globe"></i>
                <span>FAO logo policy and guidelines</span>
            </a></p> */}


            <Heading size="h3">Usage</Heading>
            <p>No element of the registered FAO logo can be
                used on its own.</p>

            <p>The FAO logo (or emblem) and wordmark (full
                name of the Organization) should always appear
                together to form the FAO logo block.
            </p>

            <p>The minimum sizes for the logo are 40px for desktop and 35px for mobile. The sizes are referred to the
                height of the logo.</p>

            <br/>


            <div className="tags-list title-category">
                <span className="badge bg-secondary">Desktop</span>
            </div>
            <br/>
            <div className="fao-logo-container ms-5">
                <img
                    src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-blue-3lines-en.svg"
                    alt="Fao logo EN" className="ms-2" style={{height: '40px'}}/>
                <div className="ref-bar"><span>40px</span></div>
            </div>

            <br/><br/>
            <div className="tags-list title-category">
                <span className="badge bg-secondary">Mobile</span>
            </div>
            <br/>
            <div className="fao-logo-container ms-5">
                <img
                    src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-blue-3lines-en.svg"
                    alt="Fao logo EN" className="ms-2" style={{height: '35px'}}/>
                <div className="ref-bar"><span>35px</span></div>
            </div>


            <Heading size="h1">FAO Logo archive</Heading>
            <p>The FAO logo block may be used in its two
                variants, with text on three lines and with text on two lines.</p>

            <p>Below are the language versions in Arabic, Chinese, English, French, Russian and Spanish for both
                cases.</p>

            <p>All these files are stored in the FAO Sitefinity corporate library.</p>


            <Heading size="h3">Logo 3 lines blue</Heading>

            <div className="fao-logo-container">
                <div className="render-code">
                    <div className="render-code-element">
                        <img
                            src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-blue-3lines-ar.svg"
                            alt="Fao logo AR"/>
                        <img
                            src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-blue-3lines-zh.svg"
                            alt="Fao logo ZH"/>
                        <img
                            src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-blue-3lines-en.svg"
                            alt="Fao logo EN"/>
                        <img
                            src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-blue-3lines-fr.svg"
                            alt="Fao logo FR"/>
                        <img
                            src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-blue-3lines-ru.svg"
                            alt="Fao logo RU"/>
                        <img
                            src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-blue-3lines-es.svg"
                            alt="Fao logo ES"/>
                    </div>
                </div>

                <ShowCode skipStripImgSrc={true} withBorders={true}>

                    <img
                        src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-blue-3lines-ar.svg"
                        alt="Fao logo AR"/>
                    <img
                        src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-blue-3lines-zh.svg"
                        alt="Fao logo ZH"/>
                    <img
                        src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-blue-3lines-en.svg"
                        alt="Fao logo EN"/>
                    <img
                        src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-blue-3lines-fr.svg"
                        alt="Fao logo FR"/>
                    <img
                        src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-blue-3lines-ru.svg"
                        alt="Fao logo RU"/>
                    <img
                        src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-blue-3lines-es.svg"
                        alt="Fao logo ES"/>
                </ShowCode>
            </div>

            <Heading size="h3">Logo 3 lines black</Heading>

            <div className="fao-logo-container">

                <div className="render-code">
                    <div className="render-code-element">
                        <img
                            src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-black-3lines-ar.svg"
                            alt="Fao logo AR"/>
                        <img
                            src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-black-3lines-zh.svg"
                            alt="Fao logo ZH"/>
                        <img
                            src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-black-3lines-en.svg"
                            alt="Fao logo EN"/>
                        <img
                            src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-black-3lines-fr.svg"
                            alt="Fao logo FR"/>
                        <img
                            src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-black-3lines-ru.svg"
                            alt="Fao logo RU"/>
                        <img
                            src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-black-3lines-es.svg"
                            alt="Fao logo ES"/>
                    </div> 
                </div>

                
                <ShowCode skipStripImgSrc={true} withBorders={true}>

                    <img
                        src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-black-3lines-ar.svg"
                        alt="Fao logo AR"/>
                    <img
                        src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-black-3lines-zh.svg"
                        alt="Fao logo ZH"/>
                    <img
                        src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-black-3lines-en.svg"
                        alt="Fao logo EN"/>
                    <img
                        src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-black-3lines-fr.svg"
                        alt="Fao logo FR"/>
                    <img
                        src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-black-3lines-ru.svg"
                        alt="Fao logo RU"/>
                    <img
                        src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-black-3lines-es.svg"
                        alt="Fao logo ES"/>
                </ShowCode>
            </div>

            <Heading size="h3">Logo 3 lines white</Heading>

            <div className="fao-logo-container bg-gray-dark">
                <div className="render-code">
                        <div className="render-code-element">
                            <img
                                src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-white-3lines-ar.svg"
                                alt="Fao logo AR"/>
                            <img
                                src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-white-3lines-zh.svg"
                                alt="Fao logo ZH"/>
                            <img
                                src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-white-3lines-en.svg"
                                alt="Fao logo EN"/>
                            <img
                                src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-white-3lines-fr.svg"
                                alt="Fao logo FR"/>
                            <img
                                src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-white-3lines-ru.svg"
                                alt="Fao logo RU"/>
                            <img
                                src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-white-3lines-es.svg"
                                alt="Fao logo ES"/>
                        </div>
                </div>
            </div>

            <div className="fao-logo-container bg-gray-dark">
                <ShowCode skipStripImgSrc={true} withBorders={true}>

                    <img
                        src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-white-3lines-ar.svg"
                        alt="Fao logo AR"/>
                    <img
                        src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-white-3lines-zh.svg"
                        alt="Fao logo ZH"/>
                    <img
                        src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-white-3lines-en.svg"
                        alt="Fao logo EN"/>
                    <img
                        src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-white-3lines-fr.svg"
                        alt="Fao logo FR"/>
                    <img
                        src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-white-3lines-ru.svg"
                        alt="Fao logo RU"/>
                    <img
                        src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-white-3lines-es.svg"
                        alt="Fao logo ES"/>
                </ShowCode>
            </div>

            <Heading size="h3">Logo 2 lines blue</Heading>

            <div className="fao-logo-container overflow-hidden">
                <div className="render-code">
                    <div className="render-code-element">
                        <img
                            src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-blue-2lines-ar.svg"
                            alt="Fao logo AR"/>
                        <img
                            src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-blue-2lines-zh.svg"
                            alt="Fao logo ZH"/>
                        <img
                            src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-blue-2lines-en.svg"
                            alt="Fao logo EN"/>
                        <img
                            src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-blue-2lines-fr.svg"
                            alt="Fao logo FR"/>
                        <img
                            src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-blue-2lines-ru.svg"
                            alt="Fao logo RU"/>
                        <img
                            src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-blue-2lines-es.svg"
                            alt="Fao logo ES"/>
                    </div>
                </div>
            </div>

            <ShowCode skipStripImgSrc={true} withBorders={true}>
                <img
                    src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-blue-2lines-ar.svg"
                    alt="Fao logo AR"/>
                <img
                    src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-blue-2lines-zh.svg"
                    alt="Fao logo ZH"/>
                <img
                    src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-blue-2lines-en.svg"
                    alt="Fao logo EN"/>
                <img
                    src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-blue-2lines-fr.svg"
                    alt="Fao logo FR"/>
                <img
                    src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-blue-2lines-ru.svg"
                    alt="Fao logo RU"/>
                <img
                    src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-blue-2lines-es.svg"
                    alt="Fao logo ES"/>
            </ShowCode>

            <Heading size="h3">Logo 2 lines black</Heading>

            <div className="fao-logo-container overflow-hidden">

                <div className="render-code">
                    <div className="render-code-element">
                        <img
                            src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-black-2lines-ar.svg"
                            alt="Fao logo AR"/>
                        <img
                            src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-black-2lines-zh.svg"
                            alt="Fao logo ZH"/>
                        <img
                            src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-black-2lines-en.svg"
                            alt="Fao logo EN"/>
                        <img
                            src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-black-2lines-fr.svg"
                            alt="Fao logo FR"/>
                        <img
                            src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-black-2lines-ru.svg"
                            alt="Fao logo RU"/>
                        <img
                            src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-black-2lines-es.svg"
                            alt="Fao logo ES"/>
                    </div>
                </div>

                
            </div>

            <ShowCode skipStripImgSrc={true} withBorders={true}>
                <img
                    src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-black-2lines-ar.svg"
                    alt="Fao logo AR"/>
                <img
                    src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-black-2lines-zh.svg"
                    alt="Fao logo ZH"/>
                <img
                    src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-black-2lines-en.svg"
                    alt="Fao logo EN"/>
                <img
                    src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-black-2lines-fr.svg"
                    alt="Fao logo FR"/>
                <img
                    src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-black-2lines-ru.svg"
                    alt="Fao logo RU"/>
                <img
                    src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-black-2lines-es.svg"
                    alt="Fao logo ES"/>
            </ShowCode>

            <Heading size="h3">Logo 2 lines white</Heading>

            <div className="fao-logo-container bg-gray-dark overflow-hidden">
                <div className="render-code">
                    <div className="render-code-element">
                        <img
                            src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-white-2lines-ar.svg"
                            alt="Fao logo AR"/>
                        <img
                            src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-white-2lines-zh.svg"
                            alt="Fao logo ZH"/>
                        <img
                            src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-white-2lines-en.svg"
                            alt="Fao logo EN"/>
                        <img
                            src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-white-2lines-fr.svg"
                            alt="Fao logo FR"/>
                        <img
                            src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-white-2lines-ru.svg"
                            alt="Fao logo RU"/>
                        <img
                            src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-white-2lines-es.svg"
                            alt="Fao logo ES"/>
                    </div>
                </div>

                
            </div>

            <ShowCode skipStripImgSrc={true} withBorders={true}>

                <img
                    src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-white-2lines-ar.svg"
                    alt="Fao logo AR"/>
                <img
                    src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-white-2lines-zh.svg"
                    alt="Fao logo ZH"/>
                <img
                    src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-white-2lines-en.svg"
                    alt="Fao logo EN"/>
                <img
                    src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-white-2lines-fr.svg"
                    alt="Fao logo FR"/>
                <img
                    src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-white-2lines-ru.svg"
                    alt="Fao logo RU"/>
                <img
                    src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-white-2lines-es.svg"
                    alt="Fao logo ES"/>
            </ShowCode>


        </DsLayout>
    );
}

export default FaoLogoView;
