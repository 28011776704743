import { TagsList } from './FaoComponents'
// bg-primary-light 

const DynamicList = props => {
    return (
        <div className={`row dynamic-list-row ${props.publication ? 'list-publication p-3 mb-3 rounded' : ''}${props.blog ? ' list-blog' : ''}${props.news ? ' list-news' : ''}${props.event ? ' list-event' : ''}${props.article ? ' list-article' : ''}${props.elearning ? ' list-elearning' : ''}${props.speeches ? ' list-speeches' : ''}${props.story ? ' list-story' : ''}${props.photog ? ' list-photog' : ''}`}>
            
            <div className={props.news || props.event || props.blog ? 'col-md-3 col-lg-2' : 'col-md-2'}>
                { renderMedia(props) }
            </div>
            <div className={props.news || props.event || props.blog ? 'col-md-9 col-lg-10' : 'col-md-10'}>
                { props.blogLabel ? <h6 className="title-category pt-3 pt-md-0">{props.blogLabel}</h6> : '' }
                <h5 className={props.blogLabel ? 'title-link' : 'title-link pt-3 pt-md-0'}>
                    <a href="#!">Lorem ipsum dolor sit amet consectetur adipisicing elit.</a>
                </h5>
                {renderDate(props)}
                {renderText(props)}
                {renderTags(props)}
                {renderSdgs(props)}
            </div>
        </div>
    );
}

const renderMedia = props => {
    if (props.event) {
        return RenderCalendar(props.fromTo);
    } else if (props.article || props.elearning || props.speeches) {
        return;
    } else {
        return renderImage(props.image, props.publication);
    }
}

const renderImage = (image, isPubl) => {
    return(
        <div className={`dynamic-list-img${isPubl ? '' : ' ratio ratio-3x2'}`}>
            <img src={image} alt="card alt" />
        </div>
    );
}

const RenderCalendar = (fromTo) => {
    if (fromTo) {
        return(
            <div className="card-calendar">
              <div className="row-calendar">
                <p className="date">22/5</p>
                <p className="year">2021</p>
              </div>
              <div className="from-to-divider"></div>
              <div className="row-calendar">
                <p className="date">26/5</p>
                <p className="year">2022</p>
              </div>
            </div>
        );
    } else {
        return(
            <div className="card-calendar">
              <div className="row-calendar">
                <p className="date">22/5</p>
                <p className="year">2021</p>
              </div>
            </div>
        );
    }
    
}

const renderDate = pr => {
    if (pr.event) {
        return(
            <h6 className="date"><i className="bi bi-geo-alt-fill"></i>ROME (ITALY), 26/11/2020 - 25/11/2022</h6>
        );
    } else if (pr.blog) {
        return(
            <div className="dynamic-info text-color-gray-medium"><h6 className="blog-author d-inline-block"><a href="#!">Lorem Ipsum</a></h6> - <h6 className="date d-inline-block">26/11/2020</h6></div>
        );
    } else {
        return(
            <h6 className="date">26/11/2020</h6>
        );
    }
}

const renderText = pr => {
    if (pr.story) {
        return(
            <p className="title-category">BY LOREM IPSUM, ROLE</p>
        );
    } else {
        return (
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque autem corrupti consequuntur tempore. Numquam, facere? Natus nisi aliquid a cumque, incidunt deleniti atque quibusdam adipisci recusandae repellendus labore. Neque, placeat.</p>
        );
    } 
}

const renderTags = pr => {
    if (pr.blog || pr.story) {
        return(
            <TagsList title />
        );
    }
}

const renderSdgs = pr => {
    if (pr.story) {
        return(
            <div className="d-flex align-items-center">
                <span className="me-2 mb-2"><b>SDGs: </b></span>
                <span className="sdg-small sdg-3 me-2 mb-2"></span>
                <span className="sdg-small sdg-4 me-2 mb-2"></span>
                <span className="sdg-small sdg-5 me-2 mb-2"></span>
                <span className="sdg-small sdg-6 me-2 mb-2"></span>
            </div>
        );
    }
}

  
export default DynamicList;