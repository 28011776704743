import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
import identityTest from '../../assets/test-images/identity-test.svg'
import identityTestSolo from '../../assets/test-images/identity-test-solo.svg'
import headerBackground from '../../assets/test-images/card-3.jpg'

const CustomSubHeaderView = props => {
    return (
        <DsLayout>
            <Heading size="h1">Custom Sub Header</Heading>
            <p>The custom sub header is a customizable space where the user can play with graphic elements. The allowed
            customizations are on the foreground graphic elements and the color/image of the background.</p>
            <p>Use the right and left space to place the element/elements. The requested format is <b>SVG</b>, in order to
            maintain the maximum
            quality. The maximum width for the elements is 320px. This is to avoid image cut on mobile version. 320px is
            the limit of size for the Custom Sub Header, and 100px is the height, in line with the main FAO Header on top.</p>

            <Heading size="h3">Example</Heading>

            <RenderCode>
                <div className="custom-sub-header d-flex align-items-center justify-content-between flex-wrap">
                    <div className="custom-sub-left d-flex align-items-center">
                        <img src={identityTest} className="pop-out" alt="card alt" />
                    </div>
                    <div className="custom-sub-right d-flex align-items-center justify-content-end">
                        <img src={identityTestSolo} alt="card alt" />
                    </div>
                </div>
            </RenderCode>

            <Heading size="h3">Mobile limit</Heading>
            <div className="custom-sub-header d-flex align-items-center justify-content-between flex-wrap" style={{width: '320px', maxHeight: 'none', backgroundColor: '#D5DBB1'}}>
                <div className="ref-bar">
                    <span>320px</span>
                </div>
                <div className="custom-sub-left d-flex align-items-center">
                    <img src={identityTest} className="pop-out" alt="card alt" />
                </div>
                <div className="custom-sub-right d-flex align-items-center justify-content-end">
                    <img src={identityTestSolo} alt="card alt" />
                </div>
            </div>

            <Heading size="h3">Background image</Heading>
            <p>Is it possible to a place a background image. The image is set as "cover", so it will fill up the space proportionally, and it is centered.</p>

            <div className="custom-sub-header d-flex align-items-center justify-content-between flex-wrap" style={{backgroundImage: `url(${headerBackground})`}}>
                <div className="custom-sub-left d-flex align-items-center">
                    <img src={identityTest} className="pop-out" alt="card alt" />
                </div>
                <div className="custom-sub-right d-flex align-items-center justify-content-end">
                    <img src={identityTestSolo} className="pop-out" alt="card alt" />
                </div>
            </div>

            <Heading size="h3">Number of elements</Heading>
            <p>Is really important to be careful on the amount of elements placed in the two sides of the Custom Sub Header.
            By using more than one element per side, it is very easy to force the banner to have 2 rows of element, causing an
            unaesthetic display on mobile phones.</p>

            <div className="custom-sub-header d-flex align-items-center justify-content-between flex-wrap" style={{width: '320px', height: 'auto'}}>
                <div className="custom-sub-left d-flex align-items-center">
                    <img src={identityTest} className="pop-out" alt="card alt" />
                </div>
                <div className="custom-sub-right d-flex align-items-center justify-content-end">
                    <img src={identityTestSolo} alt="card alt" />
                    <img src={identityTestSolo} alt="card alt" />
                </div>
            </div>

            <Heading size="h3">Pop out class</Heading>
            <p>The "pop-out" class makes the element stand out from the background image/color. It adds a white background and a
                shadow to the element, that results more visibile.</p>

            <div className="d-flex">
                <div style={{marginRight: '15px'}}>
                    <div className="custom-sub-header d-flex align-items-center justify-content-between flex-wrap" style={{width: '100px', height: 'auto', overflow: 'hidden'}}>
                        <div className="custom-sub-left d-flex align-items-center">
                            <img src={identityTest} alt="card alt" />
                        </div>
                    </div>
                    <p className="text-center small">No "pop-out"</p>
                </div>

                <div>
                    <div className="custom-sub-header d-flex align-items-center justify-content-between flex-wrap" style={{width: '100px', height: 'auto', overflow: 'hidden'}}>
                        <div className="custom-sub-left d-flex align-items-center">
                            <img src={identityTest} className="pop-out" alt="card alt" />
                        </div>
                    </div>
                    <p className="text-center small">"pop-out"</p>
                </div>
            </div>

        </DsLayout>
    );
}

  
export default CustomSubHeaderView;