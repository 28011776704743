import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'


const PaginationView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Pagination</Heading>
            <p>For Sizing, Disable / Active State and Alignment please visit the <a href="https://getbootstrap.com/docs/5.2/components/pagination/" target="_blank" rel="noreferrer">Bootstrap v5 Pagination Component page</a></p>

            <RenderCode>
              <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-center">
                      <li className="page-item">
                      <a className="page-link" href="#!" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                      </a>
                      </li>
                      <li className="page-item"><a className="page-link" href="#!">1</a></li>
                      <li className="page-item active"><a className="page-link" href="#!">2</a></li>
                      <li className="page-item"><a className="page-link" href="#!">3</a></li>
                      <li className="page-item">
                      <a className="page-link" href="#!" aria-label="Next">
                          <span aria-hidden="true">&raquo;</span>
                      </a>
                      </li>
                  </ul>
              </nav>
            </RenderCode>

        </DsLayout>
    );
}

export default PaginationView;
