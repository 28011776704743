import React from "react";


const DynamicListResources = props => {
    return (
        <div
            className={`row dynamic-list-row list-resources${props.article ? ' list-article' : ''}${props.elearning ? ' list-elearning' : ''}${props.speeches ? ' list-speeches' : ''}${props.video ? ' list-video bg-gray-dark rounded' : ''}${props.audio ? ' list-audio bg-gray-dark' : ''}${props.photog ? ' list-photog bg-gray-dark' : ''}`}>
            {props.videoImage || props.audioImage || props.photog ? renderImage(props.image) : ''}
            {props.videoPlayer ? renderPlayerVideo() : ''}
            <div className={props.videoImage || props.audioImage || props.videoPlayer || props.photog ? 'col-md-8 col-lg-9' : 'col-12'}>
                <h5 className='title-link pt-3 pt-md-0'>
                    <a href="#!">{props.title}</a>
                </h5>
                {props.audioPlayer ? renderPlayerAudio(props.image) : ''}
                <h6 className={`date${props.video || props.audio ? ' text-color-gray-medium' : ''}`}>26/11/2020{props.speeches ? ' - ROME' : ''}</h6>
                <p><b>{renderType(props)} </b>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                {props.article ? renderArticleWebsite() : ''}
                {props.elearning ? renderElearningLinks() : ''}
                {props.video ? renderVideoLinks() : ''}
                {props.audio ? renderAudioLinks() : ''}
            </div>
        </div>
    );
}

function renderType(props) {
    let type;

    if (props.article) type = 'ARTICLE';
    if (props.elearning) type = 'E-LEARNING';
    if (props.speeches) type = 'SPEECHES';
    if (props.video) type = 'VIDEO';
    if (props.audio) type = 'AUDIO';

    return type;
}

function renderImage(image) {
    return (
        <div className="col-md-3 col-lg-2">
            <div className="list-image ratio ratio-3x2">
                <img src={image} alt="resource"/>
            </div>
        </div>
    );
}

function renderPlayerVideo() {
    return (
        <div className="col-md-4 col-lg-3">
            <div className="ratio ratio-3x2">
                <iframe src="https://www.youtube.com/embed/WeoIsjYBQH0?controls=0" title="iframe1" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen=""></iframe>
            </div>
        </div>
    );
}

function renderPlayerAudio() {
    return (

        <div className="list-iframe-audio my-2">
            <iframe title="Iframe title" width="100%" height="100%" scrolling="no" frameBorder="no" allow="autoplay"
                    src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1176941233&color=%23116aab&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
        </div>

    );
}


function renderArticleWebsite() {
    return (
        <p><b>WEBSITE: </b><a href="www.fao.org" target="_blank">FAO - Food and Agriculture Organization</a></p>
    );
}

function renderElearningLinks() {
    return (
        <React.Fragment>
            <p className="list-elearning-links">
                <a href="#!" className="btn btn-link btn-icon">Enroll<i className="bi bi-pencil-square"></i></a>
                <a href="#!" className="btn btn-link btn-icon">Learn more<i className="bi bi-gear-fill"></i></a>
            </p>
            {/*<p className="small text-color-gray-medium">Also available in <a href="#!">French</a> and <a href="#!">Spanish</a></p>*/}
        </React.Fragment>
    );
}

function renderVideoLinks() {
    return (
        <a href="#!" className="btn btn-link btn-icon text-color-gray-medium text-uppercase ps-0">
            Play Video
            <i className="bi bi-play-fill text-white"></i>
        </a>
    );
}

function renderAudioLinks() {
    return (
        <a href="#!" className="btn btn-link btn-icon text-color-gray-medium text-uppercase ps-0">
            Play Audio
            <i className="bi bi-volume-up-fill text-white"></i>
        </a>
    );
}


export default DynamicListResources;