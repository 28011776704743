import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'


const LayoutsView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Layouts</Heading>

            <Heading size="h3">Subsite - 2 column</Heading>
            <RenderCode>
                <header>
                    {/* <!-- Header at: https://frosty-carson-bf1e0d.netlify.app/header --> */}
                </header>
                <div className="subheader">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="page-title">Food System</h2>
                                {/* <!-- Subsite Navbar at: https://frosty-carson-bf1e0d.netlify.app/navbar --> */}
                            </div>
                        </div>
                    </div>
                </div>
                <main>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                ...
                            </div>
                            <div className="col-md-9">
                                ...
                            </div>
                            {/* <!-- Right Position --> */}
                            <div className="col-md-3">
                                ...
                            </div>
                        </div>
                    </div>
                </main>
                <footer>
                    {/* <!-- Footer at: https://frosty-carson-bf1e0d.netlify.app/footer --> */}
                </footer>
            </RenderCode>
            
            <Heading size="h3">Subsite - 1 column</Heading>
            <RenderCode>
                <header>
                    {/* <!-- Header at: https://frosty-carson-bf1e0d.netlify.app/header --> */}
                </header>
                <div className="subheader">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="page-title">Food System</h2>
                                {/* <!-- Subsite Navbar at: https://frosty-carson-bf1e0d.netlify.app/navbar --> */}
                            </div>
                        </div>
                    </div>
                </div>
                <main>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                ...
                            </div>
                        </div>
                    </div>
                </main>
                <footer>
                    {/* <!-- Footer at: https://frosty-carson-bf1e0d.netlify.app/footer --> */}
                </footer>
            </RenderCode>
            
        </DsLayout>
    );
}

export default LayoutsView;
