import facebook from '../../assets/social-icons/social-icon-facebook.svg'
import flickr from '../../assets/social-icons/social-icon-flickr.svg'
import instagram from '../../assets/social-icons/social-icon-instagram.svg'
import linkedin from '../../assets/social-icons/social-icon-linkedin.svg'
import rss from '../../assets/social-icons/social-icon-rss.svg'
import slideshare from '../../assets/social-icons/social-icon-slideshare.svg'
import soundcloud from '../../assets/social-icons/social-icon-facebook.svg'
import tiktok from '../../assets/social-icons/social-icon-tiktok.svg'
import tuotiao from '../../assets/social-icons/social-icon-tuotiao.svg'
import twitter from '../../assets/social-icons/social-icon-twitter.svg'
import wechat from '../../assets/social-icons/social-icon-wechat.svg'
import weibo from '../../assets/social-icons/social-icon-weibo.svg'
import youtube from '../../assets/social-icons/social-icon-youtube.svg'


const ShareModal = props => {
    return (
        <div className={`modal modal-share fade ${props.className}`} id="shareModal" tabIndex="-1" aria-labelledby="shareModalLabel" aria-hidden="true" style={props.style}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="shareModalLabel">Share</h5>
                        <button type="button" className="btn btn-secondary btn-icon btn-round close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <ul className="list-inline social-icons">
                            <li className="list-inline-item">
                                <a href="#!" alt="facebook" title="facebook" target="_blank">
                                    <img src={facebook} alt="facebook" />
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="#!" alt="flickr" title="flickr" target="_blank">
                                    <img src={flickr} alt="flickr" />
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="#!" alt="instagram" title="instagram" target="_blank">
                                    <img src={instagram} alt="instagram" />
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="#!" alt="linkedin" title="linkedin" target="_blank">
                                    <img src={linkedin} alt="linkedin" />
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="#!" alt="rss" title="rss" target="_blank">
                                    <img src={rss} alt="rss" />
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="#!" alt="slideshare" title="slideshare" target="_blank">
                                    <img src={slideshare} alt="slideshare" />
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="#!" alt="soundcloud" title="soundcloud" target="_blank">
                                    <img src={soundcloud} alt="soundcloud" />
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="#!" alt="tiktok" title="tiktok" target="_blank">
                                    <img src={tiktok} alt="tiktok" />
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="#!" alt="tuotiao" title="tuotiao" target="_blank">
                                    <img src={tuotiao} alt="tuotiao" />
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="#!" alt="twitter" title="twitter" target="_blank">
                                    <img src={twitter} alt="twitter" />
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="#!" alt="wechat" title="wechat" target="_blank">
                                    <img src={wechat} alt="wechat" />
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="#!" alt="weibo" title="weibo" target="_blank">
                                    <img src={weibo} alt="weibo" />
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="#!" alt="youtube" title="youtube" target="_blank">
                                    <img src={youtube} alt="youtube" />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
  
export default ShareModal;