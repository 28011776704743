

const Image = props => {
    return (
        <div className={`ratio ratio-${props.ratio} ${props.className ? props.className : ''}`}>
            <img src={props.src} alt={props.alt} />
        </div>
    );
}
  
export default Image;
