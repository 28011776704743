import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode, ShowCode } from '../../components/design-system/DsComponents'
import React, { useEffect } from "react";
import L from 'leaflet';
// import '../../scripts/ds-map';

const MapView = () => {

    useEffect(() => {
        const map = L.map(document.getElementById('yourMapId')).setView([0,0], 2);
        L.tileLayer('https://pro-ags1.dfs.un.org/arcgis/rest/services/basemaps/clearmap_webtopo_nolabel_cvw/MapServer/tile/{z}/{y}/{x}', {}).addTo(map);
        map.scrollWheelZoom.disable();
        map.on('focus', function() { map.scrollWheelZoom.enable(); });
        map.on('blur', function() { map.scrollWheelZoom.disable(); });

        const map2 = L.map(document.getElementById('yourMapId2')).setView([0,0], 3);
        L.tileLayer('https://pro-ags1.dfs.un.org/arcgis/rest/services/basemaps/clearmap_webtopo_nolabel_cvw/MapServer/tile/{z}/{y}/{x}', {}).addTo(map2);
        const bounds = L.latLngBounds([-89.98155760646617, -180], [89.99346179538875, 180]);
        map2.setMaxBounds(bounds);
        map2.scrollWheelZoom.disable();
        map2.on('drag', () => map.panInsideBounds(bounds, { animate: false }));
        map2.on('focus', function() { map.scrollWheelZoom.enable(); });
        map2.on('blur', function() { map.scrollWheelZoom.disable(); });

        map2.on("click", function (event) {
            console.log('startcoordx : ' + event.latlng.lat);
            console.log('startcoordy : ' + event.latlng.lng);
            console.log('current zoom: ' + map2.getZoom())
            L.marker(event.latlng).addTo(map2);
          });

        // const map3 = L.map(document.getElementById('yourMapId3')).setView([0,0], 2);
        // L.tileLayer('https://pro-ags1.dfs.un.org/arcgis/rest/services/basemaps/clearmap_webtopo_nolabel_cvw/MapServer/tile/{z}/{y}/{x}', {}).addTo(map3);
        // map3.scrollWheelZoom.disable();
        // map3.on('focus', function() { map3.scrollWheelZoom.enable(); });
        // map3.on('blur', function() { map3.scrollWheelZoom.disable(); });

        const data = [
            {
                lat: 42,
                lon: 12,
                title: 'Title 1',
                content: 'Content 1'
            },
            {
                lat: 12,
                lon: -80,
                title: 'Title 2',
                content: 'Content 2'
            },
        ]


        const tooltip = document.querySelector('.ds-map-tooltip');
        const tooltipContent = document.querySelector('.ds-map-tooltip-content');
        const tooltipCloseBtn = document.querySelector('.ds-map-tooltip-close');
        const tooltipTitle = document.querySelector('.ds-map-tooltip-title');

        let pinIsActive = false;

        const map3 = L.map('yourMapId3', {
            center: [0, 0],
            zoom: 2,
            zoomControl: false
        });

        L.control.zoom({
            position: 'bottomright'
        }).addTo(map3);

        L.tileLayer('https://pro-ags1.dfs.un.org/arcgis/rest/services/basemaps/clearmap_webtopo_nolabel_cvw/MapServer/tile/{z}/{y}/{x}', {}).addTo(map3);
            
        data.forEach(function(item) {
            L.marker([item.lat, item.lon])
            .on('click', e => !pinIsActive ? openTooltip(e, item) : closeToolip())
            .addTo(map3);
        });

        tooltipCloseBtn.addEventListener('click', closeToolip);

        function openTooltip(e, item) {
            pinIsActive = true;

            // On click center marker in map
            map3.setView([e.latlng.lat, e.latlng.lng], 4, {animate: false});
            // Get map width and divide it by 4
            const mapQuarterOfWith = Math.round(map3.getSize().x / 4);
            // On click move map to center the pin on the right side of the tooltip
            map3.panBy([-mapQuarterOfWith, 0], {animate: false});

            tooltip.classList.add('active');
            // Add content to the tooltip
            tooltipTitle.innerText = item.title;
            tooltipContent.innerHTML = item.content;
        }

        function closeToolip() {
            pinIsActive = false;
            tooltip.classList.remove('active');
            map3.setZoom(2, {animate: false});
            // map3.setView([e.latlng.lat, e.latlng.lng], 2, {animate: false});
        }

    }, []);

    return (
        <DsLayout>
            <Heading size="h1">Maps</Heading>

            <p>All maps should follow the maps available on the website of the UN . In particular, the delineation used by the UN for the representation of international borders must be followed. 
                This standard map uses the correct UN layers and country names, as derived from FAO NOCS <a href="https://www.fao.org/nocs/en">https://www.fao.org/nocs/en</a>https://www.fao.org/nocs/en. 
                Additional OCC guidance is available here ( <a href="https://unfao.sharepoint.com/sites/OCCP/UN%20Maps/Forms/AllItems.aspx">https://unfao.sharepoint.com/sites/OCCP/UN%20Maps/Forms/AllItems.aspx</a> ) as well as the 
                Administrative Circular ( <a href="https://intranet.fao.org/fileadmin/user_upload/FAO_Communications/ac/AC%202021-13_EN_v2.pdf">https://intranet.fao.org/fileadmin/user_upload/FAO_Communications/ac/AC%202021-13_EN_v2.pdf</a> ).</p>

            <Heading size="h3">Simple map with UN Layer - CDN</Heading>
            <p>Please refer to <a href="https://leafletjs.com/download.html" target='_blank' rel="noreferrer">Leaflet documentation</a> to get the latest CDNs with the integrity hashes.</p>
            <RenderCode>
                <link rel="stylesheet" href="https://unpkg.com/leaflet@1.8.0/dist/leaflet.css" />
                <script src="https://unpkg.com/leaflet@1.8.0/dist/leaflet.js" />
                <div className='ratio ratio-21x9' id="yourMapId"></div>
            </RenderCode>
            <ShowCode language="js">{handleJsStr(false)}</ShowCode>

            <Heading size="h3">Simple map with UN Layer - with package managers and world boundaries</Heading>
            <p>If you use the npm/yarn package managers, you can fetch a local copy of Leaflet by running: <code>npm i leaflet</code> or <code>yarn add leaflet</code>.</p>
            <p>Extra: click on this map to get the current coordinates and zoom values in the dev tools console and place a marker</p>
            <RenderCode>
                <div className='ratio ratio-21x9' id="yourMapId2"></div>
            </RenderCode>
            <ShowCode language="js">{handleJsStr(true, 2, true)}</ShowCode>


            <Heading size="h3">Simple map with sidebar</Heading>

            <div className="ds-map">
                <div style={{width: '100%', height: '600px'}} id="yourMapId3"></div>
                <div className="ds-map-tooltip">
                    <div className="ds-map-tooltip-header">
                        
                        <h5 className="ds-map-tooltip-title">title</h5>
                        <button className="ds-map-tooltip-close btn btn-secondary btn-icon btn-round">
                            <i className="bi bi-x"></i>
                        </button>
                    </div>
                    
                    <div className="ds-map-tooltip-content">
                        {/* content */}
                    </div>
                </div>
            </div>



            

            {/* <RenderCode>
                
            </RenderCode> */}

          
        </DsLayout>
    );
}

export default MapView;

function handleJsStr(showJSImports = true, id = "", boundaries = false) {
    const baseStr = 
`const element = document.getElementById('yourMapId${id}');
const layerUrl ='https://pro-ags1.dfs.un.org/arcgis/rest/services/basemaps/clearmap_webtopo_nolabel_cvw/MapServer/tile/{z}/{y}/{x}';
const map = L.map(element).setView([0,0], 3);
L.tileLayer(layerUrl, {...yourMapOptionsHere}).addTo(map);`;

    const JSImportsStr =
`import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
`;

    const boundariesStr = `
// RECOMMENDED: set world boundaries for the map
const bounds = L.latLngBounds(L.latLng([-89.98155760646617, -180]), L.latLng([89.99346179538875, 180]));
map.setMaxBounds(bounds);
map.on('drag', function () {map.panInsideBounds(bounds, { animate: false })});`;

    return `${showJSImports ? JSImportsStr : ''}
${baseStr}
${boundaries ? boundariesStr : ''}`;

}

