import {Button} from "./FaoComponents";


const DynamicSearch = () => {
    return (
        <div className="dynamic-search">
            <div className="input-main-search input-group">
                <input type="text" placeholder="Search" aria-label="Search" className="form-control" />
                <div className="input-group-append">
                    <button type="button" className="btn btn-primary btn-icon">
                        <i className="bi bi-search"></i>
                    </button>
                </div>
            </div>

            <div className="accordion dynamic-search-collapse" id="dynamicSearchAccordion">
                <div className="dynamic-search-header" id="dynamicSearchCollapse">
                    <button className="btn btn-secondary btn-icon btn-adv-search collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dynamicSearchBody" aria-expanded="true" aria-controls="dynamicSearchBody">
                        Advanced Search
                        <i className="bi bi-chevron-down"></i>
                    </button>
                </div>

                <div id="dynamicSearchBody" className="dynamic-search-body collapse" aria-labelledby="dynamicSearchCollapse" data-bs-parent="#dynamicSearchAccordion">
                    <form>
                        <div className="row">
                            <div className="col-md-6 mb-4">
                                <label className="form-label">Date from</label>
                                <input type="date" className="form-control" />
                            </div>

                            <div className="col-md-6">
                                <label className="form-label">Date to</label>
                                <input type="date" className="form-control" />
                            </div>

                            <div className="col-md-4">
                                <select id="inputState1" className="form-control">
                                    <option defaultValue>Example</option>
                                    <option>...</option>
                                </select>
                            </div>

                            <div className="col-md-4">
                                <select id="inputState2" className="form-control">
                                    <option defaultValue>Example</option>
                                    <option>...</option>
                                </select>
                            </div>

                            <div className="col-md-4">
                                <select id="inputState3" className="form-control">
                                    <option defaultValue>Example</option>
                                    <option>...</option>
                                </select>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end mt-3">
                            <Button type="primary">Search</Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
  
export default DynamicSearch;