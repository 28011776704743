import { Link } from '../FaoComponents'
import { Category, Date } from '../typo/Typo'


const CardAudio = props => {
    return (
        <div className="card card-audio bg-gray-dark">
            <div className="ratio ratio-3x2">
                <iframe src={props.iframe} title={props.title}></iframe>
            </div>
            <div className="card-body">
                <Category>Audio</Category>
                <h5 className="card-title">
                    <a href="#!" className="title-link">{props.title}</a>
                </h5>
                <Date>{props.date}</Date>
                <p className="card-text">{props.body}</p>
                <Link links={['Internal Link Example']} internal iconRight />
            </div>
        </div>
    );
}
  
export default CardAudio;