import { Link } from '../FaoComponents'
import { Category, Date } from '../typo/Typo'


const CardVideo = props => {
    return (
        <div className="card bg-gray-dark">
            <div className="card-image ratio ratio-3x2">
                <iframe src={props.iframe} title={props.title} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
            <div className="card-body">
                <Category>Video</Category>
                <h5 className="card-title">
                    <a href="#!" className="title-link">{props.title}</a>
                </h5>
                <Date>{props.date}</Date>
                <p className="card-text">{props.body}</p>
                
                <Link links={['Internal Link Example']} internal iconRight />
            </div>
        </div>
    );
}
  
export default CardVideo;