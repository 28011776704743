import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
import { CardNews } from '../../components/fao/FaoComponents'
import cardImage from '../../assets/test-images/card-test-speeches.jpg'

const FaoNewsCardView = () => {
    return (
        <DsLayout>
            <style>{`.card { max-width: 18rem; }`}</style>
            <Heading size="h1">News card</Heading>
            <RenderCode>
                <CardNews 
                    image={cardImage} 
                    title="Lorem ipsum dolor sit amet" 
                    date="26/06/2020" 
                    body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor." 
                />
            </RenderCode>
        </DsLayout>
    );
}

export default FaoNewsCardView;
