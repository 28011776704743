import  UserContext  from '../../contexts/UserContext'
import { useContext, useState, useEffect } from 'react'


const RenderOnLogin = props => {
    const [auth, setAuth] = useState(false);
    const { user } = useContext(UserContext);

    useEffect(() => {
        setAuth(user);
    }, [user]);

    return auth ? <div>{ props.children }</div> : '';
}

export default RenderOnLogin;