import DsLayout from '../../layouts/DsLayout'
import { Heading } from '../../components/design-system/DsComponents'


const ReadingView = () => {
    return (
        <DsLayout>
        <Heading size="h1">Recommended Reading</Heading>
        <ul>
    <li>
        <a   rel="noreferrer" target="_blank" 
            href="https://intranet.fao.org/fileadmin/user_upload/FAO_Communications/documents/FAO_Comms_Guidelines_for_Disability_Inclusion.pdf"
        >
            FAO Communication Guidelines for Disability Inclusion
        </a>
    </li>
    <li>
        <a   rel="noreferrer" target="_blank" 
            href="https://github.com/UKHomeOffice/posters/blob/master/accessibility/dos-donts/posters_en-UK/accessibility-posters-set.pdf"
        >
            UK Government Accessibility Poster Set
        </a>
    </li>
    <li>
        <a   rel="noreferrer" target="_blank"  href="https://www.un.org/en/webaccessibility/index.shtml">
            UN Accessibility Guidelines for UN Websites
        </a>
    </li>
    <li>
        <a   rel="noreferrer" target="_blank" 
            href="https://developers.google.com/web/fundamentals/accessibility?utm_source=lighthouse&amp;utm_medium=lr"
        >
            Google Web Fundamentals on Accessibility
        </a>
    </li>
    <li>
    Practical Web Inclusion and Accessibility, Ashley Firth, 2019 Apress.
    </li>
    </ul>

        </DsLayout>
    );
}
  
export default ReadingView;
