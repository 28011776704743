import { Date, Category } from "../typo/Typo";
import listImage from "../../../assets/test-images/card-test.jpg";
import { TagsList } from "../FaoComponents";

const ListBlog = (props) => {
  return (
    <div className="d-list d-list-blog">
      <div className="d-list-visual ratio ratio-3x2">
        <img
          src={props.image || listImage}
          alt={props.title || "Lorem ipsum dolor sit amet"}
        />
      </div>
      <div className="d-list-content">
        <Category>Blog label</Category>
        <h5 className="title-link">
          <a href="#!">{props.title || "Lorem ipsum dolor sit amet"}</a>
        </h5>
        <Date>
          <span className="blog-author">
            <a href="#!">{props.author || "Lorem ipsum"}</a>
          </span>{" "}
          - {props.date || "26/06/2020"}
        </Date>
        <p>
          {props.text ||
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque autem corrupti consequuntur tempore. Numquam, facere? Natus nisi aliquid a cumque, incidunt deleniti atque quibusdam adipisci recusandae repellendus labore. Neque, placeat."}
        </p>
        <p className="category-list"><span className="category-list-title">Categories:</span> <a href="#!">Lorem ipsum</a>, <a href="#!">Cumque autem</a>, <a href="#!">Neque</a></p>
        <TagsList />
      </div>
    </div>
  );
};

export default ListBlog;
