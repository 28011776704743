import DsLayout from '../layouts/DsLayout'


const Route404 = () => {
    return (
        <DsLayout>
            <h1>404</h1>
        </DsLayout>
    );
}
  
export default Route404;