import React, { useEffect } from 'react';
import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode, ShowCode } from '../../components/design-system/DsComponents'
import { Timeline } from '@knight-lab/timelinejs';
import '@knight-lab/timelinejs/dist/css/timeline.css';

import mediaImage from '../../assets/test-images/card-test.jpg'

const timelineData = {
    "events": [
      {
        "media": {
          "url": mediaImage,
          "caption": "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
          "credit": "Credit: <a href='#!'>Author photo</a>"
        },
        "start_date": {
          "month": "8",
          "day": "9",
          "year": "1963"
        },
        "text": {
          "headline": "Lorem ipsum dolor sit amet 1",
          "text": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>"
        }
      },
      {
        "media": {
          "url": mediaImage,
          "caption": "Lorem ipsum dolor sit amet, consectetur adipiscing elit asd ",
          "credit": "Credit: <a href='#!'>Author photo</a>"
        },
        "start_date": {
          "month": "9",
          "day": "9",
          "year": "1964"
        },
        "text": {
          "headline": "Lorem ipsum dolor sit amet 2",
          "text": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>"
        }
      },
    ]
};


const TimelineView = () => {
    useEffect(() => {
        window.timeline = new Timeline('timeline-embed', timelineData);
    }, []);

    return (
        <DsLayout>
            <Heading size="h1">Timeline</Heading>

            <p>This component use <b><a href="https://github.com/NUKnightLab/TimelineJS3">TimelineJS3</a></b>.</p>
            <p>Please visit the offical <b><a href="https://github.com/NUKnightLab/TimelineJS3">TimelineJS3</a></b> documentation to see all the possible implementations.</p>

            <RenderCode withBorders={true}>
                <div id="timeline-embed"></div>
            </RenderCode>

            <ShowCode language="js">{jsString}</ShowCode>
            <ShowCode language="json">{jsonString}</ShowCode>
        </DsLayout>
    );
}

export default TimelineView;

const jsString = `
const timeline = new Timeline('timeline-embed', timelineData);
`;


const jsonString = `
{
    "events": [
      {
        "media": {
          "url": mediaImage,
          "caption": "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
          "credit": "Credit: <a href='#!'>Author photo</a>"
        },
        "start_date": {
          "month": "8",
          "day": "9",
          "year": "1963"
        },
        "text": {
          "headline": "Lorem ipsum dolor sit amet 1",
          "text": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>"
        }
      },
      {
        "media": {
          "url": mediaImage,
          "caption": "Lorem ipsum dolor sit amet, consectetur adipiscing elit asd ",
          "credit": "Credit: <a href='#!'>Author photo</a>"
        },
        "start_date": {
          "month": "9",
          "day": "9",
          "year": "1964"
        },
        "text": {
          "headline": "Lorem ipsum dolor sit amet 2",
          "text": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>"
        }
      },
    ]
};
`;

