

const DocCard = props => {
    return (
        <div className="--doc-card card mt-5">
            <div className="card-body">
                { props.children }
            </div>
        </div>
    );
}
  
export default DocCard;