// import DsLayout from '../layouts/DsLayout'
import { NavLink } from "react-router-dom";
import { Footer } from '../components/design-system/DsComponents'
// import Card from '../components/design-system/Card';
// import logoHome from '../assets/logo-intro.svg'
import gridRepeat from '../assets/grid-repeat.svg';
import gridLogo from '../assets/grid-logo.png';

import wnImage1 from '../assets/wn1.jpg';
import wnImage2 from '../assets/wn2.jpg';

//import homeVideo from '../assets/videos/fao-flag.mp4';
import SearchComponents from "../components/design-system/SearchComponents";
import {route_styles, route_components, route_content, route_accessibility, route_examples} from '../routes'


const Home = () => {
    const aRoutes = {
        styles: route_styles, components: route_components, content: route_content.flatMap((el) => [...el.children]),
        accessibility: route_accessibility, examples: route_examples
    }

    return (
        <div className="home-content">
            <div className="ds-grid-home mb-5">
                <div className="grid-repeat" style={{backgroundImage: `url("${gridRepeat}")`}}></div>

                {/*<video loop autoPlay muted playsInline preload="auto"*/}
                {/*       className="report-video"*/}
                {/*       poster="">*/}
                {/*    <source src={homeVideo} type="video/mp4"/>*/}
                {/*</video>*/}

                {/*<div className="--home__hero">*/}
                {/*    <h1 className="--home__title text-center">Design your service using FAO.ORG <br />styles, components and patterns.</h1>*/}
                {/*    <h4 className="--home__subtitle text-center">Use FAO end-to-end design language to create simple, intuitive and beautiful experiences.</h4>*/}
                {/*    /!* <p className="text-center py-4 bg-primary-light rounded mt-4 d-block">The CSS produced are dependent from <a href="https://getbootstrap.com/">Bootstrap 5</a></p> *!/*/}
                {/*</div>*/}
                <div className="container home-grid-content">
                    <div className="row">
                        <div className="grid-logo" style={{backgroundImage: `url("${gridLogo}")`}}></div>
                        <div className="col-lg-6 position-relative">
                            <h1>FAO Design System</h1>
                            <h4>Use FAO design language to create simple, intuitive and beautiful experiences.</h4>
                            <br/>
                            <NavLink className="btn btn-secondary me-4" to="/changelog">What's new</NavLink>
                            <NavLink className="btn btn-secondary me-4" to="/download">Download</NavLink>
                        </div>
                    </div>
                </div>

            </div>
            <div className="container --doc-content">
                <div className="row">
                    <div className="col-12">
                        

                        <SearchComponents allRoutes={computePaths(aRoutes)} modalId={1}/>

                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h2 className="text-center mb-4">About</h2>

                        <p className="text-center mb-4">The FAO web Design System is an essential resource for all Content Owners seeking to create world-class online experiences. This system provides a robust framework that enables users to develop simple, intuitive, elegant, aesthetically pleasing and user-friendly digital interfaces. The system's components have undergone rigorous testing, ensuring their compatibility with over 30 screen sizes, orientations, and language combinations. As a result, users can expect a fully adaptive digital experience that delivers a harmonized User eXperience (UX) across FAO.org. In addition, the system's components are designed to work seamlessly with one another, allowing users to build a cohesive online presence that is consistent with the FAO brand.</p>


                    </div>
                </div>


            </div>
            <div style={{backgroundColor: '#95a1b5', padding: '4rem 0 2rem 0'}}>


                <div className="container ">
                    <h2 className="text-center mb-5" style={{color: 'white'}}>What's new?</h2>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="ds-home-card">
                                <div className="ds-home-card-body">
                                    <h3>Flags</h3>
                                    <p>Take a look at the flags available from Sitefinity media base and how to use it in a standard and consistent way</p>
                                    <a href="/styles/flags" title="Placeholder link title"
                                       className="stretched-link link-icon">Learn more
                                        <i className="bi bi-chevron-right"></i>
                                    </a>
                                </div>
                                <div className="ds-home-card-image">
                                    <img src={wnImage1} alt="what's new"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="ds-home-card">
                                <div className="ds-home-card-body">
                                    <h3>Maps</h3>
                                    <p>Use the FAO Design System maps to create beautiful visualization with a compliant map (UN Geospatial)</p>
                                    <a href="/components/maps" title="Placeholder link title"
                                       className="stretched-link link-icon">Learn more
                                        <i className="bi bi-chevron-right"></i>
                                    </a>
                                </div>
                                <div className="ds-home-card-image">
                                    <img src={wnImage2} alt="what's new"/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="container ">
                <div className="row">
                    <div className="col-12">
                        <h2 className="text-center my-5">Main features</h2>
                    </div>
                </div>
                <div className="row pb-5">
                    <div className="col-lg-4">
                        <div className="card text-left">
                            <div className="card-body">
                                <div className="ds-home-card-ico bg-primary">
                                    <i className="bi bi-palette2"></i>
                                </div>
                                <h5 className="card-title">Style</h5>
                                <p className="card-text">
                                    Learn about the color systems, typography, images support, icons and layout.
                                </p>
                                <a href="/styles" title="Placeholder link title"
                                   className="stretched-link link-icon">Learn more
                                    <i className="bi bi-chevron-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="card text-left">
                            <div className="card-body">
                                <div className="ds-home-card-ico bg-primary">
                                    <i className="bi bi-boxes"></i>
                                </div>
                                <h5 className="card-title">Components</h5>
                                <p className="card-text">
                                    Components are reusable parts of the user interface that have been made to support a variety of applications.
                                </p>
                                <a href="/components" title="Placeholder link title"
                                   className="stretched-link link-icon">Learn more
                                    <i className="bi bi-chevron-right"></i>
                                </a>

                                

                            </div>
                        </div>

                    </div>

                    <div className="col-lg-4">
                        <div className="card text-left">
                            <div className="card-body">
                                <div className="ds-home-card-ico bg-primary">
                                    <i className="bi bi-circle-square"></i>
                                </div>
                                <h5 className="card-title">Sitefinity CMS</h5>
                                <p className="card-text">
                                    An overview on how the compontents will work when combined togheter on the Sitefinity corporate CMS
                                </p>
                                <NavLink className="stretched-link link-icon" to="/sitefinity">
                                    Learn more
                                    <i className="bi bi-chevron-right"></i>
                                </NavLink>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <div className="container">
                <Footer copyright="©FAO 2023" />
            </div>
            
        </div>
        
        

    )
        ;
}

export default Home;


function computePaths(allRoutes) {
    const result = [];

    for (let key of Object.keys(allRoutes)) {
        allRoutes[key].forEach(el => {
            el.category = key;
            result.push(el)
        })
    }
    ;

    return result;
}
