import PropTypes from "prop-types";
import ReactDOMServer from 'react-dom/server';
import React, { useEffect } from "react";
import Prism from 'prismjs';
import 'prismjs';
import 'prismjs/components/prism-markdown';
import 'prismjs/plugins/toolbar/prism-toolbar';
import 'prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard';
import 'prismjs/themes/prism.css';
// fix console errors to use renderToStaticMarkup since useLayout can't be used in SSR ()
React.useLayoutEffect = React.useEffect;

extendCustomPrismLineWrap();

const ShowCode = props => {
    const codeToRender = processStr(ReactDOMServer.renderToStaticMarkup(props.children), props.skipStripHTMLComment, props.skipStripIFrameSrc, 
	  props.skipStripImgSrc, props.skipStripVideoSrc);

    useEffect(() => {
        Prism.highlightAll();
    }, []);

    return (
        <div className="tp-showcode">
			<div className={`tp-showcode-header${props.hideHeader ? ' d-none' : ''}`}>
				<p className="mb-0 px-3 py-2 text-uppercase">{props.language ?? 'HTML'}</p>
			</div>
            <pre>
                <code className={`language-${props.language ?? 'markdown'}`}>{ codeToRender }</code>
            </pre>
        </div>
    );
}

ShowCode.propTypes = {
  children: PropTypes.any.isRequired,
  language: PropTypes.string,
  skipStripHTMLComment: PropTypes.bool,
  skipStripImgSrc: PropTypes.bool,
  skipStripVideoSrc: PropTypes.bool,
  skipStripIFrameSrc: PropTypes.bool
}

export default ShowCode;

function processStr(str, skipHTMLComments, skipIFrameSrc, skipImgSrc, skipVideoSrc) {
    let div = document.createElement('div');
    str = stripReactBindings(str);
	str = stripBase64(str);
	if (!skipHTMLComments) {
		str = stripHTMLComments(str);
	}

    div.innerHTML = str.trim();
	div = replaceHTMLAttributes(div, skipIFrameSrc, skipImgSrc, skipVideoSrc);
    return format(div, 0).innerHTML;
}

function stripReactBindings(str) {
	str = str.replace(new RegExp('data-reactroot=""', 'g'), '');

	const pattern = 'v-if="';
	let count = (str.match(new RegExp(pattern, 'g')) || []).length;
	while (count !== 0) {
		const startIdx = str.indexOf(pattern);
		const endIdx = str.indexOf('"', startIdx + pattern.length);
		str = str.substr(0, startIdx) + str.substr(endIdx + 1);
    	count--;
	}

	return str;
}

function stripBase64(str) {
	const pattern = 'data:image/';
	let count = (str.match(new RegExp(pattern, 'g')) || []).length;
	while (count !== 0) {
		const startIdx = str.indexOf(pattern);
		const endIdx = str.indexOf('"', startIdx + pattern.length);
		str = str.substr(0, startIdx) + 'insertImageHere' + str.substr(endIdx);
    	count--;
	}
	
	return str;
}

function stripHTMLComments(str) {
	const pattern = '<!--';
	const patternEnd = '-->';

	let count = (str.match(new RegExp(pattern, 'g')) || []).length;
	while (count !== 0) {
		const startIdx = str.indexOf(pattern);
		const endIdx = str.indexOf(patternEnd, startIdx + pattern.length);
		str = str.substr(0, startIdx) + str.substr(endIdx + patternEnd.length);
    	count--;
	}

	return str;
}

function replaceHTMLAttributes(el, skipIFrameSrc, skipImgSrc, skipVideoSrc) {
	
	[...el.children].forEach(child => {
		child.removeAttribute('style');
	})

	for (const IFrame of el.getElementsByTagName('iframe')) {
		[...IFrame.attributes].forEach(attr => IFrame.removeAttribute(attr.name));
		if (!skipIFrameSrc) {
			IFrame.src = '...';
		}
		
	}

	for (const img of el.getElementsByTagName('img')) {
		if (!skipImgSrc) {
			img.src = '...';
		}
		img.alt = '...';
	}
	
	for (const video of el.getElementsByTagName('video')) {
		[...video.attributes].forEach(attr => video.removeAttribute(attr.name));
		if (!skipVideoSrc) {
			video.src = '...';
		}
	}

	return el;
}

function format(node, level) {
    let indentBefore = new Array(level++ +  1).join('  ');
    let indentAfter  = new Array(level - 1).join('  ');
    let textNode;

    for (let i = 0; i < node.children.length; i++) {
        textNode = document.createTextNode('\n' + indentBefore);
        node.insertBefore(textNode, node.children[i]);

        format(node.children[i], level);

        if (node.lastElementChild === node.children[i]) {
            textNode = document.createTextNode('\n' + indentAfter);
            node.appendChild(textNode);
        }
    }

    return node;
}

function extendCustomPrismLineWrap() {
	let assign = Object.assign || function (obj1, obj2) {
		for (let name in obj2) {
			if (obj2.hasOwnProperty(name)) {
				obj1[name] = obj2[name];
			}
		}
		return obj1;
	};

	function NormalizeWhitespace(defaults) {
		this.defaults = assign({}, defaults);
	}

	function toCamelCase(value) {
		return value.replace(/-(\w)/g, function (match, firstChar) {
			return firstChar.toUpperCase();
		});
	}

	function tabLen(str) {
		let res = 0;
		for (let i = 0; i < str.length; ++i) {
			if (str.charCodeAt(i) === '\t'.charCodeAt(0)) {
				res += 3;
			}
		}
		return str.length + res;
	}

	NormalizeWhitespace.prototype = {
		setDefaults: function (defaults) {
			this.defaults = assign(this.defaults, defaults);
		},
		normalize: function (input, settings) {
			settings = assign(this.defaults, settings);

			for (let name in settings) {
				let methodName = toCamelCase(name);
				if (name !== 'normalize' && methodName !== 'setDefaults' &&
					settings[name] && this[methodName]) {
					input = this[methodName].call(this, input, settings[name]);
				}
			}

			return input;
		},

		leftTrim: function (input) {
			return input.replace(/^\s+/, '');
		},
		rightTrim: function (input) {
			return input.replace(/\s+$/, '');
		},
		tabsToSpaces: function (input, spaces) {
			spaces = spaces|0 || 4;
			return input.replace(/\t/g, new Array(++spaces).join(' '));
		},
		spacesToTabs: function (input, spaces) {
			spaces = spaces|0 || 4;
			return input.replace(RegExp(' {' + spaces + '}', 'g'), '\t');
		},
		removeTrailing: function (input) {
			return input.replace(/\s*?$/gm, '');
		},
		// Support for deprecated plugin remove-initial-line-feed
		removeInitialLineFeed: function (input) {
			return input.replace(/^(?:\r?\n|\r)/, '');
		},
		removeIndent: function (input) {
			let indents = input.match(/^[^\S\n\r]*(?=\S)/gm);

			if (!indents || !indents[0].length) {
				return input;
			}

			indents.sort(function (a, b) { return a.length - b.length; });

			if (!indents[0].length) {
				return input;
			}

			return input.replace(RegExp('^' + indents[0], 'gm'), '');
		},
		indent: function (input, tabs) {
			return input.replace(/^[^\S\n\r]*(?=\S)/gm, new Array(++tabs).join('\t') + '$&');
		},
		breakLines: function (input, characters) {
			characters = (characters === true) ? 80 : characters|0 || 80;

			let lines = input.split('\n');
			for (let i = 0; i < lines.length; ++i) {
				if (tabLen(lines[i]) <= characters) {
					continue;
				}

				let line = lines[i].split(/(\s+)/g);
				let len = 0;
				
                for (let j = 0; j < line.length; ++j) {
					let tl = tabLen(line[j]);
					len += tl;
					
					if (len > characters) {
						const indentationAmount = lines[i].search(/\S|$/)
						line[j] = '\n' + ' '.repeat(indentationAmount) + line[j];
						len = tl + indentationAmount;
					}
				}
				lines[i] = line.join('');
			}
			return lines.join('\n');
		}
	};

	Prism.plugins.NormalizeWhitespace = new NormalizeWhitespace({
		'remove-trailing': true,
		'remove-indent': true,
		'left-trim': true,
		'right-trim': true,
		'break-lines': 135,
		'remove-initial-line-feed': true,
		/*' 'tabs-to-spaces': 4,
		'spaces-to-tabs': 4*/
	});

	Prism.hooks.add('before-sanity-check', function (env) {
		let Normalizer = Prism.plugins.NormalizeWhitespace;

		// Check settings
		if (env.settings && env.settings['whitespace-normalization'] === false) {
			return;
		}

		// Check classes
		if (!Prism.util.isActive(env.element, 'whitespace-normalization', true)) {
			return;
		}

		// Simple mode if there is no env.element
		if ((!env.element || !env.element.parentNode) && env.code) {
			env.code = Normalizer.normalize(env.code, env.settings);
			return;
		}

		// Normal mode
		let pre = env.element.parentNode;
		if (!env.code || !pre || pre.nodeName.toLowerCase() !== 'pre') {
			return;
		}

		let children = pre.childNodes;
		let before = '';
		let after = '';
		let codeFound = false;

		// Move surrounding whitespace from the <pre> tag into the <code> tag
		for (let i = 0; i < children.length; ++i) {
			let node = children[i];

			if (node === env.element) {
				codeFound = true;
			} else if (node.nodeName === '#text') {
				if (codeFound) {
					after += node.nodeValue;
				} else {
					before += node.nodeValue;
				}

				pre.removeChild(node);
				--i;
			}
		}

		if (!env.element.children.length || !Prism.plugins.KeepMarkup) {
			env.code = before + env.code + after;
			env.code = Normalizer.normalize(env.code, env.settings);
		} else {
			// Preserve markup for keep-markup plugin
			let html = before + env.element.innerHTML + after;
			env.element.innerHTML = Normalizer.normalize(html, env.settings);
			env.code = env.element.textContent;
		}
	});

}