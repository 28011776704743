// import DsLayout from '../layouts/DsLayout'
//import {gsap} from "gsap";
// import Swiper JS
import Swiper from 'swiper';
// import Swiper styles
import 'swiper/css';
import {useEffect} from "react";


const HomeBanner = () => {
    useEffect(() => {
        // eslint-disable-next-line
        const swiper = new Swiper('.swiper', {
            // Optional parameters
            //loop: true,

            // If we need pagination
            pagination: {
                el: '.swiper-pagination',
            },

            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        });
    }, [])
    return (
        <>
            <div className="row row-fao-homepage-hero py-0" data-sf-element="Row">
                <div className="col-md-12">
                    <div className="hero-banner hero-interactive">
                        <video className="hero-banner-video" loop="" autoPlay="" muted="" playsInline="" preload="auto">
                            <source
                                src="https://www.fao.org/videos/homepagelibraries/hero-banner-videos/fao-emergencies-and-resilience-loop.mp4?sfvrsn=55ebdd2e_12"/>
                        </video>
                        <div className="hero-caption">
                            <h5 className="title-link">FAO in emergencies</h5>
                            <div className="hero-text">
                                <h6 className="title-category">In focus</h6>
                                <h1><a href="https://www.fao.org/emergencies/en">FAO in emergencies</a></h1>
                                <p>Saving lives, safeguarding livelihoods and laying the foundations for resilience</p>
                                <p><a href="#!" onClick={toggleBanner} title=" FAO in the 2023 humanitarian appeals"
                                      className="link-icon" data-sf-ec-immutable=""><i
                                    className="bi bi-eye-fill bi-bgico"></i><span>Play with data</span></a>
                                </p>
                            </div>
                        </div>

                        <div className="hero-interactive-content">
                            <button onClick={toggleBanner}
                                    className="btn btn-secondary btn-icon btn-round btn-round-big btn-hero-interactive-close">
                                <i className="bi bi-x"></i></button>


                            <div className="swiper swiper-forestry">

                                <div className="swiper-wrapper">

                                    <div className="swiper-slide">

                                        <div className="container forestry-text">
                                            <div className="col-forestry">
                                                <h1>1 000 000 000 <span className="unit">people</span></h1>
                                                <p>Forests are a vital source of food and nutrition
                                                    Nearly one billion people globally depend on harvesting wild
                                                    food.</p>
                                                <a href="#!" title="" className="link-icon"><i
                                                    className="bi bi-chevron-right bi-bgico"/><span>Next</span></a>

                                            </div>
                                            <div className="forestry-video">
                                                <img
                                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAcCAQAAACJkCR8AAAAJUlEQVR42u3NQREAAAwCoNm/9Fp4PqAAuYJIJBKJRCKRSCR7yQOrOgAdN7XvagAAAABJRU5ErkJggg=="
                                                    className="image-prop-helper" alt="img-helper" />

                                                <iframe
                                                    src="https://www.youtube.com/embed/sTa2eiZdEgA?controls=0&autoplay=1&mute=1&loop=1&playlist=sTa2eiZdEgA"
                                                    title="YouTube video player" frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"/>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="swiper-slide">
                                        <div className="container forestry-text">
                                            <div className="col-forestry">
                                                <h1>50 000 <span className="unit">plant species</span></h1>
                                                <p>Around 50 000 plant species – many of which grow in forests – have
                                                    medicinal value.</p>
                                                <a href="#!" title="" className="link-icon"><i
                                                    className="bi bi-chevron-right bi-bgico"/><span>Next</span></a>

                                            </div>
                                            <div className="forestry-video">
                                                <img
                                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAcCAQAAACJkCR8AAAAJUlEQVR42u3NQREAAAwCoNm/9Fp4PqAAuYJIJBKJRCKRSCR7yQOrOgAdN7XvagAAAABJRU5ErkJggg=="
                                                    className="image-prop-helper" alt="img-helper" />

                                                <iframe
                                                    src="https://www.youtube.com/embed/Ys5d4dOl-10?controls=0&autoplay=1&mute=1&loop=1&playlist=Ys5d4dOl-10"
                                                    title="YouTube video player" frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"/>
                                            </div>
                                        </div>

                                        <div className="forestry-text">


                                        </div>
                                        <div className="forestry-video">

                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="forestry-text">
                                            <p>More than 30 percent of new diseases reported since 1960 are attributed
                                                to land-use change, including deforestation.</p>
                                            <h1>30 percent of new diseases</h1>
                                        </div>
                                        <div className="forestry-video">
                                            <iframe
                                                src="https://www.youtube.com/embed/igADH5F0tPw?controls=0&autoplay=1&mute=1&loop=1&playlist=igADH5F0tPw"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"></iframe>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="forestry-text">
                                            <p>Healthy forests help keep global warming in check: forests contain 662
                                                billion tonnes of carbon, which is more than half the global carbon
                                                stock in soils and vegetation.</p>
                                            <h1>662 billion tonnes of carbon</h1>
                                        </div>
                                        <div className="forestry-video">
                                            <iframe
                                                src="https://www.youtube.com/embed/igADH5F0tPw?controls=0&autoplay=1&mute=1&loop=1"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"></iframe>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="forestry-text">
                                            <p>Ten million hectares – roughly the equivalent of 14 million football
                                                pitches – of forest were lost per year to deforestation between 2015 and
                                                2020.</p>
                                            <h1>10 000 000 hectares</h1>
                                        </div>
                                        <div className="forestry-video">
                                            <iframe
                                                src="https://www.youtube.com/embed/igADH5F0tPw?controls=0&autoplay=1&mute=1&loop=1"
                                                title="YouTube video player" frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"></iframe>
                                        </div>
                                    </div>
                                </div>

                                <div className="swiper-pagination"></div>

                                <div className="swiper-button-prev"></div>
                                <div className="swiper-button-next"></div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Non in necessitatibus quam culpa
                    laudantium, voluptas eveniet cupiditate architecto placeat optio officia repellendus suscipit veniam
                    accusantium? Provident dicta beatae culpa incidunt!</p>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Non in necessitatibus quam culpa
                    laudantium, voluptas eveniet cupiditate architecto placeat optio officia repellendus suscipit veniam
                    accusantium? Provident dicta beatae culpa incidunt!</p>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Non in necessitatibus quam culpa
                    laudantium, voluptas eveniet cupiditate architecto placeat optio officia repellendus suscipit veniam
                    accusantium? Provident dicta beatae culpa incidunt!</p>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Non in necessitatibus quam culpa
                    laudantium, voluptas eveniet cupiditate architecto placeat optio officia repellendus suscipit veniam
                    accusantium? Provident dicta beatae culpa incidunt!</p>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Non in necessitatibus quam culpa
                    laudantium, voluptas eveniet cupiditate architecto placeat optio officia repellendus suscipit veniam
                    accusantium? Provident dicta beatae culpa incidunt!</p>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Non in necessitatibus quam culpa
                    laudantium, voluptas eveniet cupiditate architecto placeat optio officia repellendus suscipit veniam
                    accusantium? Provident dicta beatae culpa incidunt!</p>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Non in necessitatibus quam culpa
                    laudantium, voluptas eveniet cupiditate architecto placeat optio officia repellendus suscipit veniam
                    accusantium? Provident dicta beatae culpa incidunt!</p>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Non in necessitatibus quam culpa
                    laudantium, voluptas eveniet cupiditate architecto placeat optio officia repellendus suscipit veniam
                    accusantium? Provident dicta beatae culpa incidunt!</p>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Non in necessitatibus quam culpa
                    laudantium, voluptas eveniet cupiditate architecto placeat optio officia repellendus suscipit veniam
                    accusantium? Provident dicta beatae culpa incidunt!</p>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Non in necessitatibus quam culpa
                    laudantium, voluptas eveniet cupiditate architecto placeat optio officia repellendus suscipit veniam
                    accusantium? Provident dicta beatae culpa incidunt!</p>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Non in necessitatibus quam culpa
                    laudantium, voluptas eveniet cupiditate architecto placeat optio officia repellendus suscipit veniam
                    accusantium? Provident dicta beatae culpa incidunt!</p>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Non in necessitatibus quam culpa
                    laudantium, voluptas eveniet cupiditate architecto placeat optio officia repellendus suscipit veniam
                    accusantium? Provident dicta beatae culpa incidunt!</p>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Non in necessitatibus quam culpa
                    laudantium, voluptas eveniet cupiditate architecto placeat optio officia repellendus suscipit veniam
                    accusantium? Provident dicta beatae culpa incidunt!</p>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Non in necessitatibus quam culpa
                    laudantium, voluptas eveniet cupiditate architecto placeat optio officia repellendus suscipit veniam
                    accusantium? Provident dicta beatae culpa incidunt!</p>
            </div>


        </>
    );
}

function toggleBanner(e) {
    e.preventDefault()
    const banner = document.querySelector('.hero-banner');
    // const activeContent = document.querySelector('.hero-active-content');
    // const headerHeight = '57px';

    if (!banner.classList.contains('active')) {
        banner.classList.add('active');

        // gsap.to(banner, {height: `calc(90vh - ${headerHeight})`});
        // gsap.to(activeContent, {opacity: '1', duration: 3});
    } else {
        banner.classList.remove('active');

        // gsap.to(activeContent, {opacity: '0', duration: 0});
        // gsap.to(banner, {height: 'auto'})
    }
}


export default HomeBanner;