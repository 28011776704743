

const ContentBlock = props => {
    return (
        <div className="sfContentBlock">
            { props.children }
        </div>
    );
}
  
export default ContentBlock;