import DsLayout from '../../layouts/DsLayout'
import { Heading } from '../../components/design-system/DsComponents'

const CreatingView = () => {
    return (
        <DsLayout>
        <Heading size="h1">Creating accessible websites</Heading>
        <p>
    The principles and details of compliance in practice are laid out in the
    World Wide Web Consortium's&nbsp;
    <a  rel="noreferrer"  target="_blank" href="https://www.w3.org/WAI/standards-guidelines/wcag/">
        WCAG Guidelines
    </a>
    . These guidelines represent a gold standard, and several countries have
    introduced digital accessibility legislation which refers to these
    guidelines.
</p>
<p>
The components and styles of FAO's design system are built upon the    <a rel="noreferrer" target="_blank" href="https://getbootstrap.com/">Bootstrap</a> web framework and so
    consequently inherits several best practices which have been refined over
    time.
</p>
<p>
    The Design System covers definitions relating to styles, components and
    content; were relevant for each definition, you will find notes on
    improving accessibility.
</p>
        </DsLayout>
            );
}
  
export default CreatingView;
