import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
import { AccordionItem, AccordionWrap } from '../../components/fao/FaoComponents'


const AccordionsView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Accordion</Heading>
            <p>Using the card component, you can extend the default collapse behavior to create an accordion. To properly achieve the accordion style, be sure to use <b>.accordion</b> as a wrapper.</p>

            <Heading size="h3">Accessibility</Heading>
            <p>Be sure to add <b>aria-expanded</b> to the control element. This attribute explicitly conveys the current state of the collapsible 
                element tied to the control to screen readers and similar assistive technologies. If the collapsible element is closed by default, 
                the attribute on the control element should have a value of <b>aria-expanded="false"</b>. If you’ve set the collapsible element 
                to be open by default using the show class, set <b>aria-expanded="true"</b> on the control instead. The plugin will automatically 
                toggle this attribute on the control based on whether or not the collapsible element has been opened or closed 
                (via JavaScript, or because the user triggered another control element also tied to the same collapsible element). 
                If the control element’s HTML element is not a button, the attribute <b>role="button"</b> should be added to the element.</p>
            <p>If your control element is targeting a single collapsible element – i.e. the data-target 
                attribute is pointing to an id selector – you should add the aria-controls attribute to the control element, 
                containing the id of the collapsible element. Modern screen readers and similar assistive technologies make use 
                of this attribute to provide users with additional shortcuts to navigate directly to the collapsible element itself.</p>
            <Heading size="h3">Usage</Heading> 
            <p>The collapse component utilizes a few classes to handle the heavy lifting:</p>
            <ul>
                <li><b>.collapse</b> hides the content</li>
                <li><b>.collapse.show</b> shows the content</li>
                <li><b>.collapsing</b> is added when the transition starts, and removed when it finishes</li>
            </ul>

            <Heading size="h3">Example</Heading>
            <p>Click the accordions below to expand/collapse the accordion content.</p>

            <RenderCode>
            <AccordionWrap id="2">
                <AccordionItem parentId="2" id="accmultiple1" title="Test title 1" bodytext="Lorem ipsum 1" open/>
                <AccordionItem parentId="2" id="accmultiple2" title="Test title 2" bodytext="Lorem ipsum 2"/>
                <AccordionItem parentId="2" id="accmultiple3" title="Test title 3" bodytext="Lorem ipsum 3"/>
            </AccordionWrap>
            </RenderCode>

            
        </DsLayout>
    );
}

export default AccordionsView;