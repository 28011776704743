import listImage from '../../../assets/test-images/identity-test.svg'
import {TagsList} from "../FaoComponents";


const ListPartners = props => {
    return (
        <div className="d-list d-list-partners">
            <div className="d-list-visual ratio ratio-3x2">
                <img src={ props.image || listImage} alt={ props.title || 'Lorem ipsum dolor sit amet' } />
            </div>
            <div className="d-list-content">
                <h5 className="title-link">
                    <a href="#!">{ props.title || 'Lorem ipsum dolor sit amet' }</a>
                </h5>
                <p>{ props.text || 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque autem corrupti consequuntur tempore. Numquam, facere? Natus nisi aliquid a cumque, incidunt deleniti atque quibusdam adipisci recusandae repellendus labore. Neque, placeat.' }</p>
                <p className="category-list"><span className="category-list-title">Categories:</span> <a href="#!">Lorem ipsum</a>, <a href="#!">Cumque autem</a>, <a href="#!">Neque</a></p>
                <TagsList />
                <div>
                    <p className="me-3 mb-2 d-inline-block">
                        <b>IN PARTNERSHIP SINCE: </b>2013
                    </p>
                    <p className="me-3 mb-2 d-inline-block">
                        <b>COUNTRY: </b>Italy
                    </p>
                    <div className="d-inline-flex align-items-center mb-2">
                        <span className="me-2 mb-2">
                            <b>SDGs: </b>
                        </span>
                        <span className="sdg-small sdg-3 me-2 mb-2"></span>
                        <span className="sdg-small sdg-4 me-2 mb-2"></span>
                        <span className="sdg-small sdg-5 me-2 mb-2"></span>
                        <span className="sdg-small sdg-6 me-2 mb-2"></span>
                    </div>
                </div>

                <p className="me-3 mb-2">
                    <b>AREAS OF COLLABORATION: </b>Lorem, Lorem Ipsum
                </p>

                <p className="me-3 mb-2">
                    <b>Resources: </b>
                    <a href="#!">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</a>
                </p>
            </div>
        </div>
    );
}
  
export default ListPartners;
