import DsLayout from '../../layouts/DsLayout'
import { Heading } from '../../components/design-system/DsComponents'


const ToolsView = () => {
    return (
        <DsLayout>
        <Heading size="h1">Tools</Heading>
        <p>
    Here you will find a list of tools that can automate finding accessibility
    issues in your website. However, automated testing can only take you part
    of the way to testing for accessibility issues. The most reliable test is
    done by users who have accessibility issues and are familiar with assistive
    technologies. That said, human testing should come after all problems
    detectable with automated testing have been addressed.
</p>

        <ul>
    <li>
        Chrome Plugins
    </li>
<ul>
<li>
    
    <a   rel="noreferrer" target="_blank" 
        href="https://chrome.google.com/webstore/detail/wave-evaluation-tool/jbbplnpkjmmeebjpijfedlgcdilocofh"
    >
        Wave Evaluation Tool
    </a>
    &nbsp;Check for accessibility issues on web pages
</li>
<li>
    
    <a   rel="noreferrer" target="_blank" 
        href="https://chrome.google.com/webstore/detail/headingsmap/flbjommegcjonpdmenkdiocclhjacmbi?hl=en"
    >
        Heading Map
    </a>
    &nbsp;A good heading structure facilitates accessibility
</li>
<li>
    
    <a   rel="noreferrer" target="_blank" 
        href="https://chrome.google.com/webstore/detail/validity/bbicmjjbohdfglopkidebfccilipgeif?hl=en-GB"
    >
        HTML Validity
    </a>
    &nbsp;Look for HTML errors that could confuse screen readers
</li>
</ul>
</ul>
<ul>
    <li>
        <a   rel="noreferrer" target="_blank"  href="https://www.nvaccess.org/download/">
            NVDA - Free Open-Source Screen Reader
        </a>
    </li>
    <li>
        Colour blindness testing filters&nbsp;
        <a   rel="noreferrer" target="_blank"  href="https://www.toptal.com/designers/colorfilter/">
            Colour Filter
        </a>
        ,&nbsp;
        <a  rel="noreferrer"  target="_blank" href="https://michelf.ca/projects/mac/sim-daltonism/">
            Sim Daltonism for Mac
        </a>
    </li>
    <li>
Crawl and test websites with        <a href="https://github.com/liip/TheA11yMachine">The A11ly Machine</a>
    </li>
    <li>
        <a  rel="noreferrer"  target="_blank" href="https://web.dev/measure/">Lighthouse</a>
        . Available online and built into Chrome's developer tools – checks
        many aspects of a web page, including the accessibility
    </li>
</ul>
        </DsLayout>
    );
}
  
export default ToolsView;
