import DsLayout from '../../layouts/DsLayout'
import {Heading, RenderCode} from '../../components/design-system/DsComponents'
// import {ProjectsListItem} from '../../components/fao/FaoComponents'

const FaoForumListView = () => {
    return (
        <DsLayout>
            <Heading size="h3">Forum list</Heading>
            <RenderCode>
                <div className="table-responsive forum-list">
                    <table className="table">
                        <thead className="bg-gray-light">
                        <tr>
                            <th>Forum title</th>
                            <th>Threads</th>
                            <th>Post/Replies</th>
                            <th>LastPost</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><a href="#!">Lorem ipsum dolor sit amet consectetur adipisicing elit.</a></td>
                            <td>1</td>
                            <td>1</td>
                            <td>16/08/2023, 06:37:57</td>
                        </tr>

                        <tr>
                            <td><a href="#!">Lorem ipsum dolor sit amet consectetur adipisicing elit.</a></td>
                            <td>1</td>
                            <td>1</td>
                            <td>16/08/2023, 06:37:57</td>
                        </tr>

                        <tr>
                            <td><a href="#!">Lorem ipsum dolor sit amet consectetur adipisicing elit.</a></td>
                            <td>1</td>
                            <td>1</td>
                            <td>16/08/2023, 06:37:57</td>
                        </tr>

                        </tbody>
                    </table>
                </div>
            </RenderCode>
            
        </DsLayout>
    );
}

export default FaoForumListView;
