import DsLayoutFullWidth from '../../layouts/DsLayoutFullWidth'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
import { TagsList, ListPhotoGallery } from "../../components/fao/FaoComponents";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from "swiper/core";
import { Navigation } from 'swiper';

import galleryImage from "../../assets/test-images/card-test.jpg";

// Import Swiper styles
// import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Navigation]);

const FaoPhotoGalleryDetailView = () => {
    return (
        <DsLayoutFullWidth>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Heading size="h1">Photo Gallery detail</Heading>
                    </div>
                </div>
            </div>

            <RenderCode fullWidth={true}>
                <div className="bg-gray-dark text-white pt-4 border-top border-white">
                    <div className="container detail-photo-gallery">
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="detail-title mb-4">Lorem ipsum dolor sit amet</h1>

                                <div className="ratio ratio-3x2 mb-4">

                                <Swiper className="swiper-container" slidesPerView={1} navigation>
                                    {[...Array(4)].map((e, i) => <SwiperSlide key={i}>
                                    
                                        <img src={galleryImage} className="img-fluid" alt="card alt"/>
                                    </SwiperSlide>)}
                                </Swiper>

                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="detail__body mb-4">
                                            <p>Lorem ipsum dolor sit amet. Ea aperiam mollitia aut explicabo error ut sint
                                                assumenda est temporibus molestiae sed harum itaque. Et omnis voluptate et
                                                optio dolorem ut architecto ratione a voluptatem laudantium sit illum
                                                assumenda sed ducimus quisquam. Sed dignissimos totam cum dolorem iusto ex
                                                voluptas consectetur est numquam dicta et rerum totam ab voluptas illo. Eos
                                                inventore temporibus ut corporis quis et quisquam </p>
                                            <p className="date text-white">26/11/2020, Rome (Italy)</p>
                                            <TagsList title/>
                                        </div>
                                        <h5 className="sub-title small text-white">Related Galleries</h5>
                                        <div className="row detail-video__rvideos pb-5">
                                            <div className="col-lg-6 border-right border-white">
                                                <ListPhotoGallery />
                                            </div>
                                            <div className="col-lg-6">
                                                <ListPhotoGallery />
                                            </div>
                                            <div className="col-lg-6 border-right border-white">
                                                <ListPhotoGallery />  
                                            </div>
                                            <div className="col-lg-6">
                                                <ListPhotoGallery />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </RenderCode>
            
        </DsLayoutFullWidth>
    );
}

export default FaoPhotoGalleryDetailView;

