import DsLayout from '../../layouts/DsLayout'
import {Heading, RenderCode} from '../../components/design-system/DsComponents'
import {ProjectsListItem} from '../../components/fao/FaoComponents'

const FaoProjectListView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Projects</Heading>
            <Heading size="h3">List</Heading>
            <ProjectsListItem
                fullList
                showCode
                title="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Amet deserunt officia fugiat."
                startDate="02/06/2022"
                endDate="04/06/2022"
                targetAreas="Lorem, Ipsum, Lorem, Ipsum"
                budget="99999"
                donor="Lorem ipsum"
                projectCode="LOREM/IPS/UM"
            />

            <Heading size="h3">List minimal</Heading>
            <ProjectsListItem
                title="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Amet deserunt officia fugiat."
                startDate="02/06/2022"
                endDate="04/06/2022"
                targetAreas="Lorem, Ipsum, Lorem, Ipsum"
                budget="99999"
                donor="Lorem ipsum"
                projectCode="LOREM/IPS/UM"
            />
            <br/><br/>

            <Heading size="h3">List minimal horizontal</Heading>
            <RenderCode>
                <div className="table-responsive project-list-item-minimal">
                    <table className="table">
                        <thead className="bg-gray-light">
                        <tr>
                            <th>Symbol</th>
                            <th>Title</th>
                            <th>From</th>
                            <th>to</th>
                            <th>Total Budget</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>LOREM/IPS/UM</td>
                            <td><a href="#!">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Amet deserunt officia
                                fugiat.</a>
                            </td>
                            <td> 2021</td>
                            <td> 2023</td>
                            <td>300,000$</td>
                        </tr>
                        <tr>
                            <td>LOREM/IPS/UM</td>
                            <td><a href="#!">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Amet deserunt officia fugiat.</a>
                            </td>
                            <td> 2021</td>
                            <td> 2023</td>
                            <td>300,000$</td>
                        </tr>

                        </tbody>
                    </table>
                </div>
            </RenderCode>
            
        </DsLayout>
    );
}

export default FaoProjectListView;
