import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
import { ListElearning } from '../../components/fao/FaoComponents'

const FaoElearningListView = () => {
    return (
        <DsLayout>
            <Heading size="h1">E-Learning list</Heading>

            <RenderCode>
                <ListElearning />
            </RenderCode>
        </DsLayout>
    );
}

export default FaoElearningListView;
