import DsLayout from '../layouts/DsLayout'


const AccessibilityPage = () => {
    return (
        <DsLayout>
            <h1 className="ds-title-fw mt-5 mb-4">Accessibility</h1>
            <p>
    Accessibility in this document refers to facilitating access to FAO's web
    content by users with impairments. When considering the web, we usually
    tend to think of the need for facilitating access to totally blind users
    who need screen readers; however, there are many kinds of impairment
    experienced to varying degrees. Therefore, beyond assisting blind users, it
    is necessary to consider the needs of visitors with low vision, physical or
    motor disabilities, deaf or hard of hearing, anxiety, and those on the
    autistic spectrum.
</p>
<p>
According to the <a rel="noreferrer" target="_blank" href="https://www.w3.org/WAI/standards-guidelines/wcag/">W3C</a>, For a
    website to be considered accessible, it must be:
</p>
<ul>
    <li>
        <strong>Perceivable.</strong>
        &nbsp;Information and user interface components must be presentable to
        users in ways they can perceive.
    </li>
    <li>
        <strong>Operable.</strong>
        &nbsp;User interface components and navigation must be operable.
    </li>
    <li>
        <strong>Understandable.</strong>
        &nbsp;Information and the operation of the user interface must be
        understandable.
    </li>
    <li>
        <strong>Robust.</strong>
        &nbsp;Content must be robust enough that it can be interpreted by a wide
        variety of user agents, including assistive technologies.
    </li>
</ul>
<p>
    One final observation is that many optimizations which improve
    accessibility for those with disabilities also enhance the usability of the
    website for everyone.
</p>            
        </DsLayout>
    );
}
  
export default AccessibilityPage;
