import DsLayout from '../../layouts/DsLayout'
import {Heading} from '../../components/design-system/DsComponents'


const AppLayoutView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Emoji</Heading>


            <p>🚫 The design system prohibits the use of emojis across all FAO web properties.</p>

            <p>Emojis pose several challenges across different devices and operating systems, potentially leading to
                confusion and misinterpretation of messages. Variations in appearance exist between Apple, Android,
                and Windows devices, resulting from platform-specific designs implemented by Google, Microsoft,
                Apple, Facebook, Twitter, etc., after the inclusion of new emojis in the Unicode Standard. This not
                only leads to delayed availability across different systems but also introduces the risk of certain
                emojis being deemed controversial and so omitted by some vendors.</p>

            <p>The use of emojis may also be perceived as unprofessional or casual, especially in FAO's professional
                communication setting. Additionally, assistive technologies, like screen readers, often struggle to
                interpret emojis correctly, disrupting text flow for users with visual impairments. The absence of
                HTML attribute ALT, crucial for alternative text, exacerbates this issue.</p>

            <p>Moreover, older browser versions may lack support for newer emojis, and this limitation is tied to
                the font and rendering capabilities of both the browser and the underlying operating system. Users
                may encounter blank boxes, question marks, or generic placeholders when using unsupported emojis,
                necessitating updates for both browsers and, in some cases, operating systems.</p>

            <p>Emojis also face challenges related to cultural interpretation, potential misuse leading to
                ambiguity, and evolving meanings over time, contributing to communication challenges; likewise the
                representation of diversity in emojis remains a concern.</p>


        </DsLayout>);
}

export default AppLayoutView;