import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
import { ListSpeeches } from '../../components/fao/FaoComponents'

const FaoSpeechesListView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Speeches list</Heading>

            <RenderCode>
                <ListSpeeches />
            </RenderCode>
        </DsLayout>
    );
}

export default FaoSpeechesListView;
