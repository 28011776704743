import React, {useState, useEffect} from "react";
import { useLocation} from "react-router-dom";

const Toc = () => {
    const location = useLocation();
    const [toc, setToc] = useState([]);

    useEffect(() => {
        setToc(computeToc());
    }, [location]);

    return (
        <>
            {toc.length > 0 && <aside className="d-none d-lg-flex col-lg-3 py-3 toc">
                <nav>
                    <p className="h6 py-2 border-bottom mb-2">On this page</p>
                    <ul className="toc-list simple-list list-unstyled">
                    {toc.map((t, i) => {
                        return <li key={i}>
                            <a className="text-reset text-decoration-none" href={'#' + t.id}>{t.innerText}</a>
                            {t.subtitles?.length > 0 && <ul className="toc-sublist simple-list list-unstyled">{t.subtitles.map((subT, index) => {
                            return <li key={index}><a className="text-reset text-decoration-none" href={'#' + subT.id}>{subT.innerText}</a></li>
                            })}</ul>}
                        </li>
                    })}
                    </ul>
                </nav>
            </aside>}
        </>
    );

    function computeToc() {
        const mainEl = document.querySelector('main.app-main');
        const entries = [];
        const renderCodes = [...mainEl.getElementsByClassName('render-code')];
        [...mainEl.querySelectorAll('.toc-entry')].forEach((entry) => {
          if (renderCodes.every(codeBlock => !codeBlock.contains(entry))) {
            if (entry.tagName === "H1" || entry.tagName === "H3" || entry.tagName === "H3") {
              entries.push(entry)
            } else {
              if (entries[entries.length - 1].subtitles && !entries[entries.length - 1].subtitles.includes(el => el.id === entry.id)) {
                entries[entries.length - 1].subtitles.push(entry);
              } else {
                entries[entries.length - 1].subtitles = [entry];
              }
            }
          }
        });
        entries.shift();
        entries.forEach(el => {
          if (el.subtitles) {
            el.subtitles = [...new Set(el.subtitles)];
          }
        })
        return entries;
    }
      
}

export default Toc;