import { Category, Date } from '../typo/Typo'


const CardStaticOverlay = props => {
    return (
        <div className={`card card-overlay${props.live ? ' event-live' : ''}`}>
            <div className="card-image ratio ratio-3x2">
                <img src={props.image} alt={props.title} />
            </div>
            <div className="card-img-overlay d-flex flex-column justify-content-center">
                {props.live && <h6 className="card-event-live">Live</h6>}
                <Category>{props.category}</Category>
                <h5 className="card-title">
                    <a href="#!" className="title-link stretched-link">{props.title}</a>
                </h5>
                <Date>{props.date}</Date>
            </div>
        </div>
    );
}
  
export default CardStaticOverlay;
