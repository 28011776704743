import cardImage from '../../assets/test-images/card-test.jpg'


const CardHorizontal = (props) => {
    return (
        <div className={`card card-horizontal bg-caption${props.live ? ' event-live' : ''}`}>
            <div className="row g-0 align-items-center">
                <div className="col-md-6">
                    <div className="card-image ratio ratio-3x2">
                        <img src={cardImage} alt="card alt" />
                    </div>
                    {props.live && <h6 className="card-event-live">Live</h6>}
                </div>
                <div className="col-md-6">
                    <div className="card-body">
                    <h5 className="card-title text-white">A battle plan for ensuring global food supplies during the COVID-19 crisis</h5>
                    <p className="card-text text-white">FAO’s Chief Economist maps the ways the world can mitigate shocks to agriculture and food systems. </p>
                    <a href="www.google.com" title="Placeholder link title" className="link-icon text-white">
                        Internal Link Example
                        <i className="bi bi-chevron-right"></i>
                    </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
  
export default CardHorizontal;