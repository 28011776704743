// import { NavLink } from "react-router-dom";
// import navLogo from '../../assets/fao-logo.svg';
//import navLogo from 'https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-black-3lines-en.svg';

const TopNav = props => {
    return (
        <>

            {props.search && 
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Search</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        Coming soon <i className="bi bi-emoji-smile"></i> 
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
            </div>
            }

            <header className="navbar navbar-expand-lg navbar-dark app-navbar bg-white sticky-top" style={props.style}>
                <div className="container-fluid flex-wrap flex-lg-nowrap" aria-label="Main navigation">

                    <div  className="bd-navbar-toggle">
                        <button  className="navbar-toggler p-2 border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#bdSidebar" aria-controls="bdSidebar" aria-label="Toggle docs navigation">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"  className="bi" fill="currentColor" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"></path>
                            </svg>
                            <span  className="d-none fs-6 pe-1">Browse</span>
                        </button>
                    </div>


                    <a  className="navbar-brand p-0 me-0 me-lg-4 d-flex align-items-center" href="/" aria-label="FAO Design System">
                        <img src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-black-3lines-en.svg" alt="FAO logo" width="100%" height="40px" className="" />

                        {/* <img src={navLogo} alt="FAO logo" width="100%" height="40px" className="d-none d-sm-inline" /> */}
                        {/* <img src={navLogoMobile} alt="FAO logo" width="100%" height="40px" className="d-block d-sm-none "/> */}
                        <p className='ms-3 mb-0 border-start ps-3 nav-link fw-semibold d-none d-lg-block'>{ props.title }</p>
                    </a>
                    
                    <div  className="d-flex">

                        {props.search && 
                            <button href="#!" className="btn btn-search btn-icon btn-icon-s btn-sm text-decoration-none text-color-gray-dark me-xl-2 d-lg-none" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="bi bi-search m-0 me-xl-2"></i>
                                <span className="d-none d-xl-inline">Search</span>
                            </button>
                        }

                        

                        <button  className="navbar-toggler d-flex d-lg-none order-3 p-2 border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#bdNavbar" aria-controls="bdNavbar" aria-label="Toggle navigation">
                            <i className="bi bi-three-dots"></i>
                        </button>
                    </div>

                    <div className="offcanvas-lg offcanvas-end flex-grow-1 bg-white" tabIndex="-1" id="bdNavbar" aria-labelledby="bdNavbarOffcanvasLabel" data-bs-scroll="true">
                        <div className="offcanvas-header px-4 pb-0">

                            {/* <h5 className="offcanvas-title" id="bdNavbarOffcanvasLabel">FAO Design System - v{props.version}</h5> */}

                            {props.login &&
                                <>
                                    <button className="btn btn-link btn-icon btn-icon-s btn-sm text-decoration-none text-color-gray-dark">
                                        <i className="bi bi-pencil-square me-2"></i>
                                        <span>Login</span>
                                    </button>

                                    <button className="btn btn-link btn-icon btn-icon-s btn-sm text-decoration-none text-color-gray-dark">
                                        <i className="bi bi-person-circle me-2"></i>
                                        <span>Sign-up</span>
                                    </button>
                                </>
                            }

                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" data-bs-target="#bdNavbar"></button>
                        </div>

                        <div className="offcanvas-body p-4 pt-0 p-lg-0">

                        <hr className="d-lg-none" />
                        
                        
                        {props.languages &&
                            <>
                                <div className="dropdown d-lg-none">
                                    <button className="btn bg-gray-light btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">ENGLISH</button>
                                    <ul className="dropdown-menu">
                                        <li><span className="dropdown-item">ESPAÑOL</span></li>
                                        <li><span className="dropdown-item">FRANÇAIS</span></li>
                                        <li><span className="dropdown-item">中文</span></li>
                                        <li><span className="dropdown-item">РУССКИЙ</span></li>
                                        <li><span className="dropdown-item">عربي</span></li>
                                    </ul>
                                </div>

                                <hr className="d-lg-none" />
                            </>
                        }
                            

                            <ul className="navbar-nav flex-row bd-navbar-nav flex-wrap">
                                    <li className="nav-item col-6 col-lg-auto">
                                        <a className="nav-link py-2 px-0 px-lg-2" href="#!">Styles</a>
                                    </li>
                                    <li className="nav-item col-6 col-lg-auto">
                                        <a className="nav-link py-2 px-0 px-lg-2" href="#!">Components</a>
                                    </li>
                            </ul>

                            <div className="flex-row ms-md-auto align-items-center d-none d-lg-flex justify-content-end nav-link">

                                {props.search && 
                                    <button className="btn btn-search btn-icon btn-icon-s btn-sm text-decoration-none text-color-gray-dark me-xl-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        <i className="bi bi-search m-0 me-xl-2"></i>
                                        <span className="d-none d-xl-inline">Search</span>
                                    </button>
                                }

                                {props.login &&
                                    <>
                                        <button className="btn btn-link btn-icon btn-icon-s btn-sm text-decoration-none text-color-gray-dark">
                                            <i className="bi bi-pencil-square m-0 me-xl-2"></i>
                                            <span className="d-none d-xl-inline">Login</span>
                                        </button>

                                        <button className="btn btn-link btn-icon btn-icon-s btn-sm text-decoration-none text-color-gray-dark">
                                            <i className="bi bi-person-circle m-0 me-xl-2"></i>
                                            <span className="d-none d-xl-inline">Sign-up</span>
                                        </button>
                                    </>
                                }

                                {props.languages &&
                                    <div className="dropdown ps-2">
                                        <button className="btn bg-gray-light btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">ENGLISH</button>
                                        <ul className="dropdown-menu">
                                            <li><span className="dropdown-item">ESPAÑOL</span></li>
                                            <li><span className="dropdown-item">FRANÇAIS</span></li>
                                            <li><span className="dropdown-item">中文</span></li>
                                            <li><span className="dropdown-item">РУССКИЙ</span></li>
                                            <li><span className="dropdown-item">عربي</span></li>
                                        </ul>
                                    </div>
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>

                
            </header>
            <div className="container-fluid d-lg-none" aria-label="Title">
                <p className='mb-0 p-3 nav-link fw-semibold text-center'>{ props.title }</p>
            </div>
        </>
    );
}
  
export default TopNav;

