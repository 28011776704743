import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
import { ShareModal } from '../../components/fao/FaoComponents'
import { EmailModal } from '../../components/fao/FaoComponents'


const ModalView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Modal</Heading>
            
            <Heading size="h3">Modal Share</Heading>

            <RenderCode withBorders={true}>
                <a href="#!" type="button" data-bs-toggle="modal" data-bs-target="#shareModal">
                    <i className="bi bi-share-fill"></i>
                </a>
                <ShareModal className="show" style={{ position: 'relative', paddingRight: '17px', display: 'block' }} />
            </RenderCode>

            <Heading size="h3">Modal email (admin only)</Heading>

            <RenderCode withBorders={true}>
                <div className="content-icons">
                    <a href="#!" type="button" className="d-flex" data-bs-toggle="modal" data-bs-target="#emailModal">
                        <i className="bi bi-envelope"></i> <span>Email</span>
                    </a>
                </div>
                <EmailModal className="show" style={{ position: 'relative', paddingRight: '17px', display: 'block' }} />
            </RenderCode>

        </DsLayout>
    );
}

export default ModalView;