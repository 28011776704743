import Home from "./views/Home";
import StylesPage from "./views/Styles";
import ComponentsPage from "./views/Components";
import ContentPage from "./views/Content";
import AccessibilityPage from "./views/Accessibility";
import ContactPage from "./views/Contact";
import DownloadPage from "./views/Download";
import ExamplePage from "./views/Example";
// import Route404 from './views/Route404';
import Changelog from "./views/Changelog";
import Login from "./views/Login";

// Styles
import ColorsView from "./views/styles/ColorsView";
import EmojiView from "./views/styles/EmojiView";
import FaoLogoView from "./views/styles/FaoLogoView";
import IconsView from "./views/styles/IconsView";
import TypographyView from "./views/styles/TypographyView";
import LayoutsView from "./views/styles/LayoutsView";
import ImagesView from "./views/styles/ImagesView";
import AppLayoutView from "./views/styles/AppLayoutView";
import SdgView from "./views/styles/SdgView";

// Components
import AccordionsView from "./views/components/AccordionsView";
import ButtonsView from "./views/components/ButtonsView";
import CardsView from "./views/components/CardsView";
import ContentBackgroundView from "./views/components/ContentBackgroundView";
import ContentBlockView from "./views/components/ContentBlockView";
import CustomSubHeaderView from "./views/components/CustomSubHeaderView";
// import DatePickerView from './views/components/DatePickerView';
// import DynamicDetailView from './views/components/DynamicDetailView';
// import DynamicListView from './views/components/DynamicListView';
import DynamicSearchView from "./views/components/DynamicSearchView";
import EmbedsView from "./views/components/EmbedsView";
import ModalView from "./views/components/ModalView";
// import EventsCardView from './views/components/EventsCardView';
import FooterView from "./views/components/FooterView";
import FormsView from "./views/components/FormsView";
import HeaderView from "./views/components/HeaderView";
import HeroView from "./views/components/HeroView";
import LinksView from "./views/components/LinksView";
import MapView from "./views/components/MapView";
import NavbarView from "./views/components/NavbarView";
import SwiperView from "./views/components/SwiperView";
import TabbedContentView from "./views/components/TabbedContentView";
import TagsListView from "./views/components/TagsListView";
import TimelineView from "./views/components/TimelineView";
import PaginationView from "./views/components/PaginationView";
import BreadcrumbsView from "./views/components/BreadcrumbsView";
// import SideNavView from "./views/components/SideNavView";
import FlagsView from "./views/components/FlagsView";

// Sitefinity
import FaoNewsCardView from "./views/sitefinity/FaoNewsCardView";
import FaoNewsListView from "./views/sitefinity/FaoNewsListView";
import FaoNewsDetailView from "./views/sitefinity/FaoNewsDetailView";
import FaoEventsCardView from "./views/sitefinity/FaoEventsCardView";
import FaoEventsListView from "./views/sitefinity/FaoEventsListView";
import FaoEventsDetailView from "./views/sitefinity/FaoEventsDetailView";
import FaoPublicationsListView from "./views/sitefinity/FaoPublicationsListView";
import FaoPublicationDetailView from "./views/sitefinity/FaoPublicationDetailView";
import FaoBlogListView from "./views/sitefinity/FaoBlogListView";
// import FaoBlogHeroView from './views/sitefinity/FaoBlogHeroView';
import FaoBlogDetailView from "./views/sitefinity/FaoBlogDetailView";
import FaoBlogCardView from "./views/sitefinity/FaoBlogCardView";

import FaoArticleListView from "./views/sitefinity/FaoArticleListView";
import FaoElearningListView from "./views/sitefinity/FaoElearningListView";
import FaoSpeechesListView from "./views/sitefinity/FaoSpeechesListView";
import FaoSpeechesDetailView from "./views/sitefinity/FaoSpeechesDetailView";
import FaoVideoListView from "./views/sitefinity/FaoVideoListView";
import FaoAudioListView from "./views/sitefinity/FaoAudioListView";
import FaoArticleDetailView from "./views/sitefinity/FaoArticleDetailView";
import FaoAudioDetailView from "./views/sitefinity/FaoAudioDetailView";
import FaoVideoDetailView from "./views/sitefinity/FaoVideoDetailView";
import FaoElearningDetailView from "./views/sitefinity/FaoElearningDetailView";

import FaoArticleCardView from "./views/sitefinity/FaoArticleCardView";
import FaoAudioCardView from "./views/sitefinity/FaoAudioCardView";
import FaoElearningCardView from "./views/sitefinity/FaoElearningCardView";
import FaoPublicationsCardView from "./views/sitefinity/FaoPublicationsCardView";
import FaoSpeechesCardView from "./views/sitefinity/FaoSpeechesCardView";
import FaoVideoCardView from "./views/sitefinity/FaoVideoCardView";

import FaoProjectListView from "./views/sitefinity/FaoProjectListView";
import FaoProjectDetailView from "./views/sitefinity/FaoProjectDetailView";

import FaoMeetingsDocsListView from "./views/sitefinity/FaoMeetingsDocsListView";

import FaoForumListView from "./views/sitefinity/FaoForumListView";

import FaoStoryListView from "./views/sitefinity/FaoStoryListView";
import FaoStoryCardView from "./views/sitefinity/FaoStoryCardView";
import FaoStoryDetailView from "./views/sitefinity/FaoStoryDetailView";

import FaoPhotoGalleryListView from "./views/sitefinity/FaoPhotoGalleryListView";
import FaoPhotoGalleryCardView from "./views/sitefinity/FaoPhotoGalleryCardView";
import FaoPhotoGalleryDetailView from "./views/sitefinity/FaoPhotoGalleryDetailView";

import FaoPartnerListView from "./views/sitefinity/FaoPartnerListView";
import FaoPartnerCardView from "./views/sitefinity/FaoPartnerCardView";

// Examples
import ThematicSiteExampleView from "./views/examples/ThematicSiteExampleView";
import ThematicSiteCustomSubExampleView from "./views/examples/ThematicSiteCustomSubExampleView";
import PlatformsView from "./views/examples/PlatformsView";
import ProjectsView from "./views/examples/ProjectsView";
import EventsView from "./views/examples/EventsView";
import DemoPageView from "./views/examples/DemoPageView";
// import InternationalYearV2 from "./views/examples/InternationalYearV2";

import CardsExampleView from "./views/examples/CardsExampleView";

// import SubSiteExampleView from './views/examples/SubSiteExampleView';
// import EventDetailExampleView from './views/examples/EventDetailExampleView';
// import HomeExampleView from './views/examples/HomeExampleView';
// import InternationalYearExampleView from './views/examples/InternationalYearExampleView';

// Test
import HomeBanner from "./views/test/HomeBanner";

// Accessibility
import CreatingView from "./views/accessibility/CreatingView";
import ResourcesView from "./views/accessibility/ResourcesView";
import ToolsView from "./views/accessibility/ToolsView";
import ReadingView from "./views/accessibility/ReadingView";
import AudioVisualView from "./views/examples/AudioVisualView";

export const route_home = [{ name: "Home", path: "/", component: Home }];

export const route_styles_page = [
  { name: "Styles", path: "/styles", component: StylesPage },
];

export const route_components_page = [
  { name: "Components", path: "/components", component: ComponentsPage },
];

export const route_sitefinity_page = [
  { name: "Sitefinity", path: "/sitefinity", component: ContentPage },
];
export const route_accessibility_page = [
  {
    name: "Accessibility",
    path: "/accessibility",
    component: AccessibilityPage,
  },
];

export const route_contact_page = [
  { name: "Contact", path: "/contact", component: ContactPage },
];

export const route_example_page = [
  { name: "Example", path: "/example", component: ExamplePage },
];

export const route_download_page = [
  { name: "Download", path: "/download", component: DownloadPage },
];

export const route_changelog_page = [
  { name: "Changelog", path: "/changelog", component: Changelog },
];

export const route_login_page = [
  { name: "Login", path: "/login", component: Login },
];

export const route_styles = [
  { name: "Colors", path: "/styles/colors", component: ColorsView },
  { name: "Emoji", path: "/styles/emoji", component: EmojiView },
  { name: "Icons", path: "/styles/icons", component: IconsView },
  { name: "Images", path: "/styles/images", component: ImagesView },
  { name: "Flags", path: "/styles/flags", component: FlagsView },
  { name: "Fao Logo", path: "/styles/fao-logo", component: FaoLogoView },
  { name: "Typography", path: "/styles/typography", component: TypographyView },
  { name: "Layout - Subsite", path: "/styles/layouts", component: LayoutsView },
  {
    name: "Layout - Application",
    path: "/styles/applayout",
    component: AppLayoutView,
  },
  { name: "SDG images", path: "/styles/sdg", component: SdgView },
];

export const route_components = [
  {
    name: "Accordions",
    path: "/components/accordions",
    component: AccordionsView,
  },
  { name: "Buttons", path: "/components/buttons", component: ButtonsView },
  { name: "Cards", path: "/components/cards", component: CardsView },
  {
    name: "Content Background",
    path: "/components/contentbackground",
    component: ContentBackgroundView,
  },
  {
    name: "Content Block",
    path: "/components/contentblock",
    component: ContentBlockView,
  },
  {
    name: "Custom Sub Header",
    path: "/components/customsubheader",
    component: CustomSubHeaderView,
  },
  // {name: 'Date Picker', path: '/components/datepickerview', component: DatePickerView},
  { name: "Embeds", path: "/components/embedsview", component: EmbedsView },
  {
    name: "Search",
    path: "/components/dynamicsearch",
    component: DynamicSearchView,
  },
  { name: "Footer", path: "/components/footer", component: FooterView },
  { name: "Forms", path: "/components/forms", component: FormsView },
  { name: "Header", path: "/components/header", component: HeaderView },
  { name: "Hero Banner", path: "/components/hero", component: HeroView },
  { name: "Links", path: "/components/links", component: LinksView },
  { name: "Maps", path: "/components/maps", component: MapView },
  { name: "Modal", path: "/components/modal", component: ModalView },
  { name: "Navbar", path: "/components/navbar", component: NavbarView },
  {
    name: "Pagination",
    path: "/components/pagination",
    component: PaginationView,
  },
  {
    name: "Breadcrumbs",
    path: "/components/breadcrumbs",
    component: BreadcrumbsView,
  },
  // {
  //   name: "Side Navigation",
  //   path: "/components/sidenav",
  //   component: SideNavView,
  // },
  { name: "Swiper", path: "/components/swiper", component: SwiperView },
  {
    name: "Tabbed Content",
    path: "/components/tabbedcontent",
    component: TabbedContentView,
  },
  { name: "Tags List", path: "/components/tagslist", component: TagsListView },
  { name: "Timeline", path: "/components/timeline", component: TimelineView },
];

export const route_examples = [
  {
    name: "Thematic sites/pages",
    path: "/thematichexample",
    component: ThematicSiteExampleView,
  },
  {
    name: "Thematic site with custom sub header",
    path: "/thematichexamplecustomsub",
    component: ThematicSiteCustomSubExampleView,
  },
  { name: "Platforms", path: "/platformexample", component: PlatformsView },
  { name: "Projects", path: "/projectexample", component: ProjectsView },
  { name: "Events", path: "/eventexample", component: EventsView },
  // {name: 'DemoPage', path: '/demopage', component: DemoPageView},
  { name: "Audio Visual", path: "/audiovisual", component: AudioVisualView },
  {
    name: "Cards Example Live",
    path: "/cards-example",
    component: CardsExampleView,
  },
  // {
  //   name: "International Year V2",
  //   path: "/international-year-v2",
  //   component: InternationalYearV2,
  // },

  // {name: 'Subsite', path: '/subsiteexample', component: SubSiteExampleView},
  // {name: 'Event Detail', path: '/eventdetailexample', component: EventDetailExampleView},
  // {name: 'Home', path: '/homeexample', component: HomeExampleView},
  // {name: 'International Year', path: '/internationalyear', component: InternationalYearExampleView},
];

export const route_test = [
  { name: "", path: "/test/homebanner", component: HomeBanner },
  { name: "", path: "/test/demopage", component: DemoPageView },
];

export const route_accessibility = [
  {
    name: "Creating Accessibile Sites",
    path: "/accessibility/creatingsites",
    component: CreatingView,
  },
  {
    name: "Resources",
    path: "/accessibility/resources",
    component: ResourcesView,
  },
  { name: "Tools", path: "/accessibility/tools", component: ToolsView },
  {
    name: "Recommended Reading",
    path: "/accessibility/reading",
    component: ReadingView,
  },
];

export const route_content = [
  {
    name: "Article",
    children: [
      {
        name: "List",
        path: "/sitefinity/articlelist",
        component: FaoArticleListView,
      },
      {
        name: "Card",
        path: "/sitefinity/articlecard",
        component: FaoArticleCardView,
      },
      {
        name: "Detail",
        path: "/sitefinity/articledetail",
        component: FaoArticleDetailView,
      },
    ],
  },

  {
    name: "Audio",
    children: [
      {
        name: "List",
        path: "/sitefinity/audiolist",
        component: FaoAudioListView,
      },
      {
        name: "Card",
        path: "/sitefinity/audiocard",
        component: FaoAudioCardView,
      },
      {
        name: "Detail",
        path: "/sitefinity/audiodetail",
        component: FaoAudioDetailView,
      },
    ],
  },

  {
    name: "Blog",
    children: [
      {
        name: "List",
        path: "/sitefinity/faobloglist",
        component: FaoBlogListView,
      },
      {
        name: "Card",
        path: "/sitefinity/blogcard",
        component: FaoBlogCardView,
      },
      {
        name: "Detail",
        path: "/sitefinity/blogdetail",
        component: FaoBlogDetailView,
      },
    ],
  },

  {
    name: "E-Learning",
    children: [
      {
        name: "List",
        path: "/sitefinity/elearninglist",
        component: FaoElearningListView,
      },
      {
        name: "Card",
        path: "/sitefinity/elearningcard",
        component: FaoElearningCardView,
      },
      {
        name: "Detail",
        path: "/sitefinity/elearningdetail",
        component: FaoElearningDetailView,
      },
    ],
  },

  {
    name: "Event",
    children: [
      {
        name: "List",
        path: "/sitefinity/faoeventslist",
        component: FaoEventsListView,
      },
      {
        name: "Card",
        path: "/sitefinity/faoeventscard",
        component: FaoEventsCardView,
      },
      {
        name: "Detail",
        path: "/sitefinity/faoeventsdetail",
        component: FaoEventsDetailView,
      },
    ],
  },

  {
    name: "Forum",
    children: [
      {
        name: "List",
        path: "/sitefinity/forumlist",
        component: FaoForumListView,
      },
    ],
  },

  {
    name: "Meetings Documents",
    children: [
      {
        name: "List",
        path: "/sitefinity/meetingsdocslist",
        component: FaoMeetingsDocsListView,
      },
    ],
  },

  {
    name: "News",
    children: [
      {
        name: "List",
        path: "/sitefinity/faonewslist",
        component: FaoNewsListView,
      },
      {
        name: "Card",
        path: "/sitefinity/faonewscard",
        component: FaoNewsCardView,
      },
      {
        name: "Detail",
        path: "/sitefinity/faonewsdetail",
        component: FaoNewsDetailView,
      },
    ],
  },

  {
    name: "Partners",
    children: [
      {
        name: "List",
        path: "/sitefinity/partnerslist",
        component: FaoPartnerListView,
      },
      {
        name: "Card",
        path: "/sitefinity/partnerscard",
        component: FaoPartnerCardView,
      },
    ],
  },

  {
    name: "Photo Gallery",
    children: [
      {
        name: "List",
        path: "/sitefinity/photogallerylist",
        component: FaoPhotoGalleryListView,
      },
      {
        name: "Card",
        path: "/sitefinity/photogallerycard",
        component: FaoPhotoGalleryCardView,
      },
      {
        name: "Detail",
        path: "/sitefinity/photogallerydetail",
        component: FaoPhotoGalleryDetailView,
      },
    ],
  },

  {
    name: "Project",
    children: [
      {
        name: "List",
        path: "/sitefinity/projectlist",
        component: FaoProjectListView,
      },
      {
        name: "Detail",
        path: "/sitefinity/projectdetail",
        component: FaoProjectDetailView,
      },
    ],
  },

  {
    name: "Publications",
    children: [
      {
        name: "List",
        path: "/sitefinity/faopublicationslist",
        component: FaoPublicationsListView,
      },
      {
        name: "Card",
        path: "/sitefinity/publicationscard",
        component: FaoPublicationsCardView,
      },
      {
        name: "Detail",
        path: "/sitefinity/publicationdetail",
        component: FaoPublicationDetailView,
      },
    ],
  },

  {
    name: "Speeches",
    children: [
      {
        name: "List",
        path: "/sitefinity/speecheslist",
        component: FaoSpeechesListView,
      },
      {
        name: "Card",
        path: "/sitefinity/speechescard",
        component: FaoSpeechesCardView,
      },
      {
        name: "Detail",
        path: "/sitefinity/speechesdetail",
        component: FaoSpeechesDetailView,
      },
    ],
  },

  {
    name: "Story",
    children: [
      {
        name: "List",
        path: "/sitefinity/storylist",
        component: FaoStoryListView,
      },
      {
        name: "Card",
        path: "/sitefinity/storycard",
        component: FaoStoryCardView,
      },
      {
        name: "Detail",
        path: "/sitefinity/storydetail",
        component: FaoStoryDetailView,
      },
    ],
  },

  {
    name: "Video",
    children: [
      {
        name: "List",
        path: "/sitefinity/videolist",
        component: FaoVideoListView,
      },
      {
        name: "Card",
        path: "/sitefinity/videocard",
        component: FaoVideoCardView,
      },
      {
        name: "Detail",
        path: "/sitefinity/videodetail",
        component: FaoVideoDetailView,
      },
    ],
  },
];

// export const route_error = [
//     {component: Route404}
// ]

export const routes = [
  route_content.flatMap((el) => [...el.children]),
  route_home,
  route_styles_page,
  route_components_page,
  route_sitefinity_page,
  route_accessibility_page,
  route_styles,
  route_components,
  route_examples,
  route_test,
  route_accessibility,
  route_contact_page,
  route_download_page,
  route_example_page,
  route_changelog_page,
  route_login_page,
].flatMap((el) => [...el]);
