import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'
import { CardPhotoGallery } from '../../components/fao/FaoComponents'
import cardImage from '../../assets/test-images/card-test-speeches.jpg'


const FaoPhotoGalleryCardView = () => {
    return (
        <DsLayout>
            <style>{`.card { max-width: 18rem; }`}</style>
            <Heading size="h1">Photo gallery card</Heading>
            <RenderCode>
                <CardPhotoGallery 
                    image={cardImage} 
                    title="Lorem ipsum dolor sit amet" 
                    date="26/06/2020" 
                    body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor." 
                />
            </RenderCode> 
        </DsLayout>
    );
}

export default FaoPhotoGalleryCardView;
